import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "authenticationSlice",
  initialState: {
    rta: {},
    rtaList: [],
    rtaStatus: [],
  },
  reducers: {
    GETRTALISTSUCCESS: (state, action) => {
      return {
        ...state,
        rtaList: action.payload,
      };
    },
    GETRTALISTERROR: (state) => {
      return {
        ...state,
        rtaList: [],
      };
    },
    GETRTASUCCESS: (state, action) => {
      return {
        ...state,
        rta: action.payload,
      };
    },
    GETRTAERROR: (state) => {
      return {
        ...state,
        rta: {},
      };
    },
    GETRTASTATUSSUCCESS: (state, action) => {
      return { ...state, rtaStatus: action.payload };
    },
    GETRTASTATUSERROR: (state) => {
      return { ...state, rtaStatus: {} };
    },
    CLEARRTADATA: (state) => {
      console.log("clearRtaData")
      return { ...state, rta: {}, rtaList: [], rtaStatus: [] };
    }
  },
});

export const {
  GETRTALISTSUCCESS,
  GETRTALISTERROR,
  GETRTASUCCESS,
  GETRTAERROR,
  GETRTASTATUSERROR,
  GETRTASTATUSSUCCESS,
  CLEARRTADATA,
} = slice.actions;
export default slice.reducer;
