import React from "react";
import styled from "styled-components";
import { FaUser, FaBriefcase, FaUsers } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const NavContainer = styled.nav`
  display: flex;
  justify-content: space-around;
  background-color: #e0f7fa; /* Aqua blue color for the background */
  padding: 10px 0;
`;

const NavItem = styled.div`
  position: relative; /* Added position relative for the underline */
  padding: 10px 20px; /* Add padding to create tabs */
  color: ${(props) =>
    props.active ? "#000" : "#444"}; /* Dark text color for visibility */
  cursor: pointer;
  transition: color 0.3s ease, transform 0.2s ease; /* Added transition for color and transform */

  &:hover {
    color: #000; /* Dark text color on hover */
    transform: translateY(-2px); /* Move the item up slightly on hover */
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #000; /* Dark color for the underline */
    visibility: ${(props) =>
      props.active ? "visible" : "hidden"}; /* Show underline for active item */
    transition: visibility 0.2s ease, transform 0.2s ease; /* Added transition for visibility and transform */
    transform: scaleX(
      ${(props) => (props.active ? 1 : 0)}
    ); /* Scale the underline based on active state */
  }
`;

const Navigation = ({ activeItem, setActiveItem, id }) => {
  const navigate = useNavigate();
  return (
    <NavContainer>
      <NavItem
        active={activeItem === "profile"}
        onClick={() => {
          navigate(`../viewcompanyprofile/${id}`);
        }}
      >
        <FaUser /> Profile
      </NavItem>
      <NavItem
        active={activeItem === "jobs"}
        onClick={() => {
          navigate(`../viewcompanyjobs/${id}`);
        }}
      >
        <FaBriefcase /> Jobs
      </NavItem>
      <NavItem
        active={activeItem === "users"}
        onClick={() => {
          navigate(`../viewcompanyusers/${id}`);
        }}
      >
        <FaUsers /> Users
      </NavItem>
      <NavItem
        active={activeItem === "document"}
        onClick={() => {
          navigate(`../viewcompanydoc/${id}`);
        }}
      >
        <FaUsers /> Document
      </NavItem>
    </NavContainer>
  );
};

export default Navigation;
