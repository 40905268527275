import React, { useState } from "react";
import CaseHeaderEl from "./CaseHeaderEl/index";
import { getElDetails } from "../../redux/actions/elAction";
import { useParams } from "react-router-dom";
import CampaignDetails from "../../components/CampaignGeneric/CampaignDetails";
import { campaignsNames } from "../../components/CampaignGeneric/campaignConfig";
import { useDispatch, useSelector } from "react-redux";
import El from "./el";

export default function ELDetails() {
  const { el } = useSelector((state) => state.elSlice);
  const [isSubmitFormik, setisSubmitFormik] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { code } = useParams();
  const dispatch = useDispatch();

  const onSave = async () => {
    setisSubmitFormik(true);
  };

  const handleReturnEdit = async () => {
    setDisabled(true);
    dispatch(getElDetails(code));
  };

  const onSetIsSubmitFormikFalse = () => {
    setisSubmitFormik(false);
  };

  const onCancel = () => {
    setDisabled(true);
  };

  return (
    <CampaignDetails
      campaign={el}
      campaignName={campaignsNames.el}
      campaignCode={code}
      onSave={onSave}
      onCancel={onCancel}
      getCampaignDetails={getElDetails}
      disabled={disabled}
      setDisabled={setDisabled}
      CampaignForm={
        <El
          edit={true}
          onSave={onSave}
          isSubmitFormik={isSubmitFormik}
          handleReturnEdit={handleReturnEdit}
          onSetIsSubmitFormikFalse={onSetIsSubmitFormikFalse}
          showAccSubmissionCriteria={true}
        />
      }
      CaseHeader={
        <CaseHeaderEl title={el?.elcode} subTitle={el?.statusDescr} />
      }
    />
  );
}
