import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Login from "../Login";

const EmailLinkRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [desiredPage, setDesiredPage] = useState("");

  useEffect(() => {
    // Extract necessary information from the email link
    const { search } = location;
    const params = new URLSearchParams(search);
    const desiredPage = params.get("page"); // Capture the desired page from query params
    setDesiredPage(desiredPage);
    console.log(desiredPage, "desiredPage");

    // Check if the user is already authenticated
    const isAuthenticated = !!localStorage.getItem("token");

    if (isAuthenticated) {
      // If user is authenticated, redirect them directly to the desired page
      navigate(desiredPage || "/"); // Redirect to default page if desired page not provided
    }
  }, [navigate, location]);

  return <Login redirectUrl={desiredPage} />;
};

export default EmailLinkRedirect;
