import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import LaCheckbox from "../../components/LaCheckbox";
import LaInput from "../../components/LaInput";

function EnergyDebtSection({ sethdrEnergyDebtObj, hdr, hdrEnergyDebtObj }) {
  const initialValues = {
    outstandingDebt: "N",
    outstandingSinceDate: "",
    arrearsAmount: "",
  };

  const formik = useFormik({
    // if outstandingDebt is Y or true, then outstandingSinceDate and arrearsAmount are required
    validationSchema: Yup.object({
      outstandingSinceDate: Yup.string().when("outstandingDebt", {
        is: (value) => value === "Y" || value === true,
        then: Yup.string().required("Required"),
      }),
      arrearsAmount: Yup.string().when("outstandingDebt", {
        is: (value) => value === "Y" || value === true,
        then: Yup.string().required("Required"),
      }),
    }),
    initialValues: initialValues,
    onSubmit: async (data) => {},
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange: formik.handleChange,
    values: formik.values,
  };

  useEffect(() => {
    sethdrEnergyDebtObj({ ...hdrEnergyDebtObj, ...formik.values });
  }, [formik.values]);

  const fillFormFields = () => {
    const data = { ...hdr.tblHdrclaimants[0] };

    // To convert Y/N to true/false for radio buttons and checkboxes
    Object.keys(data).forEach((key) => {
      if (data[key] === "Y") {
        data[key] = true;
      }
      if (data[key] === "N") {
        data[key] = false;
      }
      if (data[key] === null) {
        data[key] = "";
      }
    });

    if (data.outstandingSinceDate) {
      data.outstandingSinceDate = data.outstandingSinceDate.split("T")[0];
    }

    Object.keys(data).forEach((key) => {
      if (formik.initialValues.hasOwnProperty(key)) {
        formik.setFieldValue(key, data[key]);
      }
    });
  };

  useEffect(() => {
    if (hdr?.tblHdrclaimants?.length > 0) {
      fillFormFields();
    }
  }, [hdr]);

  return (
    <form>
      <div className="p-mt-4 p-ml-4">
        <div className="p-fluid p-formgrid p-grid">
          <div className="Panel__Heading">
            Outstanding Debt with Energy Provider
          </div>
          <div className="p-col-12">
            <div className="p-col-12" style={{ display: "flex" }}>
              <LaCheckbox keyName="outstandingDebt" {...props} />
              <label className="p-d-block" style={{ marginLeft: "10px" }}>
                Does the client have any outstanding debt with their energy
                provider?
              </label>
            </div>
            <div className={hdr ? "p-col-12" : "p-col-6"}>
              {formik.values.outstandingDebt && (
                <>
                  <div className="p-col-12 p-mt-2">
                    <label className="p-d-block">
                      When has this been outstanding since?
                    </label>
                    <LaInput
                      keyName="outstandingSinceDate"
                      placeholder="Outstanding Since Date"
                      type={"date"}
                      {...props}
                    />
                  </div>
                  <div className="p-col-12 p-mt-2">
                    <label className="p-d-block">
                      What is the amount in arrears?
                    </label>
                    <LaInput
                      keyName="arrearsAmount"
                      placeholder="Arrears Amount"
                      {...props}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default EnergyDebtSection;
