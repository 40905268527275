import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import LaInput from "../LaInput";
import axios from "axios";
import styled from "styled-components";
import { FaSpinner } from "react-icons/fa";
import classNames from "classnames";
import VehicleDetails from "./VehicleDetails";
import VehicleDetailsButton from "./VehicleDetailsButton";
import { toast } from "react-toastify";

export const vehicleRegKey = "dad89304-c6c0-48f8-9bb2-7c9c06a105df";

function LaVehicleReg({
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
  keyNameRegNo,
  keyNameMakeModel,
  keyNameYearOfManufacture,
  keyNameInsurer,
  keyNamePolicyNo,
  keyNameRefNo,
  disabled,
  setVehicleType,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    formik,
    disabled,
  };
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);

  const getMakeModel = async () => {
    if (values[keyNameRegNo].length > 2) {
      setLoading(true);
      const url = `https://uk1.ukvehicledata.co.uk/api/datapackage/VehicleData?v=2&api_nullitems=1&auth_apikey=${vehicleRegKey}&user_tag=&key_VRM=${values[keyNameRegNo]}`;
      try {
        const response = await axios.get(url);
        formik.setFieldValue(
          keyNameMakeModel,
          response?.data?.Response?.DataItems?.VehicleRegistration?.MakeModel ||
            ""
        );
        formik.setFieldValue(
          keyNameYearOfManufacture,
          response?.data?.Response?.DataItems?.VehicleRegistration
            ?.YearOfManufacture || ""
        );
        setResponseData(response?.data);
        const bikeOrCar =
          response?.data?.Response?.DataItems?.VehicleRegistration
            ?.VehicleClass;
        if (setVehicleType) {
          if (bikeOrCar === "Car") {
            formik.setFieldValue("vehicleType", "C");
            toast.info(
              "The vehicle type is Car so the vehicle type is set to Car"
            );
          } else {
            formik.setFieldValue("vehicleType", "B");
            toast.info(
              "The vehicle type is Bike so the vehicle type is set to Bike"
            );
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <>
      <hr className="border__hr" />
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Registration Number <span className="text-danger">*</span>
        </label>
        <div className="p-inputgroup la__inputGroup">
          <InputText
            id={keyNameRegNo}
            placeholder="Registration Number"
            name={keyNameRegNo}
            value={values[keyNameRegNo]}
            disabled={disabled}
            onChange={onChange}
            onBlur={() => {
              getMakeModel();
            }}
            className={classNames({
              "p-invalid": isFormFieldValid(keyNameRegNo.toString()),
            })}
          />
          <div>
            <ButtonOption
              disabled={loading || disabled}
              type="button"
              onClick={getMakeModel}
            >
              {loading ? <FaSpinner /> : "lookup"}
            </ButtonOption>
          </div>
        </div>
        {getFormErrorMessage(keyNameRegNo.toString())}
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Make Model <span className="text-danger">*</span>
        </label>
        <LaInput
          keyName={keyNameMakeModel}
          placeholder="make model"
          {...props}
        />
      </div>
      {values[keyNameYearOfManufacture] && (
        <div className="p-col-12 p-mt-2">
          <div className="p-col-12">
            <span className="label-info Width__100">
              Year of Manufacture : {values[keyNameYearOfManufacture]}
            </span>
            <span className="d-flex justify-content-center mt-2">
              <VehicleDetailsButton responseData={responseData} />
            </span>
          </div>
        </div>
      )}

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Insurer</label>
        <LaInput keyName={keyNameInsurer} placeholder="insurer" {...props} />
      </div>
      <div className="p-col-6 p-mt-2">
        <LaInput keyName={keyNamePolicyNo} placeholder="policy no" {...props} />
      </div>
      <div className="p-col-6 p-mt-2">
        <LaInput
          keyName={keyNameRefNo}
          placeholder="claim reference number"
          {...props}
        />
      </div>
    </>
  );
}

const ButtonOption = styled.button`
  position: relative;
  height: 33px;
  width: 100%;
  padding-right: 25px; /* Add padding to accommodate the icon */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0px !important;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #f0f0f0;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border-top: 6px solid #000;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
`;

export default LaVehicleReg;
