import React, { useEffect } from "react";
import { useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import { hdrClaimConstants, hdrTenancy } from "../../utilities/constants";

const NewForm = ({
  handleClaimReturn,
  handleTenantReturn,
  view,
  errors,
  hdrFomData,
  hasTenant,
}) => {
  const [reported, setReported] = useState("");
  const [responded, setResponded] = useState("");
  const [rentContributed, setRentContributed] = useState(false);
  const [inArrears, setInArrears] = useState(false);
  const [inPaymentPlan, setInPaymentPlan] = useState(false);
  const [receivingBenet, setReceivingBenet] = useState("");
  const [claimDetails, setclaimDetails] = useState(hdrClaimConstants);
  const [tenantDetails, setTenantDetails] = useState(hdrTenancy);

  useEffect(() => {
    if (claimDetails) handleClaimReturn(claimDetails);
  }, [claimDetails, handleClaimReturn]);

  useEffect(() => {
    handleTenantReturn(tenantDetails);
  }, [tenantDetails, handleTenantReturn]);

  useEffect(() => {
    if (hdrFomData !== undefined && hdrFomData !== null) {
      setclaimDetails(hdrFomData);
      setTenantDetails(
        "tblHdrtenancies" in hdrFomData && hdrFomData?.tblHdrtenancies[0]
      );
      setInArrears(hdrFomData?.tblHdrtenancies?.[0]?.inArrears === "yes");
      setInPaymentPlan(
        hdrFomData?.tblHdrtenancies?.[0]?.inPaymentPlan === "yes"
      );
      setRentContributed(
        hdrFomData?.tblHdrtenancies?.[0]?.rentContribute === "yes"
      );
    }
  }, [hdrFomData]);

  return (
    <div className="p-mt-4 p-ml-4">
      <div className="p-fluid p-formgrid p-grid">
        <div className="p-col-6">
          <div className="p-fluid p-formgrid p-grid p-pr-4">
            {!view && <div className="Panel__Heading">Claim Details</div>}

            <div className="p-col-12">
              <label className="p-d-block">Landlord *</label>
            </div>
            <div className="p-col-8">
              <InputText
                onChange={(e) => {
                  setclaimDetails({
                    ...claimDetails,
                    llName: e.target.value,
                  });
                }}
                value={claimDetails?.llName || ""}
                placeholder="Enter full Name"
                className={errors?.llName && "p-inputtext-error p-d-block"}
              />
              <small className={errors?.llName && "label__error p-d-block"}>
                {errors?.llName}
              </small>
            </div>
            <div className="p-col-4">
              <InputText
                onChange={(e) => {
                  setclaimDetails({
                    ...claimDetails,
                    llContactno: e.target.value,
                  });
                }}
                value={claimDetails?.llContactno || ""}
                placeholder="Contact Number"
                className={errors?.llContactno && "p-inputtext-error p-d-block"}
              />
              <small
                className={errors?.llContactno && "label__error p-d-block"}
              >
                {errors?.llContactno}
              </small>
            </div>

            <div className="p-col-6 p-mt-6">
              <label className="p-d-block">Defects First Reported</label>
            </div>
            <div className="p-col-12">
              <InputText
                onChange={(e) => {
                  setclaimDetails({
                    ...claimDetails,
                    defectFirstreportedon: e.target.value,
                  });
                }}
                value={claimDetails?.defectFirstreportedon || ""}
                type="date"
                className={
                  errors?.defectFirstreportedon && "p-inputtext-error p-d-block"
                }
              />
              <small
                className={
                  errors?.defectFirstreportedon && "label__error p-d-block"
                }
              >
                {errors?.defectFirstreportedon}
              </small>
            </div>

            <div className="p-col-12 p-mt-6">
              <label className="p-d-block">Defects Last Reported *</label>
            </div>
            <div className="p-col-12">
              <InputText
                value={claimDetails?.defectLastreportedon || ""}
                onChange={(e) => {
                  setclaimDetails({
                    ...claimDetails,
                    defectLastreportedon: e.target.value,
                  });
                }}
                type="date"
                className={
                  errors?.defectLastreportedon && "p-inputtext-error p-d-block"
                }
              />
              <small
                className={
                  errors?.defectLastreportedon && "label__error p-d-block"
                }
              >
                {errors?.defectLastreportedon}
              </small>
            </div>
            <div className="p-col-12 p-mt-2">
              <InputText
                value={claimDetails?.defectReported || ""}
                onChange={(e) => {
                  setclaimDetails({
                    ...claimDetails,
                    defectReported: e.target.value,
                  });
                }}
                placeholder="No Of Times Reported"
                className={
                  errors?.defectReported && "p-inputtext-error p-d-block"
                }
              />
              <small
                className={errors?.defectReported && "label__error p-d-block"}
              >
                {errors?.defectReported}
              </small>
            </div>
            <div className="p-col-12 p-mt-2">
              <InputTextarea
                value={claimDetails?.aprxReporteddate || ""}
                onChange={(e) => {
                  setclaimDetails({
                    ...claimDetails,
                    aprxReporteddate: e.target.value,
                  });
                }}
                placeholder="Reported On Dates List"
                rows={5}
                className={
                  errors?.aprxReporteddate && "p-inputtext-error p-d-block"
                }
              />
              <small
                className={errors?.aprxReporteddate && "label__error p-d-block"}
              >
                {errors?.aprxReporteddate}
              </small>
            </div>

            <div className="p-col-12 p-mt-4">
              <label className="p-d-block">Is Reported To Landlord?</label>
            </div>
            <div className="p-col-12">
              <div className="field-radiobutton">
                <RadioButton
                  inputId="reportedyes"
                  name="reported"
                  value="yes"
                  onChange={(e) => {
                    setclaimDetails({
                      ...claimDetails,
                      reportedToLl: "yes",
                    });
                    setReported(e.value);
                  }}
                  checked={
                    claimDetails?.reportedToLl === "yes" || reported === "yes"
                  }
                  className={
                    errors?.reportedToLl && "p-inputtext-error p-d-block"
                  }
                />
                <small
                  className={errors?.reportedToLl && "label__error p-d-block"}
                >
                  {errors?.reportedToLl}
                </small>
                <label htmlFor="reportedyes" className="p-ml-1">
                  Yes
                </label>
              </div>
              <div className="field-radiobutton">
                <RadioButton
                  inputId="reportedno"
                  name="reported"
                  value="no"
                  onChange={(e) => {
                    setclaimDetails({
                      ...claimDetails,
                      reportedToLl: "no",
                    });
                    setReported(e.value);
                  }}
                  checked={
                    claimDetails?.reportedToLl === "no" || reported === "no"
                  }
                  className={
                    errors?.reportedToLl && "p-inputtext-error p-d-block"
                  }
                />
                <small
                  className={errors?.reportedToLl && "label__error p-d-block"}
                >
                  {errors?.reportedToLl}
                </small>
                <label htmlFor="reportedno" className="p-ml-1">
                  No
                </label>
              </div>
            </div>
            <div className="p-col-12 p-mt-2">
              <InputTextarea
                value={claimDetails?.reportedToLlBy || ""}
                onChange={(e) => {
                  setclaimDetails({
                    ...claimDetails,
                    reportedToLlBy: e.target.value,
                  });
                }}
                disabled={reported === "no"}
                placeholder="Reported To Landlord By"
                rows={5}
                className={
                  errors?.reportedToLlBy && "p-inputtext-error p-d-block"
                }
              />
              <small
                className={errors?.reportedToLlBy && "label__error p-d-block"}
              >
                {errors?.reportedToLlBy}
              </small>
            </div>

            <div className="p-col-12 p-mt-4">
              <label className="p-d-block">Has Landlord Responded?</label>
            </div>
            <div className="p-col-12">
              <div className="field-radiobutton">
                <RadioButton
                  inputId="respondedyes"
                  name="responded"
                  value="yes"
                  onChange={(e) => {
                    setResponded(e.value);
                    setclaimDetails({
                      ...claimDetails,
                      llResponded: e.target.value,
                    });
                  }}
                  checked={
                    claimDetails?.llResponded === "yes" ||
                    claimDetails?.llResponded === "yes"
                  }
                  className={
                    errors?.llResponded && "p-inputtext-error p-d-block"
                  }
                />
                <small
                  className={errors?.llResponded && "label__error p-d-block"}
                >
                  {errors?.llResponded}
                </small>
                <label htmlFor="respondedyes" className="p-ml-1">
                  Yes
                </label>
              </div>
              <div className="field-radiobutton">
                <RadioButton
                  inputId="respondedno"
                  name="responded"
                  value="no"
                  disabled={responded === "no"}
                  onChange={(e) => {
                    setResponded(e.value);
                    setclaimDetails({
                      ...claimDetails,
                      llResponded: e.target.value,
                      llRespondDetail: "",
                    });
                  }}
                  checked={
                    claimDetails?.llResponded === "no" || responded === "no"
                  }
                  className={
                    errors?.llResponded && "p-inputtext-error p-d-block"
                  }
                />
                <small
                  className={errors?.llResponded && "label__error p-d-block"}
                >
                  {errors?.llResponded}
                </small>
                <label htmlFor="respondedno" className="p-ml-1">
                  No
                </label>
              </div>
            </div>
            <div className="p-col-12 p-mt-2">
              <InputTextarea
                value={claimDetails?.llRespondDetail || ""}
                onChange={(e) => {
                  setclaimDetails({
                    ...claimDetails,
                    llRespondDetail: e.target.value,
                  });
                }}
                disabled={responded === "no"}
                placeholder="Landlord Response Copy"
                rows={5}
                className={
                  errors?.llRespondDetail && "p-inputtext-error p-d-block"
                }
              />
              <small
                className={errors?.llRespondDetail && "label__error p-d-block"}
              >
                {errors?.llRespondDetail}
              </small>
            </div>
          </div>
        </div>
        {hasTenant && (
          <div className="p-col-6">
            <div className="p-fluid p-formgrid p-grid p-pr-4">
              {!view && <div className="Panel__Heading">Tenant Info</div>}

              <div className="p-col-12">
                <label className="p-d-block">Start Date *</label>
                <InputText
                  value={tenantDetails?.startDate || ""}
                  onChange={(e) => {
                    setTenantDetails({
                      ...tenantDetails,
                      startDate: e.target.value,
                    });
                  }}
                  type="date"
                  className={errors?.startDate && "p-inputtext-error p-d-block"}
                />
                <small
                  className={errors?.startDate && "label__error p-d-block"}
                >
                  {errors?.startDate}
                </small>
              </div>

              <div className="p-col-12 p-mt-2">
                <label className="p-d-block">Type Of Property *</label>
                <InputText
                  value={tenantDetails?.propertyType || ""}
                  onChange={(e) => {
                    setTenantDetails({
                      ...tenantDetails,
                      propertyType: e.target.value,
                    });
                  }}
                  className={
                    errors?.propertyType && "p-inputtext-error p-d-block"
                  }
                />
                <small
                  className={errors?.propertyType && "label__error p-d-block"}
                >
                  {errors?.propertyType}
                </small>
              </div>

              <div className="p-col-12 p-mt-4">
                <label className="p-d-block">Rent info</label>
              </div>
              <div className="p-col-4">
                <InputText
                  value={tenantDetails?.noOfOccupants || ""}
                  onChange={(e) => {
                    setTenantDetails({
                      ...tenantDetails,
                      noOfOccupants: e.target.value,
                    });
                  }}
                  placeholder="No of occupants"
                  className={
                    errors?.noOfOccupants && "p-inputtext-error p-d-block"
                  }
                />
                <small
                  className={errors?.noOfOccupants && "label__error p-d-block"}
                >
                  {errors?.noOfOccupants}
                </small>
              </div>
              <div className="p-col-4">
                <InputText
                  value={tenantDetails?.rentPw || ""}
                  onChange={(e) => {
                    setTenantDetails({
                      ...tenantDetails,
                      rentPw: e.target.value,
                    });
                  }}
                  placeholder="Rent PW"
                  className={errors?.rentPw && "p-inputtext-error p-d-block"}
                />
                <small className={errors?.rentPw && "label__error p-d-block"}>
                  {errors?.rentPw}
                </small>
              </div>
              <div className="p-col-4">
                <InputText
                  value={tenantDetails?.rentPm || ""}
                  onChange={(e) => {
                    setTenantDetails({
                      ...tenantDetails,
                      rentPm: e.target.value,
                    });
                  }}
                  placeholder="Rent PM"
                  className={errors?.rentPm && "p-inputtext-error p-d-block"}
                />
                <small className={errors?.rentPm && "label__error p-d-block"}>
                  {errors?.rentPm}
                </small>
              </div>

              <div className="p-col-4 p-mt-4">
                <div className="field-checkbox">
                  <Checkbox
                    inputId="rentContributed"
                    checked={rentContributed}
                    onChange={(e) => {
                      setRentContributed(e.checked);
                      setTenantDetails({
                        ...tenantDetails,
                        rentContribute: e.target.value,
                      });
                    }}
                    value="yes"
                    className={
                      errors?.rentContribute && "p-inputtext-error p-d-block"
                    }
                  />
                  <small
                    className={
                      errors?.rentContribute && "label__error p-d-block"
                    }
                  >
                    {errors?.rentContribute}
                  </small>
                  <label htmlFor="rentContributed" className="p-ml-1">
                    Rent Contributed?
                  </label>
                </div>
              </div>
              <div className="p-col-4 p-mt-4">
                <InputText
                  value={tenantDetails?.contributePw || ""}
                  onChange={(e) => {
                    setTenantDetails({
                      ...tenantDetails,
                      contributePw: e.target.value,
                    });
                  }}
                  placeholder="Contribute PW"
                  className={
                    errors?.contributePw && "p-inputtext-error p-d-block"
                  }
                />
                <small
                  className={errors?.contributePw && "label__error p-d-block"}
                >
                  {errors?.contributePw}
                </small>
              </div>
              <div className="p-col-4 p-mt-4">
                <InputText
                  value={tenantDetails?.contributePm || ""}
                  onChange={(e) => {
                    setTenantDetails({
                      ...tenantDetails,
                      contributePm: e.target.value,
                    });
                  }}
                  placeholder="Contribute PM"
                  className={
                    errors?.contributePm && "p-inputtext-error p-d-block"
                  }
                />
                <small
                  className={errors?.contributePm && "label__error p-d-block"}
                >
                  {errors?.contributePm}
                </small>
              </div>

              <div className="p-col-4 p-mt-4">
                <div className="field-checkbox">
                  <Checkbox
                    inputId="inArrears"
                    checked={inArrears}
                    onChange={(e) => {
                      setInArrears(e.checked);
                      setTenantDetails({
                        ...tenantDetails,
                        inArrears: e.target.value,
                      });
                    }}
                    value="yes"
                    className={
                      errors?.inArrears && "p-inputtext-error p-d-block"
                    }
                  />
                  <small
                    className={errors?.inArrears && "label__error p-d-block"}
                  >
                    {errors?.inArrears}
                  </small>
                  <label htmlFor="inArrears" className="p-ml-1">
                    In Arrears?
                  </label>
                </div>
              </div>
              <div className="p-col-4 p-mt-4">
                <InputText
                  value={tenantDetails?.arrearsAmount || ""}
                  onChange={(e) => {
                    setTenantDetails({
                      ...tenantDetails,
                      arrearsAmount: e.target.value,
                    });
                  }}
                  placeholder="Arrears Amount"
                  className={
                    errors?.arrearsAmount && "p-inputtext-error p-d-block"
                  }
                />
                <small
                  className={errors?.arrearsAmount && "label__error p-d-block"}
                >
                  {errors?.arrearsAmount}
                </small>
              </div>
              <div className="p-col-4 p-mt-4"></div>

              <div className="p-col-4 p-mt-4">
                <div className="field-checkbox">
                  <Checkbox
                    inputId="inPaymentPlan"
                    checked={inPaymentPlan}
                    onChange={(e) => {
                      setInPaymentPlan(e.checked);
                      setTenantDetails({
                        ...tenantDetails,
                        inPaymentPlan: e.target.value,
                      });
                    }}
                    value="yes"
                    className={
                      errors?.inPaymentPlan && "p-inputtext-error p-d-block"
                    }
                  />
                  <small
                    className={
                      errors?.inPaymentPlan && "label__error p-d-block"
                    }
                  >
                    {errors?.inPaymentPlan}
                  </small>
                  <label htmlFor="inPaymentPlan" className="p-ml-1">
                    In Payment Plan?
                  </label>
                </div>
              </div>
              <div className="p-col-4 p-mt-4">
                <InputText
                  value={tenantDetails?.paymentPlanPw || ""}
                  onChange={(e) => {
                    setTenantDetails({
                      ...tenantDetails,
                      paymentPlanPw: e.target.value,
                    });
                  }}
                  placeholder="Payment Plan PW"
                  className={
                    errors?.tenantDetails && "p-inputtext-error p-d-block"
                  }
                />
                <small
                  className={errors?.tenantDetails && "label__error p-d-block"}
                >
                  {errors?.tenantDetails}
                </small>
              </div>
              <div className="p-col-4 p-mt-4">
                <InputText
                  value={tenantDetails?.paymentPlanPm || ""}
                  onChange={(e) => {
                    setTenantDetails({
                      ...tenantDetails,
                      paymentPlanPm: e.target.value,
                    });
                  }}
                  placeholder="Payment Plan PM"
                  className={
                    errors?.tenantDetails && "p-inputtext-error p-d-block"
                  }
                />
                <small
                  className={errors?.tenantDetails && "label__error p-d-block"}
                >
                  {errors?.tenantDetails}
                </small>
              </div>

              <div className="p-col-12 p-mt-4">
                <label className="p-d-block">Receiving Benefits?</label>
              </div>
              <div className="p-col-12">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="receivingbenetsyes"
                    name="receivingbenets"
                    value="yes"
                    onChange={(e) => {
                      setReceivingBenet(e.value);
                      setTenantDetails({
                        ...tenantDetails,
                        receivingBenefits: e.value,
                      });
                    }}
                    checked={
                      tenantDetails?.receivingBenefits === "yes" ||
                      receivingBenet === "yes"
                    }
                    className={
                      errors?.tenantDetails && "p-inputtext-error p-d-block"
                    }
                  />
                  <small
                    className={
                      errors?.tenantDetails && "label__error p-d-block"
                    }
                  >
                    {errors?.tenantDetails}
                  </small>
                  <label htmlFor="receivingbenetsyes" className="p-ml-1">
                    Yes
                  </label>
                </div>
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="receivingbenetsno"
                    name="receivingbenets"
                    value="no"
                    onChange={(e) => {
                      setReceivingBenet(e.value);
                      setTenantDetails({
                        ...tenantDetails,
                        receivingBenefits: e.value,
                        benefitsDetail: "",
                      });
                    }}
                    checked={
                      tenantDetails?.receivingBenefits === "no" ||
                      receivingBenet === "no"
                    }
                    className={
                      errors?.tenantDetails && "p-inputtext-error p-d-block"
                    }
                  />
                  <small
                    className={
                      errors?.tenantDetails && "label__error p-d-block"
                    }
                  >
                    {errors?.tenantDetails}
                  </small>
                  <label htmlFor="receivingbenetsno" className="p-ml-1">
                    No
                  </label>
                </div>
              </div>
              <div className="p-col-12 p-mt-2">
                <InputTextarea
                  value={tenantDetails?.benefitsDetail || ""}
                  onChange={(e) => {
                    setTenantDetails({
                      ...tenantDetails,
                      benefitsDetail: e.target.value,
                    });
                  }}
                  disabled={receivingBenet === "no"}
                  placeholder="Benefits List"
                  rows={5}
                  className={
                    errors?.tenantDetails && "p-inputtext-error p-d-block"
                  }
                />
                <small
                  className={errors?.tenantDetails && "label__error p-d-block"}
                >
                  {errors?.tenantDetails}
                </small>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewForm;
