import React from "react";
import { InputText } from "primereact/inputtext";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { updatePassword } from "../../services/Auth";
import "./ChangePassword.css";
import { FaLock } from "react-icons/fa";

function UpdatePassword({ show, hide, isUpdatePwdJob }) {
  const [isloading, setisloading] = React.useState(false);

  const Schema = Yup.object().shape({
    password: Yup.string().required("This field is required"),
    confirmpassword: Yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
  });

  return (
    <Dialog
      header={
        <div className="d-flex align-items-center">
          <FaLock /> &nbsp;Change Password
        </div>
      }
      visible={show}
      style={{ width: "30%" }}
      onHide={() => hide()}
      // contentStyle={{ padding: "0 0rem 2rem 0rem " }}
    >
      <Formik
        initialValues={{
          password: "",
          confirmpassword: "",
        }}
        validationSchema={Schema}
        onSubmit={async (e) => {
          if (e.password && e.confirmpassword) {
            setisloading(true);
            await updatePassword(e.password);
            localStorage.clear();
            window.location.href = "/";
            setisloading(false);
          }
        }}
      >
        {({ values, errors, handleSubmit, handleChange, handleBlur }) => {
          return (
            <div className="password-wrapper">
              <form onSubmit={handleSubmit} className="w-100">
                <div className="p-field ">
                  <label>Password</label> <br />
                  <InputText
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password || ""}
                    type="password"
                    placeholder="Enter a new password"
                    className={
                      errors?.password ? "p-invalid p-d-block w-100" : "w-100"
                    }
                  />
                  <small
                    className={
                      errors?.password && "label__error p-d-block w-100"
                    }
                  >
                    {errors?.password}
                  </small>
                </div>
                <div className="p-field ">
                  <label>Confirm Password</label> <br />
                  <InputText
                    name="confirmpassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmpassword || ""}
                    type="password"
                    placeholder="Re enter a new password"
                    className={
                      errors?.confirmpassword
                        ? "p-invalid p-d-block w-100"
                        : "w-100"
                    }
                  />
                  <small
                    className={
                      errors?.confirmpassword && "label__error p-d-block w-100"
                    }
                  >
                    {errors?.confirmpassword}
                  </small>
                </div>

                <center>
                  <Button
                    disabled={isloading}
                    type="submit"
                    className="btn btn-primary"
                    label={"Update Password"}
                  />
                </center>
              </form>
            </div>
          );
        }}
      </Formik>
    </Dialog>
  );
}

export default UpdatePassword;
