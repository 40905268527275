import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "immigrationSlice",
    initialState: {
        immigration: {},
        immigrationList: [],
        immigrationStatus: [],
    },
    reducers: {
        GETIMMIGRATIONLISTSUCCESS: (state, action) => {
            return {
                ...state,
                immigrationList: action.payload,
            };
        },
        GETIMMIGRATIONLISTERROR: (state) => {
            return {
                ...state,
                immigrationList: [],
            };
        },
        GETIMMIGRATIONSUCCESS: (state, action) => {
            return {
                ...state,
                immigration: action.payload,
            };
        },
        GETIMMIGRATIONERROR: (state) => {
            return {
                ...state,
                immigration: {},
            };
        },
        GETIMMIGRATIONSTATUSSUCCESS: (state, action) => {
            return { ...state, immigrationStatus: action.payload };
        },
        GETIMMIGRATIONSTATUSERROR: (state) => {
            return { ...state, immigrationStatus: {} };
        },
    },
});

export const {
    GETIMMIGRATIONLISTSUCCESS,
    GETIMMIGRATIONLISTERROR,
    GETIMMIGRATIONSUCCESS,
    GETIMMIGRATIONERROR,
    GETIMMIGRATIONSTATUSERROR,
    GETIMMIGRATIONSTATUSSUCCESS,
} = slice.actions;
export default slice.reducer;
