// RtaExcel.js

import React from "react";
import { Button } from "primereact/button";
import * as XLSX from "xlsx";

function RtaExcel({ rta }) {
  const exportRtaToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([rta]);
    XLSX.utils.book_append_sheet(wb, ws, "RTA Data");
    XLSX.writeFile(wb, `${rta?.rtanumber}.xlsx`);
  };

  return (
    <Button
      onClick={exportRtaToExcel}
      className="btn btn-primary"
      style={{
        color: "white",
        fontWeight: "bold",
      }}
    >
      Export to Excel
    </Button>
  );
}

export default RtaExcel;
