import * as Yup from "yup";

const initialValues = {
  amountOfDepositReturned: "",
  arrears: "",
  claimHousingBenefitsLiving: "",
  contact: "",
  currentAddress: "",
  dateMovedOn: "",
  depositPaid: "",
  depositPaidDate: "",
  depositPaidMethod: "",
  depositProtected: "",
  dob: "",
  email: "",
  fullName: "",
  haveLandlordContact: "",
  landlordAddress: "",
  landlordContact: "",
  landlordDamageClaim: "",
  landlordEmailAddress: "",
  landlordLiveInProperty: "",
  nameOfAgency: "",
  noOfTenancyAgrmtSigned: "",
  onlyTenantRegistered: "",
  paidToLandlordOrAgent: "",
  paymentPlan: "",
  receivingBenefits: "",
  rentAmount: "",
  rentArrears: "",
  rentArrearsDetail: "",
  stillLivingInProperty: "",
  termDate: "",
  thinkReason: "",
  withheldReason: "",
};
const validationSchema = Yup.object().shape({
  amountOfDepositReturned: Yup.string().required("required."),
  arrears: Yup.string().required("required."),
  claimHousingBenefitsLiving: Yup.string().required("required."),
  contact: Yup.string().required("required."),
  currentAddress: Yup.string().required("required."),
  dateMovedOn: Yup.string().required("required."),
  depositPaid: Yup.string().required("required."),
  depositPaidDate: Yup.string().required("required."),
  depositPaidMethod: Yup.string().required("required."),
  depositProtected: Yup.string().required("required."),
  dob: Yup.string().required("required."),
  email: Yup.string().required("required."),
  fullName: Yup.string().required("required."),
  haveLandlordContact: Yup.string().required("required."),
  landlordAddress: Yup.string().required("required."),
  landlordContact: Yup.string().required("required."),
  landlordDamageClaim: Yup.string().required("required."),
  landlordEmailAddress: Yup.string().required("required."),
  landlordLiveInProperty: Yup.string().required("required."),
  nameOfAgency: Yup.string().required("required."),
  noOfTenancyAgrmtSigned: Yup.string().required("required."),
  onlyTenantRegistered: Yup.string().required("required."),
  paidToLandlordOrAgent: Yup.string().required("required."),
  paymentPlan: Yup.string().required("required."),
  receivingBenefits: Yup.string().required("required."),
  rentAmount: Yup.string().required("required."),
  rentArrears: Yup.string().required("required."),
  rentArrearsDetail: Yup.string().required("required."),
  stillLivingInProperty: Yup.string().required("required."),
  termDate: Yup.string().required("required."),
  thinkReason: Yup.string().required("required."),
  withheldReason: Yup.string().required("required."),
});
export { initialValues, validationSchema };
