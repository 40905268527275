import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { handleGetRequest } from "../../services/GetTemplate";
import { useFormik } from "formik";
import * as Yup from "yup";
import { clearRtaData } from "../../redux/actions/rtaAction";
import { clearElData } from "../../redux/actions/elAction";
import { useDispatch } from "react-redux";

function AccSubmissionCriteria({ handleAccSum, campaignCode }) {
  const [introducerOptions, setIntroducerOptions] = useState("");
  const [advisorOptions, setAdvisorOptions] = useState("");
  const dispatch = useDispatch();

  const funcgetIntroducer = async () => {
    const res = await handleGetRequest("/getIntoducers/" + campaignCode);
    setIntroducerOptions(res?.data);
  };

  const clearDatafunc = async () => {
    dispatch(clearRtaData());
    dispatch(clearElData());
  };

  useEffect(() => {
    clearDatafunc();
    funcgetIntroducer();
  }, []);

  const handleIntroducer = async (e) => {
    formik.handleChange(e);
    const res = await handleGetRequest("/getAdvisor/" + e.value.companycode);
    setAdvisorOptions(res.data);
  };

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      introducer: Yup.mixed().required("required"),
      advisor: Yup.mixed().required("required."),
    }),
    initialValues: {
      introducer: "",
      advisor: "",
    },
    onSubmit: async (data) => {
      handleAccSum(data);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h6
            style={{
              color: "#428BCA",
              fontWeight: "600",
              lineHeight: "1.42857143",
            }}
          >
            Main
          </h6>
          <small style={{ color: "#999" }}>
            Account and Submission Criteria
          </small>
        </div>
        <div>
          <Button
            type="submit"
            icon="pi pi-arrow-circle-right"
            iconPos="right"
            className="btn btn-primary"
            label="Next"
            onClick={formik.handleSubmit}
          ></Button>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid p-formgrid p-grid p-pr-4 mt-4">
          <div className="p-col-12">
            <label className="p-d-block">Introducer *</label>

            <Dropdown
              filter
              id="introducer"
              name="introducer"
              options={introducerOptions}
              value={formik.values.introducer}
              optionLabel="name"
              placeholder="Select a Name"
              onChange={(e) => handleIntroducer(e)}
            />
            {getFormErrorMessage("introducer")}
          </div>
          <div className="p-col-12 mt-4">
            <label className="p-d-block">Advisor *</label>

            <Dropdown
              id="advisor"
              name="advisor"
              options={advisorOptions}
              value={formik.values.advisor}
              optionLabel="loginid"
              placeholder="Select a Name"
              onChange={formik.handleChange}
            />
            {getFormErrorMessage("advisor")}
          </div>
        </div>
      </form>
    </div>
  );
}

export default AccSubmissionCriteria;
