import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { FaCheckCircle, FaSpinner, FaTimesCircle } from "react-icons/fa";
import { LoqateKey } from "../LaAddress";
import PhoneNumberDetails from "./PhoneNumberDetails";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const VerificationMessage = styled.div`
  margin-left: 10px;
`;

const PhoneNumberVerifier = ({ phoneNumber }) => {
  const [verificationResult, setVerificationResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleVerification = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await axios.get(
        "https://api.addressy.com/PhoneNumberValidation/Interactive/Validate/v2.20/json3.ws",
        {
          params: {
            Key: LoqateKey,
            Phone: phoneNumber,
            Country: "GB", // UK country code
            Iso3Country: "GBR",
            AdditionalFields: "",
          },
        }
      );

      const { data } = response;

      if (
        data.Items.length === 1 &&
        typeof data.Items[0].Error !== "undefined"
      ) {
        // If there's an error
        alert(data.Items[0].Description);
      } else if (data.Items.length === 0) {
        // If no results found
        alert("Sorry, there were no results");
      } else {
        // If successful verification
        setVerificationResult(data.Items[0]);
      }
    } catch (error) {
      console.error("Error verifying phone number:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!phoneNumber || phoneNumber === "") {
    return null;
  }

  return (
    <>
      <Container>
        <Button onClick={handleVerification} disabled={isLoading}>
          {isLoading ? <FaSpinner style={{ marginRight: "5px" }} /> : "Verify"}
        </Button>
        {verificationResult && (
          <VerificationMessage>
            {verificationResult.IsValid === "Yes" ? (
              <FaCheckCircle style={{ color: "green" }} />
            ) : (
              <FaTimesCircle style={{ color: "red" }} />
            )}
          </VerificationMessage>
        )}
      </Container>
      {verificationResult && verificationResult.IsValid === "Yes" && (
        <PhoneNumberDetails details={verificationResult} />
      )}
    </>
  );
};

export default PhoneNumberVerifier;
