import React from "react";
import { FaDownload } from "react-icons/fa";
import styled from "styled-components";

const DownloadButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
`;

const StyledDocName = styled.span`
  padding-right: 5px;
`;

const StyledPdfContainer = styled.div`
  padding: 5px;
`;

function PdfMedia({ docurl }) {
  return (
    <DownloadButton
      onClick={() =>
        window.open(docurl?.replaceAll("\\", "//"), "_blank", "download")
      }
    >
      <StyledPdfContainer>
        <StyledDocName>
          {docurl?.replaceAll("\\", "//").split("/").pop()}
        </StyledDocName>
      </StyledPdfContainer>
      <FaDownload color="#007bff" />
    </DownloadButton>
  );
}

export default PdfMedia;
