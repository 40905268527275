import React from "react";
import LaInputTextArea from "../../../components/LaInputTextArea";
import LaInput from "../../../components/LaInput";

function InjuryDetails({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
  };

  return (
    <>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Describe Your Injuries</label>
        <LaInputTextArea
          keyName="describeinjuries"
          placeholder="Describe Your Injuries"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">How Long Did They Last?</label>
        <LaInput
          keyName="injuriesduration"
          placeholder="How Long Did They Last?"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Medical Attention? When? GP or A&E?</label>
        <LaInput
          keyName="medicalattention"
          placeholder="Medical Attention? When? GP or A&E?"
          {...props}
        />
      </div>
    </>
  );
}

export default InjuryDetails;
