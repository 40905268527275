import React, { useEffect, useRef, useState } from "react";
import { handleGetRequest } from "../../services/GetTemplate";
import { useParams } from "react-router-dom";
import PdfViewer from "../ESign/PdfViewer";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Navigation from "./Navigation";

function CompanyDoc() {
  const [doc, setDoc] = useState([]);
  const dt = useRef(null);
  const { id } = useParams();
  useEffect(() => {
    const getComapanyDoc = async () => {
      try {
        const response = await handleGetRequest("getCompaniesDocs/" + id);
        setDoc(response?.data);
      } catch (error) {
        console.error(error);
      }
    };
    getComapanyDoc();
  }, []);

  if (doc.length === 0) {
    return (
      <>
        <Navigation activeItem="document" id={id} />
        <div className="card mt-2">
          <div className="card-body">
            <h5 className="text-center">No Document Found</h5>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Navigation activeItem="document" id={id} />
      <div className="card mt-2">
        <DataTable
          ref={dt}
          value={doc || []}
          loading={doc === null}
          paginator
          rows={10}
          emptyMessage="No Document Found"
          header={"Docs"}
        >
          <Column
            field="createdon"
            sortable
            header="Added on"
            filter
            filterMatchMode="contains"
          />
          <Column
            field="agenature"
            sortable
            header="Age Nature"
            filter
            filterMatchMode="contains"
            body={({ agenature }) => (agenature === "A" ? "Adult" : "Minor")}
          />
          <Column
            field="bike"
            sortable
            header="Bike"
            filter
            filterMatchMode="contains"
            body={({ bike }) => (bike === "B" ? "Yes" : "No")}
          />
          <Column
            field="countrytype"
            sortable
            header="Country Type"
            filter
            filterMatchMode="contains"
            body={({ countrytype }) =>
              countrytype === "E" ? "England" : "Scotland"
            }
          />
          <Column
            field="driverpassenger"
            sortable
            header="Driver or Passenger"
            filter
            filterMatchMode="contains"
            body={({ driverpassenger }) =>
              driverpassenger === "D" ? "Driver" : "Passenger"
            }
          />
          <Column
            field="path"
            sortable
            header="Path"
            filter
            filterMatchMode="contains"
          />
        </DataTable>
      </div>
    </>
  );
}

export default CompanyDoc;
