import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { SideBox } from "../../components/FormLayout/SideBoxSection/index";
import styled from "styled-components";
import { Checkbox } from "primereact/checkbox";
import { handlePostRequest } from "../../services/PostTemplate";
import "./RtaTasks.css";
import { getrtaDetails } from "../../redux/actions/rtaAction";
import { AiOutlineCloudUpload, AiOutlineUpload } from "react-icons/ai";
import UploadModal from "../../components/UploadModal";

function RtaTasks() {
  const { rta } = useSelector((state) => state.rtaSlice);
  const [showUpload, setShowUpload] = React.useState(false);
  const [rtaTaskCode, setRtaTaskCode] = React.useState("");
  const dispatch = useDispatch();

  const taskStatus = (status) => {
    switch (status) {
      case "N":
        return "New";
      case "P":
        return "Pending";
      case "C":
        return "Completed";
      default:
        return "";
    }
  };

  const handleTaskStatus = async (taskCode, current) => {
    const data = {
      taskCode,
      current: current === true ? "Y" : "N",
      rtaCode: rta.rtacode,
    };
    try {
      await handlePostRequest(data, "rta/setCurrentTask");
      dispatch(getrtaDetails(rta?.rtacode));
    } catch (err) {
      console.log(err);
    }
  };

  const handleAction = async (isFileupload, taskCode) => {
    if (isFileupload === "Y") {
      setRtaTaskCode(taskCode);
      setShowUpload(true);
    } else {
      const formData = new FormData();
      formData.append("taskCode", taskCode);
      formData.append("rtaClaimCode", rta.rtacode);
      formData.append("multipartFiles", null);
      try {
        await handlePostRequest(formData, "rta/performTask");
        dispatch(getrtaDetails(rta?.rtacode));
      } catch (err) {
        console.log(err.response);
      }
    }
  };

  const getDetails = async () => {
    await dispatch(getrtaDetails(rta.rtacode));
  };

  return (
    <>
      <SideBox title="Document Signing Section" className="mt-4">
        {rta?.rtaCaseTasks && (
          <table>
            <tr>
              <th>Description</th>
              <th>Status</th>
              <th>Current</th>
              <th>Action</th>
            </tr>
            {rta?.rtaCaseTasks?.map((task) => (
              <tr>
                <td>{task.tblTask.descr}</td>
                <SyledStatus status={task.status}>
                  {taskStatus(task.status)}
                </SyledStatus>
                <td>
                  <Checkbox
                    value={task.current}
                    disabled={task.status === "C" ? true : false}
                    checked={task.currenttask === "Y" ? true : false}
                    onChange={(e) =>
                      handleTaskStatus(task.rtataskcode, e.checked)
                    }
                  />
                </td>
                <td>
                  <StyledBtn
                    onClick={() =>
                      handleAction(
                        task.tblTask.fileupload,
                        task.tblTask.taskcode
                      )
                    }
                  >
                    {task.tblTask.fileupload === "Y" ? (
                      <AiOutlineCloudUpload
                        color="white"
                        size="20px"
                        className="p-ml-2"
                      />
                    ) : (
                      <AiOutlineUpload
                        color="white"
                        size="20px"
                        className="p-ml-2"
                      />
                    )}
                  </StyledBtn>
                </td>
              </tr>
            ))}
          </table>
        )}
      </SideBox>
      <UploadModal
        taskCode={rtaTaskCode}
        show={showUpload}
        hide={setShowUpload}
        codeName="rtaClaimCode"
        codeValue={rta?.rtacode}
        addFilesService="rta/performTask"
        returnSubmit={getDetails}
      />
    </>
  );
}

const SyledStatus = styled.td`
  color: ${(props) =>
    props.status === "N"
      ? "#FF0000"
      : props.status === "P"
      ? "blue"
      : props.status === "C"
      ? "#2cc05b"
      : "#FF0000"};
  text-align: center;
`;

const StyledBtn = styled.button`
  background-color: #175782;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.42857;
  margin-bottom: 0;
  padding-right: 11px;
  padding-bottom: 6px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  &:hover {
    background-color: #12364e;
    border-color: #adadad;
  }
`;

export default RtaTasks;
