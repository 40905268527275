import { Dialog } from "primereact/dialog";
import React, { useRef, useState, Fragment } from "react";
import { toast } from "react-toastify";
import { handlePostRequest } from "../../services/PostTemplate";
import Dropzone from "./Dropzone";
import "./UploadModal.css";

const UploadModal = ({
  show,
  hide,
  taskCode,
  codeName,
  codeValue,
  returnSubmit,
  addFilesService,
}) => {
  let fileInput = useRef(null);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFiles = async (e) => {
    console.log(e.target.files, "e.target.files");
    const file = await e.target.files;
    await setFiles([...files, ...file]);
    setIsLoading(false);
  };

  const handleDropZoneFiles = async (file) => {
    await setFiles([...files, ...file]);
  };

  const handleSubmit = async (e) => {
    if (files.length) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append(codeName, codeValue);
      files.forEach((file) => {
        formData.append("multipartFiles", file);
      });
      if (taskCode) formData.append("taskCode", taskCode);
      try {
        const res = await handlePostRequest(formData, addFilesService);
        if (res?.responsecode) {
          returnSubmit();
          setFiles([]);
          hide(false);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  //convert bytes to kb and mb
  const bytesToSize = (bytes) => {
    const sizes = ["Bytes", "KB", "MB"];
    if (bytes === 0) return "0 Byte";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  const totalSize = bytesToSize(
    files.reduce((acc, curr) => {
      return acc + curr.size;
    }, 0)
  );

  const footer = (
    <div className="d-flex justify-content-between">
      <small className="modal-footer-text">
        Add files to the upload table and click start <br />
        Accepted formats: png,gif,jpeg,jpg,AVI,MP4,WMV,FLV,3GP,OGG,WEBM.
      </small>
      <button
        onClick={() => {
          hide(false);
          setFiles([]);
        }}
        className="btn btn-default"
      >
        Close
      </button>
    </div>
  );

  return (
    <Dialog
      header={"Upload Files"}
      footer={footer}
      visible={show}
      style={{ width: "50%" }}
      onHide={() => {
        hide(false);
        setFiles([]);
      }}
      contentStyle={{ padding: "0 0rem 2rem 0rem " }}
    >
      <div className="files-table ">
        <div className="row files-table-header">
          <div className="col-md-8">Filename</div>
          <div className="col-md-2">Size</div>
          <div className="col-md-2">Status</div>
        </div>
        <div className="row files-table-body">
          {files &&
            files.map((file, index) => (
              <Fragment key={index}>
                <div className="col-md-8">{file.name}</div>
                <div className="col-md-2">{bytesToSize(file.size)}</div>
                <div className="col-md-2">
                  {isLoading ? "Uploading..." : "-"}
                </div>
              </Fragment>
            ))}
        </div>
      </div>
      <Dropzone handleDropZoneFiles={handleDropZoneFiles} />
      <div className="mt-4">
        <div className="row upload-footer">
          <div className="col-md-8 px-4">
            <button
              disabled={isLoading}
              className="add-files"
              onClick={() => fileInput.click()}
            >
              Add Files
            </button>
            <button
              disabled={isLoading || !files.length}
              onClick={handleSubmit}
              className="start-upload"
            >
              Start Upload
            </button>
            <input
              onChange={handleFiles}
              type="file"
              ref={(input) => (fileInput = input)}
              multiple
              hidden
              accept="*"
            />
          </div>
          <div className="col-md-2">
            <small>{totalSize}</small>
          </div>
          <div className="col-md-2">
            <small className=""> {isLoading ? "Uploading..." : "-"}</small>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default UploadModal;
