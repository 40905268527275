import { Button } from "primereact/button";
import React from "react";
import { handlePostRequest } from "../../../services/PostTemplate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function DuplicateCaseToHire() {
  const { hire } = useSelector((state) => state.hireSlice);
  const navigate = useNavigate();
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const userCatInternal = user?.tblUsercategory?.categorycode;

  const duplicateCasetoHire = async () => {
    const res = await handlePostRequest(
      { hirecode: hire.hirecode },
      "hire/copyHireToHire"
    );
    navigate(`/hiredetails/${res.data?.hirecode}`);
  };

  if (userCatInternal !== "4" || hire?.hirecode === undefined) {
    return null;
  }

  return (
    <Button
      onClick={duplicateCasetoHire}
      label="Copy Case to Hire"
      className="btn-info btn-sm p-mr-2 p-mb-2"
    />
  );
}

export default DuplicateCaseToHire;
