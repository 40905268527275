import * as Yup from "yup";

const initialValues = {
  loginid: "",
  rolecodes: "",
  status: "",
  username: "",
};

const validationSchema = Yup.object().shape({
  loginid: Yup.string().required("required"),
  rolecodes: Yup.mixed().required("required"),
  status: Yup.string().required("required"),
  username: Yup.string().required("required"),
});

export default validationSchema;

export { initialValues, validationSchema };
