import React from "react";
import { useSelector } from "react-redux";
import SideBox from "../../components/FormLayout/SideBoxSection";
import { useNavigate } from "react-router-dom";

function RtaSideBoxPassengersLinkToMain() {
  const { rta } = useSelector((state) => state.rtaSlice);
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const navigate = useNavigate();

  if (user?.tblUsercategory?.categorycode === "2") {
    return null;
  }
  return (
    <>
      {rta?.rtalinkcode ? (
        <SideBox title="Main Case" className="mt-4">
          {rta && (
            <table>
              <tr>
                <tbody>
                  <td style={{ color: "green" }}>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`../rtadetails/${rta?.rtaLinkCaseid}`);
                      }}
                    >
                      {rta?.rtaLinkCaseCode}
                    </div>
                  </td>
                  <td>{rta?.rtaLinkCaseStatus}</td>
                </tbody>
              </tr>
            </table>
          )}
        </SideBox>
      ) : null}
    </>
  );
}

export default RtaSideBoxPassengersLinkToMain;
