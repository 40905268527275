import React from "react";
import LaCheckbox from "../../components/LaCheckbox";
import LaMultiSelect from "../../components/LaMultiSelect/LaMultiSelect";
import LaInput from "../../components/LaInput";

function InvoiceProcessingForm({
  formik,
  isFormFieldValid,
  getFormErrorMessage,
  onChange,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values: formik.values,
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-12 p-mt-2">
            <label htmlFor="companycode">Bill to Account</label>
            <LaMultiSelect
              filter
              keyName="companycode"
              optionLabel="name"
              url="lovActiveCompanies"
              {...props}
            />
          </div>
          <div className="p-col-6 p-mt-4" style={{ display: "flex" }}>
            <LaCheckbox keyName="billallpendingcases" {...props} />
            <label className="p-d-block" style={{ marginLeft: "10px" }}>
              Bill All Pending Cases
            </label>
          </div>
          <div className="p-col-6 p-mt-2">
            <label htmlFor="status">Bill By Date</label>
            <LaInput
              keyName="billByDate"
              type="date"
              {...props}
              disabled={formik.values.billallpendingcases}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default InvoiceProcessingForm;
