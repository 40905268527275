import React from "react";
import { handleGetRequest } from "../../services/GetTemplate";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import styled from "styled-components";
import { FaFileDownload } from "react-icons/fa";

function DownloadAllCompanies() {
  const handleFetch = async () => {
    try {
      const response = await handleGetRequest("userManagement/getCompanyCsv");
      const formattedData = await formatData(response.data);
      downloadExcel(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const formatData = async (data) => {
    const formattedData = [];

    data.forEach((company) => {
      const { accountemail, tblCompanyjobs } = company;
      if (tblCompanyjobs && tblCompanyjobs.length > 0) {
        tblCompanyjobs.forEach((job, index) => {
          const rowData = { accountemail, ...job };
          formattedData.push(rowData);
        });
      } else {
        formattedData.push({ accountemail }); // Ensure accountemail is added here as well
      }
    });

    return formattedData;
  };

  const downloadExcel = (jsonData) => {
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data1 = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const name = "company_profiles";
    const date = new Date().toISOString().slice(0, 10);
    const fileName = `${name}_${date}.xlsx`;
    FileSaver.saveAs(data1, fileName);
  };

  return (
    <div>
      <Button onClick={handleFetch}>
        <FaFileDownload />
      </Button>
    </div>
  );
}

const Button = styled.button`
  background-color: #3ca2bb;
  color: white;
  border: none;
  padding: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 2px;

  svg {
    margin-right: 0.5rem;
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    svg {
      transform: rotate3d(1, 1, 1, 45deg);
    }
  }
`;

export default DownloadAllCompanies;
