import React from "react";
import SideBox from "../FormLayout/SideBoxSection";
import { useSelector } from "react-redux";

const CaseAccountInfo = ({ introducerName, advisorName, campaign }) => {
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);

  const user = userStore && JSON.parse(userStore);
  if (user?.tblUsercategory?.categorycode === "2") {
    return null;
  }

  return (
    <SideBox title="Case Accounts" className="mt-4">
      <strong>{introducerName}</strong>
      <br />
      <small>{advisorName}</small>
      <br />
      <hr />
      <strong>{campaign?.solicitorcompany || ""}</strong>
      <br />
      <small>{campaign?.solicitorusername || ""}</small>
    </SideBox>
  );
};

export default CaseAccountInfo;
