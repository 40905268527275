import React from "react";
import EditViewProfile from "./EditViewProfile";

function CompanyDetails() {
    return (
        <div>
            <EditViewProfile />
        </div>
    );
}

export default CompanyDetails;
