import React, { useEffect, useCallback, useState } from "react";
import { getESigns } from "../../redux/actions/eSignAction";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import PdfViewer from "./PdfViewer";
import Signoff from "./Signoff";
import AlreadySigned from "./AlreadySigned";
import DynamicFields from "./DynamicFields";

const ESign = () => {
  const eFields = useSelector((state) => state?.eSignSlice?.eFields);
  // get user from local storage
  const token = useSelector((state) => state?.authenticationSlice?.token);
  // if token is not found clear local storage
  if (token) {
    localStorage.clear();
    // redirect to login page
    window.location.href = "/";
  }
  const dispatch = useDispatch();
  const url = window.location.href;
  const urlWithoutParams = url.split("?")[1];
  const urlWithParams = urlWithoutParams.split("=");
  const campaignCode = urlWithParams[1];
  const campaignName = urlWithParams[0];
  const alreadySigned = useSelector(
    (state) => state?.eSignSlice?.eFields?.isEsign
  );
  const isDynamicField = useSelector(
    (state) => state?.eSignSlice?.eFields?.dynamicFields
  );

  const handleESigns = useCallback(async () => {
    dispatch(await getESigns(campaignCode, campaignName));
  }, [dispatch, campaignCode, campaignName]);

  useEffect(() => {
    handleESigns();
  }, [handleESigns]);

  return (
    <MainContainer>
      <center>
        <h3 className="mt-2">Conditional Fee Agreement</h3>
      </center>
      {alreadySigned && alreadySigned === "Y" ? (
        <AlreadySigned />
      ) : alreadySigned && alreadySigned === "N" ? (
        <>
          <DynamicFields
            campaignCode={campaignCode}
            campaignName={campaignName}
          />
          {(isDynamicField && isDynamicField.length === 0) ||
          isDynamicField === null ? (
            <Signoff campaignCode={campaignCode} campaignName={campaignName} />
          ) : null}
        </>
      ) : null}
      <PdfViewer doc={eFields?.esignDocPath} />
    </MainContainer>
  );
};

// Styled Components
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  max-width: 100%;
  overflow-x: hidden;

  @media (max-width: 768px) {
    padding: 10px;
  }

  @media (max-width: 480px) {
    padding: 0px;
  }
`;

export default ESign;
