import React from "react";
import InjuryInfo from "./InjuryInfo";
import MainInfo from "./MainInfo";

function AccidentInfo({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  const props = {
    classNames,
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    formik,
  };

  return (
    <div>
      <div style={{ marginTop: "5%" }}>
        <h6
          style={{
            color: "#428BCA",
            fontWeight: "600",
            lineHeight: "1.42857143",
          }}
        >
          Accident Info
        </h6>
        <small style={{ color: "#999" }}>Enter accident information</small>
      </div>
      <hr className="border__hr" />
      <div className="row">
        <div className="col-md-4">
          <div style={{ marginLeft: "30px" }}>
            <h6 style={{ fontWeight: "bold", fontSize: "12px" }}>Main Info</h6>
          </div>
          <hr className="border__hr" />
          <div className="p-fluid p-formgrid p-grid p-pr-4">
            <MainInfo {...props} />
          </div>
        </div>
        <div className="col-md-4">
          <div style={{ marginLeft: "30px" }}>
            <h6 style={{ fontWeight: "bold", fontSize: "12px" }}>
              Injury Info
            </h6>
          </div>
          <hr className="border__hr" />
          <div className="p-fluid p-formgrid p-grid p-pr-4">
            <InjuryInfo {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccidentInfo;
