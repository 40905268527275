import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "authenticationSlice",
  initialState: {
    hdr: {},
    hdrList: [],
    hdrStatus: [],
  },
  reducers: {
    GETHDRLISTSUCCESS: (state, action) => {
      return {
        ...state,
        hdrList: action.payload,
      };
    },
    GETHDRLISTERROR: (state) => {
      return {
        ...state,
        hdrList: [],
      };
    },
    GETHDRSUCCESS: (state, action) => {
      return {
        ...state,
        hdr: action.payload,
      };
    },
    GETHDRERROR: (state) => {
      return {
        ...state,
        hdr: {},
      };
    },
    GETHDRSTATUSSUCCESS: (state, action) => {
      return { ...state, hdrStatus: action.payload };
    },
    GETHDRSTATUSERROR: (state) => {
      return { ...state, hdrStatus: {} };
    },
  },
});

export const {
  GETHDRLISTSUCCESS,
  GETHDRLISTERROR,
  GETHDRSUCCESS,
  GETHDRERROR,
  GETHDRSTATUSERROR,
  GETHDRSTATUSSUCCESS,
} = slice.actions;
export default slice.reducer;
