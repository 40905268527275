import React from "react";
import { MainWrapper } from "../../components/FormLayout/FormHeader/index";
import {
  SideBoxWrapper,
  SideBox,
} from "../../components/FormLayout/SideBoxSection/index";
import { InfoWrapper, InfoButton } from "../../components/InfoButton";
import { AiOutlineBars } from "react-icons/ai";
import { useSelector } from "react-redux";
import UploadModal from "../../components/UploadModal";
import ViewImmigrationForm from "./ViewImmigrationForm";
import CaseHeaderImmigration from "./CaseHeaderImmigration";

export default function ImmigrationDetails() {
  const { immigration } = useSelector((state) => state.immigrationSlice);
  const [showUpload, setShowUpload] = React.useState(false);
  const [hdrTaskCode, setHdrTaskCode] = React.useState("");
  return (
    <>
      {/* <CaseHeaderImmigration
                title={immigration.immigrationCode}
                subTitle={` ${immigration?.statusDescr}`}
                search
                onSearch={(val) => console.log(val)}
            >
                <InfoWrapper>
                    <InfoButton
                        title={"All"}
                        count={53}
                        active
                        icon={<AiOutlineBars />}
                    />
                    <InfoButton
                        title={"New"}
                        count={53}
                        active
                        icon={<AiOutlineBars />}
                    />
                </InfoWrapper>
            </CaseHeaderImmigration> */}
      <MainWrapper>
        <ViewImmigrationForm />
        <SideBoxWrapper>
          <SideBox title="Claim Task">
            {"tblHdrtasks" in immigration &&
              immigration?.tblpltasks.map(
                (
                  {
                    tblTask: { name, crdate },
                    completedby,
                    completedon,
                    createdon,
                    currenttask,
                    hdrtaskcode,
                    remarks,
                    status,
                    taskcode,
                  },
                  index
                ) => (
                  <div
                    className="row"
                    key={taskcode}
                    onClick={() => {
                      if (status === "P") {
                        setShowUpload(true);
                        setHdrTaskCode(taskcode);
                      }
                    }}
                  >
                    <div className="col-md-5">
                      <p
                        onClick={() => (status === "P" ? "waiting" : null)}
                        className={status === "P" && "text-semibold pointer"}
                      >
                        {name}
                      </p>
                    </div>
                    <div className="col-md-4">
                      {status === "C" && <small>Completed</small>}
                      {status === "P" && <small>Pending</small>}
                    </div>
                    <div className="col-md-3">
                      <small className="text-muted">{crdate}</small>
                    </div>
                  </div>
                )
              )}
          </SideBox>
          <SideBox title="Solicitors" className="mt-4">
            {immigration &&
              "tblHdrsolicitors" in immigration &&
              immigration?.tblHdrsolicitors &&
              immigration?.tblHdrsolicitors.map(
                ({ tblCompanyprofile }, index) => (
                  <div key={index}>
                    <b>{tblCompanyprofile?.name}</b>
                    <br />
                    <small className="text-muted">
                      {tblCompanyprofile?.contactperson}
                    </small>
                    {index > 0 && <hr className="text-muted" />}
                  </div>
                )
              )}
          </SideBox>
        </SideBoxWrapper>
      </MainWrapper>
      <UploadModal
        taskCode={hdrTaskCode}
        show={showUpload}
        hide={setShowUpload}
      />
    </>
  );
}
