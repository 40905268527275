import { Dialog } from "primereact/dialog";
import React from "react";
import HireAcceptForm from "./HireAcceptForm";

function HireAcceptDialog({
  showModal,
  onCloseModal,
  buttonDetails,
  hireclaimcode,
}) {
  return (
    <Dialog
      header="Hire Accept"
      visible={showModal}
      style={{ width: "70vw" }}
      onHide={onCloseModal}
    >
      <HireAcceptForm
        onCloseModal={onCloseModal}
        buttonDetails={buttonDetails}
        hireclaimcode={hireclaimcode}
      />
    </Dialog>
  );
}

export default HireAcceptDialog;
