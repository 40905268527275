import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { handleGetRequest } from "../../../services/GetTemplate";
import { handlePostRequest } from "../../../services/PostTemplate";
import { getMedicalNegligenceDetails } from "../../../redux/actions/medicalNegligenceAction";
import { SplitButton } from "primereact/splitbutton";
import "./CaseHeaderMN.css";
import { Divider } from "primereact/divider";
import { useParams } from "react-router-dom";

function CaseHeaderMN({ title, subTitle }) {
  const [solicitorMNData, setsolicitorMNData] = useState("");
  const [solicitorMNDataValue, setsolicitorMNDataValue] = useState("");
  const [mnHotkeyModal, setmnHotkeyModal] = useState(false);
  const [companyWiseUser, setcompanyWiseUser] = useState("");
  const [companyWiseUserValue, setcompanyWiseUserValue] = useState("");
  const [rejectModal, setrejectModal] = useState(false);
  const [reason, setreason] = useState("");
  const [rejectBtnValue, setrejectBtnValue] = useState("");
  const [loading, setloading] = useState(false);
  const [loadingIcon, setloadingIcon] = useState("");
  const [btnValue, setbtnValue] = useState("");
  const dispatch = useDispatch();
  const { code } = useParams();
  const mncode = code;

  const breakpoints = { "960px": "75vw", "640px": "100vw" };

  const createdon = useSelector(
    (state) => state?.medicalNegligenceSlice?.medicalNegligenceSlice?.submitDate
  );

  const funcgetSolicitorsForMn = async () => {
    const res = await handleGetRequest("lovSolicitorsForMn/");
    setsolicitorMNData(res.data);
  };

  useEffect(() => {
    funcgetSolicitorsForMn();
  }, []);

  const handleSolicitor = async (e) => {
    setsolicitorMNDataValue(e.value);
    const res = await handleGetRequest("lovCompanyWiseUSer/" + e.value.code);
    setcompanyWiseUser(res.data);
  };

  const mnActionButtons = useSelector(
    (state) => state?.medicalNegligenceSlice?.medicalNegligence?.mnActionButtons
  );
  const mnActionButtonForLA = useSelector(
    (state) =>
      state?.medicalNegligenceSlice?.medicalNegligence?.mnActionButtonForLA
  );

  let medicalNegligenceActionBtnitems = [];
  if (mnActionButtonForLA !== null && mnActionButtonForLA !== undefined) {
    mnActionButtonForLA.map((item) => {
      medicalNegligenceActionBtnitems.push({
        label: item?.buttonname,
        command: () => {
          handleActionButton(item);
        },
      });
    });
  }

  const fetchMNDetails = async () => {
    await dispatch(getMedicalNegligenceDetails(mncode));
  };

  const handleActionHotKey = (value) => {
    setbtnValue(value);
    setmnHotkeyModal(true);
  };

  const handleSubmitSolicitor = async () => {
    const data = {
      mncode: mncode.toString(),
      solicitorCode: solicitorMNDataValue.code,
      solicitorUserCode: companyWiseUserValue.code,
      toStatus: btnValue,
    };
    await handlePostRequest(data, "medneg/performActionOnMnFromDirectIntro");
    setmnHotkeyModal(false);
    fetchMNDetails();
  };

  const footer = (
    <div>
      <center className="p-mt-2 p-button-outlined">
        <Button
          className="btn btn-primary"
          onClick={handleSubmitSolicitor}
          label="Submit"
        />
      </center>
    </div>
  );

  const handleActionButton = async (value) => {
    if (value.rejectDialog === "Y" || value?.acceptDialog === "Y") {
      // send reason on accept case as well
      setrejectModal(true);
      setrejectBtnValue(value.buttonvalue);
    } else {
      const data = {
        mnCode: mncode,
        toStatus: value.buttonvalue,
      };
      await handlePostRequest(data, "medneg/performActionOnMn");
      fetchMNDetails();
    }
  };

  const handlRejectActionButton = async () => {
    setloading(true);
    setloadingIcon("pi pi-spin pi-spinner");
    const data = {
      mnCode: mncode,
      toStatus: rejectBtnValue,
      reason,
    };
    try {
      await handlePostRequest(data, "medneg/performActionOnMn");
      setrejectModal(false);
      setrejectBtnValue("");
      setloading(false);
      setloadingIcon("");
      fetchMNDetails();
    } catch (e) {
      setloading(false);
      setloadingIcon("");
    }
  };

  const actionButtons = (
    <div>
      {mnActionButtons && mnActionButtons !== null
        ? mnActionButtons.map((item) => {
            if (
              item.apiflag === "Y" &&
              localStorage.getItem("directIntroducer") === "true"
            )
              return (
                <Button
                  value={item.buttonvalue}
                  onClick={(e) => {
                    handleActionHotKey(item.buttonvalue);
                  }}
                  label={item.buttonname}
                  className="btn-info btn-sm p-mr-2 p-mb-2"
                />
              );
            else
              return (
                <Button
                  value={item.buttonvalue}
                  onClick={(e) => {
                    handleActionButton(item);
                  }}
                  label={item.buttonname}
                  className="btn-info btn-sm p-mr-2 p-mb-2"
                />
              );
          })
        : null}
    </div>
  );

  const Rejectfooter = (
    <div>
      <center className="p-mt-2 p-button-outlined">
        <Button
          icon={loadingIcon || ""}
          disabled={loading}
          onClick={handlRejectActionButton}
          label="Submit"
          className="btn btn-primary"
        />
      </center>
    </div>
  );

  if (!subTitle) {
    return null;
  }

  return (
    <>
      <div className="case-header">
        <div className="col-lg-4">
          <h4>{title}</h4>
        </div>

        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <div className="mt-2 p-mr-2">{createdon} </div>
          <Divider style={{ padding: "0px" }} layout="vertical" />
          {subTitle ? (
            <div>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                }}
                label={subTitle}
                style={{ cursor: "text" }}
                className="btn-info btn-sm p-mr-2 p-mb-2"
              />
            </div>
          ) : null}

          <Divider style={{ padding: "0px" }} layout="vertical" />
          <div>{actionButtons}</div>
          {mnActionButtonForLA !== null && mnActionButtonForLA !== undefined ? (
            <div>
              <SplitButton
                icon="pi pi-sliders-h"
                model={medicalNegligenceActionBtnitems}
              ></SplitButton>
            </div>
          ) : null}
        </div>
        <Dialog
          header="Solicitor for Pl"
          visible={mnHotkeyModal}
          footer={footer}
          onHide={() => setmnHotkeyModal(false)}
          breakpoints={breakpoints}
          style={{ width: "50vw" }}
        >
          <div
            className="p-fluid p-formgrid p-grid p-mt-4"
            style={{ paddingBottom: "30%" }}
          >
            <div className="p-field p-col-12 p-md-6">
              <label>Hotkey Account</label>
              <Dropdown
                className="Width__100 "
                options={solicitorMNData}
                value={solicitorMNDataValue}
                onChange={(e) => {
                  handleSolicitor(e);
                }}
                placeholder="Select"
                optionLabel="name"
              />
            </div>
            <div className="p-field p-col-12 p-md-6">
              <label>Hotkey User</label>
              <Dropdown
                className="Width__100 "
                options={companyWiseUser}
                value={companyWiseUserValue}
                onChange={(e) => {
                  setcompanyWiseUserValue(e.value);
                }}
                placeholder="Select"
                optionLabel="name"
              />
            </div>
          </div>
        </Dialog>
        <Dialog
          header="Action"
          visible={rejectModal}
          footer={Rejectfooter}
          onHide={() => setrejectModal(false)}
          breakpoints={breakpoints}
          style={{ width: "50vw" }}
        >
          <div className="p-mt-4">
            <InputTextarea
              className="Width__100"
              rows={5}
              cols={60}
              placeholder="Enter Reason "
              value={reason}
              onChange={(e) => setreason(e.target.value)}
            />
          </div>
        </Dialog>
      </div>
    </>
  );
}

export default CaseHeaderMN;
