import { baseURL } from "../Config";
import axios from "axios";
import { toast } from "react-toastify";
import { SETLOADING } from "../redux/slices/loadingSlice";
import store from "../redux/store";
import { refreshToken } from "./Auth";

export const handlePostRequest = async (data, url, options = {}) => {
  // let callRefreshtoken = false;
  const customOptions = {
    Authorization: localStorage.getItem("token"),
    ...options,
    "Content-Type": "application/json",
  };
  store.dispatch(SETLOADING(true));
  try {
    const response = await axios({
      method: "post",
      url: `${baseURL + url}`,
      data: data,
      headers: customOptions,
    });
    toast.success(response?.data?.messages);
    store.dispatch(SETLOADING(false));
    return response?.data;
  } catch (error) {
    toast.warn(error?.response?.data?.messages || "Something went wrong !!");
    store.dispatch(SETLOADING(false));
    // if (error?.response?.status === 401 || error?.response?.status === 500) {
    //   if (!callRefreshtoken) {
    //     callRefreshtoken = true;
    //     await refreshToken();
    //     handlePostRequest(data, url, options);
    //   } else {
    //     // localStorage.clear();
    //     // window.location.href = "../";
    //   }
    // }
    throw error;
  }
};

export const UploadFiles = async (data, url) => {
  const customOptions = {
    Authorization: localStorage.getItem("token"),
    "Content-Type": "application/json",
  };
  store.dispatch(SETLOADING(true));
  try {
    const response = await axios({
      method: "post",
      url: `${baseURL + url}`,
      data: data,
      headers: customOptions,
    });
    store.dispatch(SETLOADING(false));
    return response?.data;
  } catch (error) {
    toast.warn(error?.response?.data?.messages || "Something went wrong !!");
    store.dispatch(SETLOADING(false));
    if (error?.response?.status === 401 || error?.response?.status === 500) {
      localStorage.clear();
      window.location.href = "../";
    }
    throw error;
  }
};
