import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

// Custom marker icon
const markerIcon = new L.Icon({
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  shadowSize: [41, 41],
});

function MapComponent({ latitude, longitude, address }) {
  if (latitude === null || longitude === null) {
    return null;
  }

  // Filter out empty strings from the address array
  const filteredAddress = address.filter((line) => line?.trim() !== "");

  // Join the filtered address lines into a single string
  const formattedAddress = filteredAddress.join(", ");

  return (
    <div style={{ height: "600px", padding: "1rem" }}>
      <MapContainer
        key={latitude + longitude}
        center={[latitude, longitude]}
        zoom={20}
        style={{ height: "600px" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[latitude, longitude]} icon={markerIcon}>
          <Popup>
            <div>
              <strong>Address:</strong>
              <br />
              {formattedAddress}
            </div>
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}

export default MapComponent;
