import React from "react";
import PropTypes from "prop-types";
import "./InfoButton.css";

function InfoButton({ icon, title, active, count }) {
  return (
    <div className={active ? "info-button info-button-active" : "info-button"}>
      {icon}
      {title}
      {count ? <span className="info-button-count">{count}</span> : null}
    </div>
  );
}

function InfoWrapper({ children }) {
  return <div className="info-list">{children}</div>;
}

export { InfoButton, InfoWrapper };

React.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
};
