import React from "react";
import { Button } from "primereact/button";
import HireAcceptButton from "./HireAccept/HireAcceptButton";
import HireRejectButton from "./HireReject/HireRejectButton";
import HireCancelButton from "./HireCancel/HireCancelButton";
import HireAssignButton from "./HireAssign/HireAssignButton";
import { handlePostRequest } from "../../../../services/PostTemplate";
import { useDispatch } from "react-redux";
import { getHireDetails } from "../../../../redux/actions/hireAction";

function HireActionButtons({ hireActionButtons, hireclaimcode }) {
  const dispatch = useDispatch();

  const handleActionBtn = async (item) => {
    const { buttonvalue } = item;
    const data = {
      hireclaimcode: hireclaimcode,
      toStatus: buttonvalue,
    };

    try {
      await handlePostRequest(data, "hire/performActionOnHire");
      dispatch(getHireDetails(hireclaimcode));
    } catch (error) {
      console.error("Error", error);
    }
  };

  if (!hireActionButtons || hireActionButtons.length === 0 || !hireclaimcode) {
    return null;
  }

  return (
    <div>
      {hireActionButtons.map((item) => {
        const {
          buttonvalue,
          buttonname,
          caserejectdialog,
          caseassigndialog,
          caseacceptdialog,
          casecanceldialog,
        } = item;

        switch (true) {
          case caserejectdialog === "Y":
            return (
              <HireRejectButton
                buttonDetails={item}
                hireclaimcode={hireclaimcode}
                key={buttonvalue}
              />
            );
          case caseassigndialog === "Y":
            return (
              <HireAssignButton
                buttonDetails={item}
                hireclaimcode={hireclaimcode}
                key={buttonvalue}
              />
            );
          case caseacceptdialog === "Y":
            return (
              <HireAcceptButton
                buttonDetails={item}
                hireclaimcode={hireclaimcode}
                key={buttonvalue}
              />
            );
          case casecanceldialog === "Y":
            return (
              <HireCancelButton
                buttonDetails={item}
                hireclaimcode={hireclaimcode}
                key={buttonvalue}
              />
            );
          default:
            if (
              caserejectdialog === "N" &&
              caseassigndialog === "N" &&
              caseacceptdialog === "N" &&
              casecanceldialog === "N"
            ) {
              return (
                <Button
                  key={buttonvalue}
                  value={buttonvalue}
                  onClick={() => handleActionBtn(item)}
                  label={buttonname}
                  className="btn-info btn-sm p-mr-2 p-mb-2"
                />
              );
            } else {
              return null;
            }
        }
      })}
      {/* TODO: Create a component for the following button */}
      {/* {user?.tblUsercategory?.categorycode === "4" ||
      user?.tblUsercategory?.categorycode === "1" ? (
        <Button
          onClick={(e) => {
            e.preventDefault();
            copyCase();
          }}
          label={"Copy Case To Rta"}
          className="btn-info btn-sm p-mr-2 p-mb-2"
        />
      ) : null} */}
    </div>
  );
}

export default HireActionButtons;
