import React, { useEffect, useState } from "react";
import { handleGetRequest } from "../../services/GetTemplate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { AiOutlineEye } from "react-icons/ai";
import { Card } from "primereact/card";
import styled from "styled-components";

function EmailStatus() {
  const [emailStatusList, setEmailStatusList] = useState([]);
  const [viewEmailDetails, setViewEmailDetails] = useState(null);
  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);

  const fetchEmailStatus = async () => {
    try {
      const response = await handleGetRequest("getEmailStatus");
      setEmailStatusList(response.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleAttachmentClick = (rowData) => {
    if (rowData.emailattachment) {
      setSelectedAttachment(rowData.emailattachment);
      setShowAttachmentDialog(true);
    }
  };

  const onHideAttachmentDialog = () => {
    setSelectedAttachment(null);
    setShowAttachmentDialog(false);
  };

  useEffect(() => {
    fetchEmailStatus();

    const fetchDataInterval = setInterval(() => {
      fetchEmailStatus();
    }, 10000);

    return () => clearInterval(fetchDataInterval);
  }, []);

  const actionTemplate = (rowData) => {
    return (
      <div className="pointer">
        <AiOutlineEye
          color="green"
          size="20"
          onClick={() => setViewEmailDetails(rowData)}
        />
      </div>
    );
  };

  const senFlagTemplate = (rowData) => {
    return (
      <StatusChip status={rowData.senflag}>
        {rowData.senflag ? "Yes" : "No"}
      </StatusChip>
    );
  };

  const keyOrder = [
    { key: "createdon", name: "Created On" },
    { key: "emailaddress", name: "Sent To" },
    { key: "ccemailaddress", name: "CC" },
    { key: "emailbody", name: "Message" },
  ];

  return (
    <Card>
      <h1>Email Status</h1>
      <DataTable value={emailStatusList}>
        <Column
          field="createdon"
          sortable
          header="Created On"
          filter
          filterMatchMode="contains"
        ></Column>

        <Column
          field="emailaddress"
          sortable
          header="Sent To"
          filter
          filterMatchMode="contains"
        ></Column>

        <Column
          field="emailattachment"
          sortable
          header="Attachment"
          filter
          filterMatchMode="contains"
          body={(rowData) =>
            rowData.emailattachment ? (
              <Button onClick={() => handleAttachmentClick(rowData)}>
                Open
              </Button>
            ) : (
              <NullChip>{rowData.emailattachment}</NullChip>
            )
          }
        ></Column>
        <Column
          field="senflag"
          header="Sen Flag"
          sortable
          body={senFlagTemplate}
          filter
          filterMatchMode="contains"
        ></Column>

        <Column
          body={actionTemplate}
          header="Acts"
          filter
          filterMatchMode="contains"
        ></Column>
      </DataTable>

      <Dialog
        header="Email Attachment"
        visible={showAttachmentDialog}
        onHide={onHideAttachmentDialog}
        style={{ width: "50%" }}
      >
        <div>{selectedAttachment}</div>
      </Dialog>

      <Dialog
        header={"Email Details"}
        visible={viewEmailDetails}
        style={{ width: "50%" }}
        onHide={() => setViewEmailDetails(null)}
        contentStyle={{ padding: "0 0rem 2rem 0rem " }}
      >
        <div>
          {viewEmailDetails &&
            keyOrder.map(({ key, name }) => {
              return (
                <div className="p-2" key={key}>
                  <strong>{name}</strong> :{" "}
                  {key === "emailbody" ? (
                    <div
                      className="pb-4"
                      dangerouslySetInnerHTML={{
                        __html: viewEmailDetails[key],
                      }}
                    ></div>
                  ) : viewEmailDetails[key] !== undefined ? (
                    viewEmailDetails[key]
                  ) : (
                    "-"
                  )}
                </div>
              );
            })}
        </div>
      </Dialog>
    </Card>
  );
}

const StatusChip = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px; // This is shorthand for padding-top, padding-right, padding-bottom, padding-left
  font-size: 12px;
  width: 100px;
  color: white;
  background-color: ${({ status }) => (status ? "green" : "red")};
`;

const NullChip = styled(StatusChip)`
  background-color: #ff6347;
  &:before {
    content: "Null";
  }
`;

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  font-size: 12px;
  border: none;
  width: 100px;
  cursor: pointer;
  background-color: #f0f0f0;
  &:hover {
    background-color: #e0e0e0;
  }
`;

export default EmailStatus;
