import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navigation from "../Navigation";
import CompanyJobDetails from "./CompanyJobDetails";
import AddJobButton from "./AddJob/AddJobButton";
import { handleGetRequest } from "../../../services/GetTemplate";
import NoJobsComponent from "./NoJobsComponent";

function ViewCompanyJobs() {
  const { id } = useParams();
  const [jobList, setjobList] = useState([]);
  const [jobFound, setjobFound] = useState(true);

  const getjobList = async () => {
    try {
      const response = await handleGetRequest("getCompaniesJobs/" + id);
      setjobList(response?.data);
      if (response?.data === null) {
        setjobFound(false);
      } else {
        setjobFound(true);
      }
    } catch (error) {
      setjobFound(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getjobList();
    }
  }, [id]);

  return (
    <>
      <Navigation activeItem="jobs" id={id} />
      {jobFound === false ? (
        <NoJobsComponent getjobList={getjobList} id={id} />
      ) : (
        <>
          <div className="d-flex justify-content-end mt-4">
            <AddJobButton getjobList={getjobList} id={id} />
          </div>
          <CompanyJobDetails jobList={jobList} getjobList={getjobList} />
        </>
      )}
    </>
  );
}

export default ViewCompanyJobs;
