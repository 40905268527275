import * as Yup from "yup";

const initialValues = {
  accdatetime: "",
  acctime: "",
  accdescription: "",
  acclocation: "",
  accreportedto: "",
  describeinjuries: "",
  dob: "",
  email: "",
  injuriesduration: "",
  landline: "",
  medicalattention: "",
  mobile: "",
  ninumber: "",
  occupation: "",
  password: "",
  remarks: "",
  witnesses: "",
  title: "",
  firstname: "",
  middlename: "",
  lastname: "",
  postalcode: "",
  address1: "",
  address2: "",
  address3: "",
  city: "",
  region: "",
  anywitnesses: "",
};

const validationSchema = Yup.object().shape({
  // addressOfClient: Yup.string().required("Required"),
  // brokeraddress: Yup.string().required("Required"),
  // brokername: Yup.string().required("Required"),
  // cashDeposit: Yup.string().required("Required"),
  // chargeForCredit: Yup.string().required("Required"),
  // contactNo: Yup.string().required("Required"),
  // contractDate: Yup.string().required("Required"),
  // contractualApr: Yup.string().required("Required"),
  // contractualMonthlyPayment: Yup.string().required("Required"),
  // dealeraddress: Yup.string().required("Required"),
  // dealername: Yup.string().required("Required"),
  // dob: Yup.string().required("Required"),
  // emailAddress: Yup.string().required("Required"),
  // // esig: Yup.string().required("Required"),
  // // esigdate: Yup.string().required("Required"),
  // fcaNumber: Yup.string().required("Required"),
  // fullName: Yup.string().required("Required"),
  // lenderaddress: Yup.string().required("Required"),
  // lendername: Yup.string().required("Required"),
  // lowestAdvertisedRate: Yup.string().required("Required"),
  // makemodel: Yup.string().required("Required"),
  // niNumber: Yup.string().required("Required"),
  // optionToPurchaseFee: Yup.string().required("Required"),
  // partExchange: Yup.string().required("Required"),
  // purchaseDate: Yup.string().required("Required"),
  // purchasePrice: Yup.string().required("Required"),
  // registration: Yup.string().required("Required"),
  // // remarks: Yup.string().required("Required"),
  // setupFees: Yup.string().required("Required"),
  // totalPayable: Yup.string().required("Required"),
  // contractualTerm: Yup.string().required("Required"),
  // endOfAgreement: Yup.string().required("Required"),
});

export { initialValues, validationSchema };
