import React, { useState } from "react";
import "../../components/CaseHeader/CaseHeader.css";
import PropTypes from "prop-types";
import InvoiceProcessing from "./InvoiceProcessing";
import InvoiceFilterMenu from "./InvoiceFilterMenu";
import { Dialog } from "primereact/dialog";
import * as XLSX from "xlsx";
import { handleGetRequest } from "../../services/GetTemplate";
import FileSaver from "file-saver";
import { useNavigate } from "react-router-dom";

function CaseHeaderInvoice({ title, subTitle }) {
  const [displayFilter, setDisplayFilter] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const handleFetch = async (url, type) => {
    try {
      const response = await handleGetRequest(url);
      setData(response.data);
      const filteredData = response.data.filter((item) => item !== null);
      downloadExcel(filteredData, type);
    } catch (e) {
      console.log(e);
    }
  };

  const downloadExcel = (jsonData, type) => {
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data1 = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const name = type === "list" ? "invoiceList" : "invoiceLines";
    const date = new Date().toISOString().slice(0, 10);
    const fileName = `${name}_${date}.xlsx`;
    FileSaver.saveAs(data1, fileName);
  };

  const registerationnoItems = [
    {
      label: "Will be provided to the solicitor",
      command: (e) => {
        console.log(e);
      },
    },
  ];

  return (
    <div className="case-header">
      <div className="col-lg-4">
        <h6>{title}</h6>
        <small>{subTitle}</small>
      </div>
      <div className="col-lg-8" style={{ textAlign: "right" }}>
        <button
          onClick={() => {
            navigate("/manualinvoice");
          }}
          className="btn btn-block btn-primary mr-2 mt-2"
        >
          Manual Invoice
        </button>
        <button
          onClick={() => {
            setIsProcessing(false);
            setDisplayFilter(true);
          }}
          className="btn btn-block btn-primary mr-2 mt-2"
        >
          Filter
        </button>
        <button
          onClick={() => {
            setIsProcessing(true);
            setDisplayFilter(true);
          }}
          className="btn btn-block btn-primary mr-2 mt-2"
        >
          Processing
        </button>
        <button
          onClick={() => {
            handleFetch("/invoicing/getInvoiceListCsv", "list");
          }}
          className="btn btn-block btn-primary mr-2 mt-2"
        >
          Invoice List CSV
        </button>
        <button
          onClick={() => {
            handleFetch("/invoicing/getInvoiceLinesCsv", "lines");
          }}
          className="btn btn-block btn-primary mr-2 mt-2"
        >
          Invoice Lines CSV
        </button>
      </div>
      <Dialog
        header={isProcessing ? "Invoice Processing" : "Invoice Filter"}
        visible={displayFilter}
        style={{ width: "50vw" }}
        onHide={() => setDisplayFilter(false)}
        position="right"
      >
        {isProcessing ? <InvoiceProcessing /> : <InvoiceFilterMenu />}
      </Dialog>
    </div>
  );
}

export default CaseHeaderInvoice;

React.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  search: PropTypes.bool,
  children: PropTypes.element,
  onSearch: PropTypes.func,
};
