import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";

function ClaimantDetails({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
}) {
  return (
    <div>
      <div className="p-mt-4 p-ml-4 p-mr-4">
        <div className="Panel__Heading">Claimant Details</div>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-col-6 p-mt-2">
            <label className="p-d-block">Claimant Name</label>
            <InputText
              id="claimantName"
              name="claimantName"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("claimantName"),
                },
                "p-d-block Width__100"
              )}
              value={values.claimantName}
              onChange={onChange}
            />
            {getFormErrorMessage("claimantName")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Claimant Address</label>
            <InputTextarea
              rows={5}
              id="claimantAddress"
              name="claimantAddress"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("claimantAddress"),
                },
                "p-d-block Width__100"
              )}
              value={values.claimantAddress}
              onChange={onChange}
            />
            {getFormErrorMessage("claimantAddress")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              Litigation Friend name, address and relationship to Claimant (if
              Claimant is a minor):
            </label>
            <InputText
              id="litigationFriend"
              name="litigationFriend"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("litigationFriend"),
                },
                "p-d-block Width__100"
              )}
              value={values.litigationFriend}
              onChange={onChange}
            />
            {getFormErrorMessage("litigationFriend")}
          </div>

          <div className="p-col-6 p-mt-2">
            <label className="p-d-block">Contact Number</label>
            <InputText
              id="claimantContactno"
              name="claimantContactno"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("claimantContactno"),
                },
                "p-d-block Width__100"
              )}
              value={values.claimantContactno}
              onChange={onChange}
            />
            {getFormErrorMessage("claimantContactno")}
          </div>
          <div className="p-col-6 p-mt-2">
            <label className="p-d-block">Contact Email</label>
            <InputText
              type="email"
              id="claimantEmail"
              name="claimantEmail"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("claimantEmail"),
                },
                "p-d-block Width__100"
              )}
              value={values.claimantEmail}
              onChange={onChange}
            />
            {getFormErrorMessage("claimantEmail")}
          </div>
          <div className="p-col-6 p-mt-2">
            <label className="p-d-block">Claimant Date of Birth</label>
            <InputText
              type="date"
              id="claimantDob"
              name="claimantDob"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("claimantDob"),
                },
                "p-d-block Width__100"
              )}
              value={values.claimantDob}
              onChange={onChange}
            />
            {getFormErrorMessage("claimantDob")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClaimantDetails;
