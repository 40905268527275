import {
  GETPLSUCCESS,
  GETPLERROR,
  GETPLSTATUSSUCCESS,
  GETPLSTATUSERROR,
  GETPLLISTSUCCESS,
  GETPLLISTERROR,
} from "../slices/plSlice";
import { SETLOADING } from "../slices/loadingSlice";
import { handleGetRequest } from "../../services/GetTemplate";

export const getList = (statusUrl) => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handleGetRequest(statusUrl);
  if (res) {
    dispatch(GETPLLISTSUCCESS(res?.data));
    dispatch(SETLOADING(false));
    return res;
  } else {
    dispatch(GETPLLISTERROR(res?.data?.data));
    dispatch(SETLOADING(false));
  }
};

export const getPlDetails = (id) => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handleGetRequest(`Pl/getplCaseById/${id}`);
  if (res) {
    dispatch(GETPLSUCCESS(res?.data));
    dispatch(SETLOADING(false));
  } else {
    dispatch(GETPLERROR(res?.data));
    dispatch(SETLOADING(false));
  }
};
export const getStatus = () => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handleGetRequest("/Pl/getAllplStatusCounts");
  if (res) {
    dispatch(GETPLSTATUSSUCCESS(res?.data));
    dispatch(SETLOADING(false));
    return res;
  } else {
    dispatch(GETPLSTATUSERROR(res));
    dispatch(SETLOADING(false));
  }
};
