import React from "react";
import styled from "styled-components";
import { FaCheckCircle } from "react-icons/fa";

// Styled container component
const SignedMessageContainer = styled.section`
  margin-top: 1rem;
  border: 2px dashed #42b983; /* Green border for positive message */
  padding: 15px;
  font-size: 18px;
  display: flex;
  align-items: center; /* Align children vertically */

  p {
    margin: 0;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Icon = styled(FaCheckCircle)`
  color: #42b983; /* Green color for the checkmark icon */
  margin-right: 10px;
`;

function SignedDocumentMessage() {
  return (
    <SignedMessageContainer>
      <Icon size={20} />
      <p>This document has already been signed.</p>
    </SignedMessageContainer>
  );
}

export default SignedDocumentMessage;
