import {
  GETOLSUCCESS,
  GETOLERROR,
  GETOLSTATUSSUCCESS,
  GETOLSTATUSERROR,
  GETOLLISTSUCCESS,
  GETOLLISTERROR,
} from "../slices/olSlice";
import { SETLOADING } from "../slices/loadingSlice";
import { handleGetRequest } from "../../services/GetTemplate";

export const getList = (statusUrl) => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handleGetRequest(statusUrl);
  if (res) {
    dispatch(GETOLLISTSUCCESS(res?.data));
    dispatch(SETLOADING(false));
    return res;
  } else {
    dispatch(GETOLLISTERROR(res?.data?.data));
    dispatch(SETLOADING(false));
  }
};

export const getOlDetails = (id) => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handleGetRequest(`Ol/getOlCaseById/${id}`);
  if (res) {
    dispatch(GETOLSUCCESS(res?.data));
    dispatch(SETLOADING(false));
  } else {
    dispatch(GETOLERROR(res?.data));
    dispatch(SETLOADING(false));
  }
};
export const getStatus = () => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handleGetRequest("/Ol/getAllOlStatusCounts");
  if (res) {
    dispatch(GETOLSTATUSSUCCESS(res?.data));
    dispatch(SETLOADING(false));
    return res;
  } else {
    dispatch(GETOLSTATUSERROR(res));
    dispatch(SETLOADING(false));
  }
};
