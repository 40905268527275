import React from "react";
import { RiFileDownloadLine } from "react-icons/ri";
import styled from "styled-components";

const StyledPdfDownload = styled.div`
  margin-top: 1rem;
  text-align: center;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }

    svg {
      margin-right: 0.5rem;
    }
  }
`;

function PdfDownload({ doc }) {
  const downloadPdf = () => {
    const link = document.createElement("a");
    link.href = `data:application/pdf;base64,${doc}`;
    link.download = "cfa.pdf";
    link.click();
  };

  return (
    <StyledPdfDownload>
      <button onClick={downloadPdf}>
        <RiFileDownloadLine />
        Download PDF
      </button>
    </StyledPdfDownload>
  );
}

export default PdfDownload;
