import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

export function Dropzone({ handleDropZoneFiles, acceptPdfOnly = false }) {
  const acceptedTypes = acceptPdfOnly
    ? { "application/pdf": [".pdf"] }
    : undefined;

  const onDrop = useCallback((acceptedFiles) => {
    handleDropZoneFiles(acceptedFiles);
    // Do something with the files
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedTypes,
  });

  return (
    <StyledDropzone {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}
    </StyledDropzone>
  );
}

const StyledDropzone = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 40px;
  text-align: center;
  margin: 20px;
  cursor: pointer;
`;

export default Dropzone;
