import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import SideBox, {
  SideBoxWrapper,
} from "../../components/FormLayout/SideBoxSection";
import { GiEnvelope } from "react-icons/gi";
import { AiFillPrinter, AiOutlineRedo } from "react-icons/ai";
import ReactToPrint from "react-to-print";
import { handleGetRequest } from "../../services/GetTemplate";
import { useParams } from "react-router-dom";
import Logo from "../../assets/images/TCGlogo.png";
import { handlePostRequest } from "../../services/PostTemplate";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { SplitButton } from "primereact/splitbutton";

function ManualInvoiceDetails() {
  let componentRef = useRef();
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [statusOpitions, setstatusOpitions] = useState("");
  const { id } = useParams();
  const invoiceId = id;

  const handleStausChange = async (statuscode) => {
    const data = {
      hdrClaimCode: invoiceDetails?.invoiceheadid,
      toStatus: statuscode,
      reason: null,
    };
    await handlePostRequest(data, "invoicing/performActionOnInvoicing");
    getInvoiceDetails();
  };

  const getInvoiceDetails = async () => {
    const res = await handleGetRequest(
      `invoicing/getManualInvoice/${invoiceId}`
    );
    setInvoiceDetails(res?.data);
    const statusOptions = res?.data?.tblRtastatuses?.map((item) => {
      return {
        label: item.descr,
        command: (e) => {
          handleStausChange(item.statuscode);
        },
      };
    });
    setstatusOpitions(statusOptions);
  };

  const amountTemplate = ({ amount }) => {
    return "£" + amount;
  };

  const handleHtmlToPdf = () => {
    const input = document.getElementById("printable");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      sendInvoiceEmail(pdf.output("blob"));
    });
  };

  const sendInvoiceEmail = async (invoice) => {
    const formData = new FormData();
    formData.append("invoice", invoice);
    formData.append("invoiceId", invoiceId);
    await handlePostRequest(formData, `invoicing/sendInvoiceEmail`, {
      "Content-Type": "multipart/form-data",
    });
    getInvoiceDetails();
  };

  const dateTimeToDate = (dateTime) => new Date(dateTime).toLocaleDateString();

  const dueDate = (date) => {
    //add two days to date
    const date1 = new Date(date);
    const dateNp = date1.setDate(date1.getDate() + 2);
    //convert milliseconds to yyyy/mm/dd
    return new Date(dateNp).toLocaleDateString();
  };

  useEffect(() => {
    getInvoiceDetails();
  }, []);

  return (
    <div ref={(el) => (componentRef = el)}>
      {" "}
      <SideBoxWrapper className="w-100">
        <SideBox title="Invoice">
          <div id="printable" className="p-4">
            <div className="d-flex justify-content-between">
              <div className="col-md-6">
                <img src={Logo} alt="The claims group" width={60} />
              </div>
              <div className="col-md-3 offset-md-3">
                <div className="row">
                  <div className="col-md-6">Invoice#</div>
                  <div className="col-md-6">
                    <strong>{invoiceDetails?.manualinvoicingid}</strong>
                  </div>
                  <div className="col-md-6">Date of Invoice</div>
                  <div className="col-md-6">
                    <strong>
                      {invoiceDetails.createdate
                        ? dateTimeToDate(invoiceDetails?.createdate)
                        : "-"}
                    </strong>
                  </div>
                  <div className="col-md-6">Due Date</div>
                  <div className="col-md-6">
                    <strong>{dueDate(invoiceDetails?.duedate)}</strong>
                  </div>
                </div>
              </div>
            </div>
            <hr className="bold-line"></hr>
            <div className="row">
              <div className="col-md-6">
                <h6 className="text-semibold">Invoice To : </h6>
                <ul className="pl-1">
                  <li>{invoiceDetails?.customername}</li>
                  <li>{invoiceDetails?.customeraddress}</li>
                </ul>
              </div>
              <div className="col-md-6">
                <h6 className="text-semibold">Invoice From : </h6>
                <ul className="pl-1">
                  <li>The Claim Group</li>
                  <li>lONDON</li>
                </ul>
              </div>
            </div>
            <DataTable
              className="p-0"
              value={invoiceDetails?.tblManualinvoicingdetails}
              showGridlines
              responsiveLayout="scroll"
            >
              <Column
                field="description"
                sortable
                header="Description"
              ></Column>
              <Column body={amountTemplate} sortable header="Amount"></Column>
            </DataTable>
            <div className="row mt-4">
              <div className="col-md-4 offset-md-8">
                <h6 className="text-semibold">
                  Total :{" £" + invoiceDetails?.grandtotal}{" "}
                </h6>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-2 " id="hide">
            <button
              className="btn btn-primary hide-on-print"
              onClick={handleHtmlToPdf}
            >
              <GiEnvelope /> Email
            </button>
            &nbsp;
            <ReactToPrint
              trigger={() => (
                <button className="btn  btn-primary hide-on-print">
                  <AiFillPrinter /> Print
                </button>
              )}
              content={() => componentRef}
            />
            &nbsp;
            <button className="btn  btn-warning hide-on-print">
              <AiOutlineRedo /> Revert
            </button>
            &nbsp;
            <SplitButton
              label="Savedsd"
              icon="pi pi-plus"
              model={statusOpitions}
            ></SplitButton>
          </div>
        </SideBox>
      </SideBoxWrapper>
    </div>
  );
}

export default ManualInvoiceDetails;
