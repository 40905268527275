import * as Yup from "yup";

const initialValues = {
  isEmployementHistory: "N",
  accbookcopy: "",
  acccircumstances: "",
  accdatetime: "",
  acclocation: "",
  additionalclaiminfo: "",
  address: "",
  agencyname: "",
  ambulancecalled: "",
  anyinvestigation: "",
  anyotherlosses: "",
  anypremedicalconds: "",
  anypreviousacc: "",
  anyriskassessments: "",
  anytreatmentreceived: "",
  anywitnesses: "",
  awaresimilaraccident: "",
  changestosystem: "",
  claimantsufferinjuries: "",
  clientattendedhospital: "",
  clientinjuries: "",
  clientname: "",
  clientpresentcond: "",
  clientseenaccbook: "",
  clientseengp: "",
  clientstillwork: "",
  companysickpay: "",
  contactno: "",
  dailyactivities: "",
  dategpattendance: "",
  datehospitalattendance: "",
  dob: "",
  employeraddress: "",
  employercontactno: "",
  employername: "",
  employmentduration: "",
  estimatednetweeklywage: "",
  gpaddress: "",
  gpadvisegiven: "",
  gpname: "",
  hospitaladdress: "",
  hospitaladvisegiven: "",
  hospitalname: "",
  injuriessymptomsstart: "",
  jobtitle: "",
  lossofearnings: "",
  ninumber: "",
  noworkadvised: "",
  otherlossesdetail: "",
  periodofabsence: "",
  premedicalconddetail: "",
  previousaccdetail: "",
  protectiveequipment: "",
  remarks: "",
  reportedbywhom: "",
  trainingreceived: "",
  userofprocequip: "",
  witnessdetails: "",
  acctime: "",
  mobile: "",
  middlename: "",
  firstname: "",
  lastname: "",
  title: "",
  email: "",
  city: "",
  region: "",
  postalcode: "",
  address1: "",
  address2: "",
  address3: "",
  clawbackDate: "",
};

const validationSchema = Yup.object().shape({
  title: Yup.string().required("required."),
  firstname: Yup.string().required("required."),
  lastname: Yup.string().required("required."),
  dob: Yup.string().required("required."),
  ninumber: Yup.string()
    .required("required.")
    .test("ninumber", "Invalid NIN", (value) => {
      if (
        value === "Will be provided to the solicitor" ||
        value === "Minor" ||
        value === "Solicitor obtained Number"
      ) {
        return true;
      }
      if (value) {
        let ninumberFirstChar = value[0];
        let ninumberSecondChar = value[1];
        let prefix = ninumberFirstChar + ninumberSecondChar;
        let prefixCheckList = ["BG", "GB", "KN", "NK", "NT", "TN", "ZZ"];
        let checklist = "D,F,I,Q,U,V";
        if (
          checklist.includes(ninumberSecondChar) ||
          checklist.includes(ninumberFirstChar) ||
          value[1] === "O" ||
          prefixCheckList.includes(prefix)
        ) {
          return false;
        } else {
          const re = new RegExp(
            /^\s*[a-zA-Z]{2}(?:\s*\d\s*){6}[a-zA-Z]{1,}?\s*$/
          );
          return re.test(value);
        }
      }
      return true;
    }),
  mobile: Yup.string()
    .required("required.")
    .nullable()
    .max(11, "Max 11 digits")
    .min(11, "Min 11 digits")
    .matches(/^[0-9]+$/, "Must be only digits"),
  city: Yup.string().required("required."),
  postalcode: Yup.string().required("required."),
  address1: Yup.string().required("required."),
  accdatetime: Yup.string().required("required."),
  acctime: Yup.string().required("required."),
  acclocation: Yup.string().required("required."),
  acccircumstances: Yup.string().required("required."),
  clientinjuries: Yup.string().required("required."),
  anytreatmentreceived: Yup.string().required("required."),
  injuriessymptomsstart: Yup.string().when("claimantsufferinjuries", {
    is: false,
    then: Yup.string().required("required."),
  }),
  clientpresentcond: Yup.string().required("required."),
  reportedbywhom: Yup.string().required("required."),
});

export default validationSchema;

export { initialValues, validationSchema };
