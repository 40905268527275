import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import AffectedModal from "../index";
import { formatDateToddmmyyy } from "../../../../utilities/commonFunctions";

function AffectedPersonTable({
  affectedPerson,
  handleRemoveAffected,
  handleUpdateAffected,
  isView,
  viewmode,
  disabledForm,
}) {
  let states = [
    {
      code: "Mr",
      name: "Mr",
      type: null,
    },
    {
      code: "Mrs",
      name: "Mrs",
      type: null,
    },
    {
      code: "Miss",
      name: "Miss",
      type: null,
    },
    {
      code: "Ms",
      name: "Ms",
      type: null,
    },
    {
      code: "Mstr",
      name: "Mstr",
      type: null,
    },
    {
      code: "Dr",
      name: "Dr",
      type: null,
    },
    {
      code: "Prof",
      name: "Prof",
      type: null,
    },
    {
      code: "Rev",
      name: "Rev",
      type: null,
    },
  ];
  const [affected, setAffected] = useState();
  const [displayBasic, setDisplayBasic] = useState(false);

  const actionTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          onClick={() => handleEditAffected(rowData)}
          className="p-button-rounded p-button-text p-mr-2"
        />
        <Button
          icon="pi pi-trash"
          onClick={() => handleRemoveAffected(rowData?.id)}
          className="p-button-rounded p-button-text"
        />
      </React.Fragment>
    );
  };
  const actionTemplateView = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          onClick={() => handleEditAffected(rowData)}
          className="p-button-rounded p-button-success p-mr-2"
        />
      </React.Fragment>
    );
  };
  const handleEditAffected = (affected) => {
    setAffected(affected);
    setDisplayBasic(true);
  };

  const formatDateOfBirth = (rowData) => {
    return rowData?.persondob
      ? formatDateToddmmyyy(new Date(rowData.persondob))
      : rowData?.persondob;
  };

  return (
    <div className="card p-mb-4 p-mt-4">
      {affectedPerson?.length > 0 && (
        <DataTable value={affectedPerson}>
          <Column
            field="personname"
            header="Name"
            sortable
            filterMatchMode="contains"
          ></Column>
          <Column
            field="persondob"
            header="Date of Birth"
            sortable
            filterMatchMode="contains"
            body={formatDateOfBirth}
          ></Column>
          <Column
            field="sufferingFrom"
            header="Suffering From"
            sortable
            filterMatchMode="contains"
          ></Column>
          <Column
            field="medicalEvidence"
            header="Has Medical Evidence"
            body={(e) => (
              <div>
                <span>{e.medicalEvidence === "Y" ? "Yes" : "No"}</span>
              </div>
            )}
            sortable
            filterMatchMode="contains"
          ></Column>
          <Column
            field="evidenceDetail"
            header="Medical Evidence Details"
            sortable
            filterMatchMode="contains"
          ></Column>
          {isView === true ? (
            <Column
              body={actionTemplateView}
              header="Action"
              filterMatchMode="contains"
            ></Column>
          ) : (
            <Column
              body={actionTemplate}
              header="Action"
              filterMatchMode="contains"
            ></Column>
          )}
        </DataTable>
      )}
      <AffectedModal
        disabledForm={disabledForm}
        status={states}
        viewmode={isView || viewmode}
        show={displayBasic}
        hide={setDisplayBasic}
        affected={affected}
        handleAffectedReturn={handleUpdateAffected}
        isEdit={true}
      />
    </div>
  );
}

export default AffectedPersonTable;
