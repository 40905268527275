import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
`;

const EmailInput = styled.input`
  border: none;
  flex: 1;
  margin-right: 8px;

  &:focus {
    outline: none;
  }
`;

const EmailTag = styled.div`
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  color: #666;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 4px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`;

const EmailList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const EmailInputField = ({ onEmailChange }) => {
  const [emails, setEmails] = useState([]);
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        setEmails([...emails, value]);
        setValue("");
        onEmailChange([...emails, value]);
      }
    }
  };

  const handleDelete = (emailToDelete) => {
    setEmails(emails.filter((email) => email !== emailToDelete));
  };

  return (
    <Container>
      <EmailList>
        {emails.map((email) => (
          <EmailTag key={email} onClick={() => handleDelete(email)}>
            {email} &times;
          </EmailTag>
        ))}
      </EmailList>
      <EmailInput
        type="email"
        placeholder="Type email addresses and press enter"
        value={value}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
    </Container>
  );
};

export default EmailInputField;
