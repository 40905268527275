/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { ImUpload2 } from "react-icons/im";
import Accordion from "../../components/Accordion";
import { FormWrapper } from "../../components/FormLayout/FormHeader";
import {
  LargeTab,
  LargeTabWrapper,
} from "../../components/FormLayout/FormHeader/LargeTab";
import FormMainBody from "../../components/FormLayout/FormMainBody/index";
import UploadModal from "../../components/UploadModal";
import Messages from "../../components/Messages";
import Notes from "../../components/Notes";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../Config";
import Multimedia from "../../components/Multimedia";
import Logs from "../../components/Logs";
import Immigration from "./immigration";
import { getImmigrationDetails } from "../../redux/actions/immigrationAction";

function ViewImmigrationForm() {
  const [active, setActive] = useState(0);
  const [showUploadModal, setshowUploadModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitFormik, setisSubmitFormik] = useState(false);
  const imCode = searchParams.get("imcode");
  const dispatch = useDispatch();
  const { immigration } = useSelector((state) => state.immigrationSlice);

  const uploadAction = (
    <div
      className="text-semibold pointer"
      onClick={() => setshowUploadModal(true)}
    >
      <ImUpload2 /> &nbsp; Upload
    </div>
  );

  const getDetails = async () => {
    setIsLoading(true);
    await dispatch(getImmigrationDetails(imCode));
    setIsLoading(false);
  };

  const onSave = async () => {
    setisSubmitFormik(true);
  };

  const handleReturnEdit = async () => {
    setisSubmitFormik(false);
  };

  React.useEffect(() => {
    getDetails();
  }, []);

  const handlePrintForm = () => {
    const url = "report/immigrationClaimReport/" + imCode;
    window.open(`${baseURL + url}`, "_blank");
  };

  return (
    <FormWrapper>
      <LargeTabWrapper>
        <LargeTab
          Icon="Icon"
          onClick={() => setActive(0)}
          active={active === 0}
          title="Main"
        />
        <LargeTab
          Icon="Icon"
          onClick={() => setActive(2)}
          active={active === 2}
          title="Notes"
        />
        <LargeTab
          Icon="Icon"
          onClick={() => setActive(3)}
          active={active === 3}
          title="Messages"
        />
        <LargeTab
          Icon="Icon"
          onClick={() => setActive(4)}
          active={active === 4}
          title="Logs"
        />
      </LargeTabWrapper>
      {active === 0 && (
        <FormMainBody
          isEdit={!disabled}
          setIsEdit={(edit) => setDisabled(!edit)}
          disabledForm={String(disabled)}
          title="Case Info"
          onSave={onSave}
        >
          <Immigration
            edit={true}
            onSave={onSave}
            isSubmitFormik={isSubmitFormik}
            handleReturnEdit={handleReturnEdit}
          />
          <center className="mt-4">
            <button
              onClick={handlePrintForm}
              id="print-btn"
              className="btn btn-block btn-primary "
            >
              Print Claimant Form
            </button>
          </center>
        </FormMainBody>
      )}
      {active === 1 && (
        <>
          <Accordion title="Images" headerRightArea={uploadAction} open>
            <Multimedia />
          </Accordion>
        </>
      )}
      <UploadModal
        show={showUploadModal}
        hide={(show) => setshowUploadModal(show)}
      />
      {active === 2 && (
        <Accordion title="Notes" open>
          <Notes
            getNotesService={"immigration/getImmigrationCaseNotes"}
            addNotesService={"immigration/addImmigrationNotes"}
            caseType={"im"}
            code={imCode}
          />
        </Accordion>
      )}
      {active === 3 && (
        <Accordion title="Messages" open>
          <Messages
            code={imCode}
            url={"immigration/getImmigrationCaseMessages/"}
          />
        </Accordion>
      )}
      {active === 4 && (
        <Accordion title="Logs" open>
          <Logs logs={immigration?.tblImmigrationlogs} />
        </Accordion>
      )}
    </FormWrapper>
  );
}

export default ViewImmigrationForm;
