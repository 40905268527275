import { handleGetRequest } from "../../services/GetTemplate";
import {
    GETMEDICALNEGLIGENCESUCCESS,
    GETMEDICALNEGLIGENCEERROR,
    GETMEDICALNEGLIGENCESTATUSSUCCESS,
    GETMEDICALNEGLIGENCESTATUSERROR,
    GETMEDICALNEGLIGENCELISTSUCCESS,
    GETMEDICALNEGLIGENCELISTERROR,
} from "../slices/medicalNegligenceSlice";
import { SETLOADING } from "../slices/loadingSlice";

export const getList = (statusUrl) => async (dispatch) => {
    dispatch(SETLOADING(true));
    const res = await handleGetRequest(statusUrl);
    if (res) {
        dispatch(GETMEDICALNEGLIGENCELISTSUCCESS(res?.data));
        dispatch(SETLOADING(false));
        return res;
    } else {
        dispatch(GETMEDICALNEGLIGENCELISTERROR(res?.data?.data));
        dispatch(SETLOADING(false));
    }
};

export const getMedicalNegligenceDetails = (id) => async (dispatch) => {
    dispatch(SETLOADING(true));
    const res = await handleGetRequest(`/medneg/getMNCaseById/${id}`);
    if (res) {
        dispatch(GETMEDICALNEGLIGENCESUCCESS(res?.data));
        dispatch(SETLOADING(false));
    } else {
        dispatch(GETMEDICALNEGLIGENCEERROR(res?.data));
        dispatch(SETLOADING(false));
    }
};

export const getStatus = () => async (dispatch) => {
    dispatch(SETLOADING(true));
    const res = await handleGetRequest("medneg/getAllMnStatusCounts");
    if (res) {
        dispatch(GETMEDICALNEGLIGENCESTATUSSUCCESS(res?.data));
        dispatch(SETLOADING(false));
        return res;
    } else {
        dispatch(GETMEDICALNEGLIGENCESTATUSERROR(res));
        dispatch(SETLOADING(false));
    }
};
