import React, { useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Chip } from "primereact/chip";
import EditUserButton from "./EditUser/EditUserButton";

function CompanyUserDetails({ userList, getuserList }) {
  const dt = useRef(null);

  const actionsTemplate = (data) => {
    return (
      <div className="d-flex justify-content-flex-start">
        <EditUserButton data={data} getuserList={getuserList} />
      </div>
    );
  };

  const statusTemplate = (rowData) => {
    return (
      <Chip
        label={rowData?.status === "Y" ? "Active" : "Inactive"}
        className="custom-chip p-px-4"
      />
    );
  };

  const rolesTemplate = (rowData) => {
    return (
      <>
        {rowData?.lovResponse?.map((role) => {
          return <Chip label={role.name} className="p-mr-1 p-mt-1" />;
        })}
      </>
    );
  };

  return (
    <div className="card mt-2">
      <DataTable
        ref={dt}
        value={userList || []}
        loading={userList === null}
        paginator
        rows={10}
        emptyMessage="No Users found in this company. Please add a new User."
        header={"Company Users"}
      >
        <Column
          field="loginid"
          sortable
          header="Login ID"
          filter
          filterMatchMode="contains"
        />
        <Column
          field="username"
          sortable
          header="Email"
          filter
          filterMatchMode="contains"
        />
        <Column field="lovResponse" header="Roles" body={rolesTemplate} />
        <Column
          field="companystatus"
          sortable
          body={statusTemplate}
          header="Status"
          filter
          filterMatchMode="contains"
        />
        <Column header="Action" body={actionsTemplate} />
      </DataTable>
    </div>
  );
}

export default CompanyUserDetails;
