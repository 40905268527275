import { Dialog } from "primereact/dialog";
import React from "react";
import EmailDetail from "./EmailDetail";

function EmailDetailDialog({ showModal, onCloseModal, data }) {
  return (
    <Dialog
      header="Email Status"
      position="top"
      draggable={false}
      visible={showModal}
      style={{ width: "80vw" }}
      onHide={onCloseModal}
    >
      <div className="mt-4">
        <EmailDetail data={data} />
      </div>
    </Dialog>
  );
}

export default EmailDetailDialog;
