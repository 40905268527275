import { Button } from "primereact/button";
import React, { useState } from "react";
import HireAssignDialog from "./HireAssignDialog";

function HireAssignButton({ buttonDetails, hireclaimcode }) {
  const [showModal, setShowModal] = useState(false);

  const onCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        label={buttonDetails.buttonname}
        className="btn-info btn-sm p-mr-2 p-mb-2"
      />
      <HireAssignDialog
        showModal={showModal}
        onCloseModal={onCloseModal}
        buttonDetails={buttonDetails}
        hireclaimcode={hireclaimcode}
      />
    </>
  );
}

export default HireAssignButton;
