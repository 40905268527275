import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { Dialog } from "primereact/dialog";
import { hdrAffectedRoomList } from "../../../utilities/constants";

function AffectedRoomModel({
  show,
  hide,
  handleAffectedReturn,
  affected,
  isEdit,
  viewmode,
  disabledForm,
}) {
  if (viewmode) {
    isEdit = "View";
  }
  const footer = (
    <div>
      {!viewmode ? (
        <>
          <button className="btn btn-primary" onClick={() => handleAffected()}>
            {!isEdit ? "Add" : "Update"}
          </button>
          {!isEdit && (
            <button
              className="btn btn-warning"
              onClick={() => setEffectDetails(hdrAffectedRoomList)}
            >
              Reset
            </button>
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );

  const [effectDetails, setEffectDetails] = useState(hdrAffectedRoomList);
  const [propertyDamage, setPropertyDamage] = useState("");

  const handleAffected = async () => {
    if (effectDetails?.roomName) {
      handleAffectedReturn(effectDetails);
      hide(false);
      !isEdit && handleClear();
    }
  };

  const handleClear = () => {
    setEffectDetails(hdrAffectedRoomList);
  };

  useEffect(() => {
    if (affected && Object.keys(affected).length !== 0)
      setEffectDetails(affected);
  }, [affected, show]);

  return (
    <Dialog
      header={
        isEdit && isEdit !== "View"
          ? "Edit Affected"
          : isEdit === "View"
          ? isEdit + " Affected Details"
          : "Affected Room"
      }
      footer={footer}
      visible={show}
      style={{ width: "50%" }}
      onHide={() => hide(false)}
    >
      <div className="p-mt-4 p-ml-4" disabledForm={disabledForm}>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-col-12">
            <div className="p-fluid p-formgrid p-grid p-pr-4">
              <div className="p-col-8">
                <div className="p-col-12">
                  <label className="p-d-block">Room</label>
                </div>
                <InputText
                  value={effectDetails?.roomName || ""}
                  onChange={(e) => {
                    setEffectDetails({
                      ...effectDetails,
                      roomName: e.target.value,
                    });
                  }}
                  placeholder="Room Name"
                />
              </div>
              <div className="p-col-4">
                <div className="p-col-12">
                  <label className="p-d-block">Last Reported On</label>
                </div>
                <InputText
                  value={effectDetails?.lastReported || ""}
                  onChange={(e) => {
                    setEffectDetails({
                      ...effectDetails,
                      lastReported: e.target.value,
                    });
                  }}
                  placeholder="Last Reported"
                  type="date"
                />
              </div>

              <div className="p-col-12 p-mt-6">
                <label className="p-d-block">Report Details</label>
              </div>
              <div className="p-col-12">
                <InputTextarea
                  value={effectDetails?.reportDetails || ""}
                  onChange={(e) => {
                    setEffectDetails({
                      ...effectDetails,
                      reportDetails: e.target.value,
                    });
                  }}
                  rows={5}
                />
              </div>

              <div className="p-col-12 p-mt-6">
                <label className="p-d-block">Disrepair Details</label>
              </div>
              <div className="p-col-12">
                <InputTextarea
                  value={effectDetails?.disrepairDetail || ""}
                  onChange={(e) => {
                    setEffectDetails({
                      ...effectDetails,
                      disrepairDetail: e.target.value,
                    });
                  }}
                  rows={5}
                />
              </div>

              <div className="p-col-12 p-mt-6">
                <label className="p-d-block">
                  Is Personal Property Damage?
                </label>
              </div>
              <div className="p-col-12">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="propertydamageyes"
                    name="propertyDamage"
                    value="yes"
                    onChange={(e) => {
                      setEffectDetails({
                        ...effectDetails,
                        personalPropertydamage: e.target.value,
                      });
                      setPropertyDamage(e.value);
                    }}
                    checked={propertyDamage === "yes"}
                  />
                  <label htmlFor="propertydamageyes" className="p-ml-1">
                    Yes
                  </label>
                </div>
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="propertydamageno"
                    name="propertyDamage"
                    value="no"
                    onChange={(e) => {
                      setEffectDetails({
                        ...effectDetails,
                        personalPropertydamage: e.target.value,
                      });
                      setPropertyDamage(e.value);
                    }}
                    checked={propertyDamage === "no"}
                  />
                  <label htmlFor="propertydamageno" className="p-ml-1">
                    No
                  </label>
                </div>
              </div>
              <div className="p-col-12 p-mt-2">
                <InputTextarea
                  value={effectDetails?.damageList || ""}
                  onChange={(e) => {
                    setEffectDetails({
                      ...effectDetails,
                      damageList: e.target.value,
                    });
                  }}
                  placeholder="Personal Property Damage detail:"
                  rows={5}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default AffectedRoomModel;
