import React, { useEffect } from "react";
import { useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";

const EffectsInfo = ({ errors, handleEffectsInfoDetails }) => {
  const [afftectedHealth, setAfftectedHealth] = useState({
    healthAffected: "N",
    healthRelDetails: "",
  });

  useEffect(() => {
    handleEffectsInfoDetails(afftectedHealth);
  }, [afftectedHealth, handleEffectsInfoDetails]);

  return (
    <div className="p-mt-4 p-ml-4">
      <div className="p-fluid p-formgrid p-grid">
        <div className="p-col-6">
          <div className="p-fluid p-formgrid p-grid p-pr-4">
            <div className="Panel__Heading">Effects Info</div>

            <div className="p-col-12 p-mt-6">
              <label className="p-d-block">
                Has anybodys health been affected? *
              </label>
            </div>
            <div className="p-col-12">
              <div className="field-radiobutton">
                <RadioButton
                  inputId="afftectedhealthyes"
                  name="afftectedHealth"
                  value="Y"
                  onChange={(e) => {
                    setAfftectedHealth({
                      ...afftectedHealth,
                      healthAffected: e.value,
                    });
                  }}
                  checked={afftectedHealth.healthAffected === "Y"}
                  className={
                    errors?.healthAffected && "p-inputtext-error p-d-block"
                  }
                />
                <label htmlFor="afftectedhealthyes" className="p-ml-1">
                  Yes
                </label>
              </div>
              <div className="field-radiobutton">
                <RadioButton
                  inputId="afftectedhealthno"
                  name="afftectedHealth"
                  value="N"
                  onChange={(e) => {
                    setAfftectedHealth({
                      ...afftectedHealth,
                      healthAffected: e.value,
                    });
                  }}
                  checked={afftectedHealth.healthAffected === "N"}
                  className={
                    errors?.healthAffected && "p-inputtext-error p-d-block"
                  }
                />

                <label htmlFor="afftectedhealthno" className="p-ml-1">
                  No
                </label>
                <small
                  className={errors?.healthAffected && "label__error p-d-block"}
                >
                  {errors?.healthAffected}
                </small>
              </div>
            </div>
            <div className="p-col-12 p-mt-2">
              <InputTextarea
                onChange={(e) => {
                  setAfftectedHealth({
                    ...afftectedHealth,
                    healthRelDetails: e.target.value,
                  });
                }}
                value={afftectedHealth.healthRelDetails}
                disabled={afftectedHealth.healthAffected === "N"}
                placeholder="Enter daily routine effects related info here"
                rows={5}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EffectsInfo;
