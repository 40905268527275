import React, { useRef } from "react";
import { useSelector } from "react-redux";
import "./Notes.css";

function NotesLegalInternal({ internalMessages }) {
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const userCatInternal = user?.tblUsercategory?.categorycode;
  const messages = internalMessages;

  const messagesEndRef = useRef(null);

  const loggedIn = JSON.parse(localStorage.getItem("loggedIn")).tblUsercategory
    .categorycode;

  const sortmessages = () => {
    return (
      messages.length &&
      [...messages].sort((a, b) => {
        return a.hdrnotecode - b.hdrnotecode;
      })
    );
  };

  if (userCatInternal !== "4") {
    return null;
  }

  if (!messages) {
    return (
      <div className="mb-4">
        <h6>
          <strong>Legal Internal Notes</strong>
        </h6>
        <p>No Notes Found</p>
      </div>
    );
  }

  return (
    <div
      className="mb-4"
      style={{ paddingLeft: "100px", paddingRight: "100px" }}
    >
      <h6>
        <strong>Legal Internal Notes</strong>
      </h6>
      <section className="chatbox">
        <section className="chat-window">
          {messages &&
            messages.length &&
            sortmessages().map((item, index) =>
              item?.self ? (
                <article
                  key={item?.rtanotecode}
                  className={
                    loggedIn === "4"
                      ? "msg-container msg-internal"
                      : "msg-container msg-self"
                  }
                  id="msg-0"
                >
                  <div className="msg-box">
                    <div className="flritem?.rtanotecode === 1 ? (">
                      <div className="messages">
                        <p className="msg" id="msg-0">
                          {item?.note}
                        </p>
                      </div>
                      <span className="timestamp">
                        <span className="username">{item?.userName}</span>&bull;
                        <span className="posttime">{item?.createdon}</span>
                      </span>
                    </div>
                  </div>
                </article>
              ) : (
                <article
                  key={item?.rtanotecode}
                  className="msg-container msg-remote"
                  id="msg-1"
                >
                  <div className="msg-box">
                    <div className="flr">
                      <div className="messages">
                        <p className="msg" id="msg-1">
                          {item?.note}
                        </p>
                      </div>
                      <span className="timestamp">
                        <span className="username">{item?.userName}</span>&bull;
                        <span className="posttime">{item?.createdon}</span>
                      </span>
                    </div>
                  </div>
                </article>
              )
            )}

          <div ref={messagesEndRef} />
        </section>
      </section>
    </div>
  );
}

export default NotesLegalInternal;
