import { handleGetRequest } from "../../services/GetTemplate";
import { SETDROPDOWNERROR, SETDROPDOWNSUCCESS } from "../slices/dropdownSlice";

const setDropdown = (url, optionLabel) => async (dispatch) => {
  try {
    const res = await handleGetRequest(url);
    const options = res.data.map((item) => {
      return {
        name: item[optionLabel],
        value: item.code,
      };
    });
    dispatch(SETDROPDOWNSUCCESS({ url, options }));
  } catch (error) {
    dispatch(SETDROPDOWNERROR({ url, options: [] }));
  }
};

export const checkIsDropdownLoaded =
  (url, optionLabel) => async (dispatch, getState) => {
    const { dropdown } = getState();
    const isDropdownLoaded = dropdown.find((item) => item.url === url);
    if (!isDropdownLoaded) {
      dispatch(setDropdown(url, optionLabel));
    }
  };

export const fetchDropdownOptions = (url, optionLabel) => async (dispatch) => {
  try {
    const res = await handleGetRequest(url);
    const options = res.data.map((item) => ({
      name: item[optionLabel],
      value: item.code,
    }));
    dispatch(SETDROPDOWNSUCCESS({ url, options }));
  } catch (error) {
    dispatch(SETDROPDOWNERROR({ url }));
  }
};
