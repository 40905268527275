import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  SetSingleCompanyDataAction,
  SetSingleJobsCompanyDataAction,
  SetSingleUsersCompanyDataAction,
  SetFreshUsersJobsDataNullLAction,
} from "../../redux/actions/profileAction";
import styled from "styled-components";
import { Chip } from "primereact/chip";

const ProfileComapanyData = () => {
  const [companyData, setcompanyData] = useState(null);
  const dt = useRef(null);

  const dispatch = useDispatch();
  const companyProfileData = useSelector(
    (state) => state.profileSlice.compnayData
  );
  let navigate = useNavigate();
  const editProduct = async (rowData) => {
    dispatch(SetSingleCompanyDataAction(rowData));
    dispatch(SetSingleJobsCompanyDataAction(rowData.tblCompanyjobs));
    dispatch(SetSingleUsersCompanyDataAction(rowData.tblUsers));
    dispatch(SetFreshUsersJobsDataNullLAction());
    navigate("/companydetails");
  };

  useEffect(() => {
    setcompanyData(companyProfileData);
  }, [companyProfileData]);

  const nameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <p className="p-column-title">Name</p>
        {rowData.name}
        <StyledSubInfo>{rowData.categoryname}</StyledSubInfo>
      </React.Fragment>
    );
  };

  const profileBodyTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex">
          <StyledLabel>Added on: </StyledLabel>
          <StyledInfo>{rowData.createdon}</StyledInfo>
        </div>
        <div className="d-flex">
          <StyledLabel>Location: </StyledLabel>
          <StyledInfo>{rowData.addressline1}</StyledInfo>
        </div>
      </>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <>
        <Chip
          label={rowData.companystatus === "Y" ? "Active" : "Inactive"}
          className={`p-px-4  ${
            rowData.companystatus === "Y"
              ? "custom-chip"
              : "custom-chip-warning"
          }`}
        />
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-cog"
          className="p-button-rounded p-button-text p-mr-2"
          onClick={() => editProduct(rowData)}
        />
      </React.Fragment>
    );
  };

  return (
    <div className="card">
      <StyledDataTable
        ref={dt}
        value={companyData}
        loading={companyData === null}
        paginator
        rows={10}
        emptyMessage="No data found"
      >
        <Column
          field="name"
          header="Name"
          body={nameBodyTemplate}
          filter
          sortable
          filterPlaceholder="Search by name"
          filterMatchMode="contains"
        />
        <Column
          field="addressline1"
          header="Profile Info"
          body={profileBodyTemplate}
          filter
          sortable
          filterPlaceholder="Search"
          filterMatchMode="contains"
        />
        <Column
          field="updatedOn"
          header="Last Modified"
          filter
          sortable
          filterPlaceholder="Search by date"
          filterMatchMode="contains"
        />
        <Column
          field="companystatus"
          header="Status"
          body={statusBodyTemplate}
          filter
          sortable
          filterPlaceholder="Search by status"
          filterMatchMode="contains"
        />
        <Column
          header="Acts"
          body={actionBodyTemplate}
          filterMatchMode="contains"
        />
      </StyledDataTable>
    </div>
  );
};

const StyledSubInfo = styled.p`
  font-size: 11px;
  color: #999;
`;

const StyledInfo = styled.p`
  font-size: 11px;
  font-weight: 500;
`;

const StyledLabel = styled.h1`
  font-size: 11px;
  font-weight: 600;
  padding-right: 5px;
`;

const StyledDataTable = styled(DataTable)``;

export default ProfileComapanyData;
