import React from "react";
import styled from "styled-components";
import { RiEdit2Line, RiCloseLine } from "react-icons/ri";

const EditButton = ({ isEdit, setIsEdit, formik }) => {
  const handleSaveChanges = () => {
    formik.handleSubmit();
  };

  const handleCancel = () => {
    // Cancel editing and exit edit mode
    setIsEdit(false);
  };

  const handleClick = () => {
    if (isEdit) {
      handleSaveChanges();
    } else {
      setIsEdit(true);
    }
  };

  return (
    <ButtonContainer>
      {isEdit ? (
        <>
          <SaveChangesButton onClick={handleSaveChanges}>
            <RiEdit2Line /> Save Changes
          </SaveChangesButton>
          <CancelButton onClick={handleCancel}>
            <RiCloseLine /> Cancel
          </CancelButton>
        </>
      ) : (
        <EditButtonStyled onClick={handleClick}>
          <RiEdit2Line /> Edit
        </EditButtonStyled>
      )}
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  display: flex;
`;

const EditButtonStyled = styled.button`
  display: flex;
  align-items: center;
  background-color: #3498db;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }

  svg {
    margin-right: 8px;
  }
`;

const CancelButton = styled.button`
  display: flex;
  align-items: center;
  background-color: #e74c3c;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 8px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c0392b;
  }

  svg {
    margin-right: 8px;
  }
`;

const SaveChangesButton = styled.button`
  display: flex;
  align-items: center;
  background-color: #27ae60;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #219653;
  }

  svg {
    margin-right: 8px;
  }
`;

export default EditButton;
