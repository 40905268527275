import * as Yup from "yup";

const initialValues = {
  advice: "",
  clientaddress: "",
  clientcontactno: "",
  clientemail: "",
  clientname: "",
  clienttitle: "",
  contacttime: "",
  esign: "",
  esigndate: "",
  immigrationcode: "",
  inquirydatetime: "",
  inquirydescription: "",
  inquirynature: "",
  inquiryreferal: "",
  nationality: "",
  notes: "",
  remarks: "",
};
const validationSchema = Yup.object().shape({
  advice: Yup.string().required("required."),
  clientaddress: Yup.string().required("required."),
  clientcontactno: Yup.string().required("required."),
  clientemail: Yup.string().required("required."),
  clientname: Yup.string().required("required."),
  clienttitle: Yup.string().required("required."),
  contacttime: Yup.string().required("required."),
  esign: Yup.string().required("required."),
  esigndate: Yup.string().required("required."),
  immigrationcode: Yup.string().required("required."),
  inquirydatetime: Yup.string().required("required."),
  inquirydescription: Yup.string().required("required."),
  inquirynature: Yup.string().required("required."),
  inquiryreferal: Yup.string().required("required."),
  nationality: Yup.string().required("required."),
  notes: Yup.string().required("required."),
  remarks: Yup.string().required("required."),
});
export { initialValues, validationSchema };
