import React from "react";
import PropTypes from "prop-types";
import "./Tab.css";

function Tab({ icon, title, active, count, onClick }) {
  return (
    <div
      className={active ? "tab tab-active" : "tab"}
      onClick={() => onClick()}
    >
      {icon}
      {title}
      {count ? <span className="tab-count">{count}</span> : null}
    </div>
  );
}

function TabWrapper(props) {
  return <div className="tabs-list">{props.children}</div>;
}

export { Tab, TabWrapper };

React.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
};
