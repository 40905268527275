import { handleGetRequest } from "../../services/GetTemplate";
import { handlePostRequest } from "../../services/PostTemplate";
import {
  GETHIRESUCCESS,
  GETHIREERROR,
  GETHIRESTATUSSUCCESS,
  GETHIRESTATUSERROR,
  GETHIRELISTSUCCESS,
  GETHIRELISTERROR,
  PERFORMACTIONSUCCESS,
  PERFORMACTIONERROR,
} from "../slices/hireSlice";
import { SETLOADING } from "../slices/loadingSlice";

export const getList = (statusUrl) => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handleGetRequest(statusUrl);
  if (res) {
    dispatch(GETHIRELISTSUCCESS(res?.data));
    dispatch(SETLOADING(false));
    return res;
  } else {
    dispatch(GETHIRELISTERROR(res?.data?.data));
    dispatch(SETLOADING(false));
  }
};

export const getHireDetails = (id) => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handleGetRequest(`hire/getHireCaseById/${id}`);
  if (res) {
    dispatch(GETHIRESUCCESS(res?.data));
    dispatch(SETLOADING(false));
  } else {
    dispatch(GETHIREERROR(res?.data));
    dispatch(SETLOADING(false));
  }
};

export const getStatus = () => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handleGetRequest("hire/getAllHireStatusCounts");
  if (res) {
    dispatch(GETHIRESTATUSSUCCESS(res?.data));
    dispatch(SETLOADING(false));
    return res;
  } else {
    dispatch(GETHIRESTATUSERROR(res));
    dispatch(SETLOADING(false));
  }
};

export const ActionOnHire = (data, url) => async (dispatch) => {
  const res = await handlePostRequest(data, url);
  if (res) {
    dispatch(PERFORMACTIONSUCCESS(res?.data));
    return res;
  } else {
    dispatch(PERFORMACTIONERROR(res));
    return;
  }
};

export const getFilerHireList = (data, url) => async (dispatch) => {
  try {
    dispatch(SETLOADING(true));
    const res = await handlePostRequest(data, url);
    dispatch(GETHIRELISTSUCCESS(res?.data));
    return res;
  } catch (error) {
    dispatch(GETHIRELISTERROR(error?.response?.data?.data));
    dispatch(SETLOADING(false));
  } finally {
    dispatch(SETLOADING(false));
  }
};
