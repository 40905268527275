import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { SideBox } from "../../components/FormLayout/SideBoxSection/index";
import styled from "styled-components";
import { Checkbox } from "primereact/checkbox";
import { handlePostRequest } from "../../services/PostTemplate";
import { AiOutlineCloudUpload, AiOutlineUpload } from "react-icons/ai";
import UploadModal from "../../components/UploadModal";
import { campaignTaskKeys } from "../CampaignGeneric/campaignConfig";
import "./CampaignTasks.css";

function CampaignTasks({
  campaignCode = null,
  getCampaignDetails,
  campaign,
  campaignName = null,
}) {
  const [showUpload, setShowUpload] = React.useState(false);
  const [taskCode, settaskCode] = React.useState("");
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const dispatch = useDispatch();
  const {
    compaignCodeKeyPostApi,
    comapaignCaseTasks,
    compaignTaskCodeName,
    taskUploadKeyName,
    taskUploadAddFileService,
    setCurrentTaskService,
  } = campaignTaskKeys[campaignName];

  const taskStatus = (status) => {
    switch (status) {
      case "N":
        return "New";
      case "P":
        return "Pending";
      case "C":
        return "Completed";
      default:
        return "";
    }
  };

  const handleTaskStatus = async (taskCode, current) => {
    const data = {
      taskCode,
      current: current === true ? "Y" : "N",
      [compaignCodeKeyPostApi]: campaignCode,
    };
    try {
      await handlePostRequest(data, setCurrentTaskService);
      dispatch(getCampaignDetails(campaignCode));
    } catch (err) {
      console.log(err);
    }
  };

  const handleAction = async (isFileupload, taskCode) => {
    if (isFileupload === "Y") {
      settaskCode(taskCode);
      setShowUpload(true);
    } else {
      const formData = new FormData();
      formData.append("taskCode", taskCode);
      formData.append(taskUploadKeyName, campaignCode);
      formData.append("multipartFiles", null);
      try {
        await handlePostRequest(formData, taskUploadAddFileService);
        dispatch(getCampaignDetails(campaignCode));
      } catch (err) {
        console.log(err.response);
      }
    }
  };

  const returnSubmit = () => {
    setShowUpload(false);
    dispatch(getCampaignDetails(campaignCode));
  };

  if (
    !campaign?.[comapaignCaseTasks] ||
    campaign?.[comapaignCaseTasks]?.length === 0 ||
    (user?.tblUsercategory?.categorycode !== "4" &&
      user?.tblUsercategory?.categorycode !== "1") ||
    campaignCode === null ||
    campaignName === null
  ) {
    return null;
  }

  return (
    <>
      <SideBox title="Document Signing Section" className="mt-4">
        <table>
          <tr>
            <th>Description</th>
            <th>Status</th>
            <th>Current</th>
            <th>Action</th>
          </tr>
          {campaign?.[comapaignCaseTasks]?.map((task) => (
            <tr>
              <td>{task.tblTask?.descr}</td>
              <SyledStatus status={task.status}>
                {taskStatus(task.status)}
              </SyledStatus>
              <td>
                <Checkbox
                  value={task.current}
                  disabled={task.status === "C" ? true : false}
                  checked={task.currenttask === "Y" ? true : false}
                  onChange={(e) =>
                    handleTaskStatus(task?.[compaignTaskCodeName], e.checked)
                  }
                />
              </td>
              <td>
                <StyledBtn
                  onClick={() =>
                    handleAction(
                      task.tblTask?.fileupload,
                      task.tblTask.taskcode
                    )
                  }
                >
                  {task.tblTask?.fileupload === "Y" ? (
                    <AiOutlineCloudUpload
                      color="white"
                      size="20px"
                      className="p-ml-2"
                    />
                  ) : (
                    <AiOutlineUpload
                      color="white"
                      size="20px"
                      className="p-ml-2"
                    />
                  )}
                </StyledBtn>
              </td>
            </tr>
          ))}
        </table>
      </SideBox>
      <UploadModal
        taskCode={taskCode}
        show={showUpload}
        hide={setShowUpload}
        codeName={taskUploadKeyName}
        codeValue={campaignCode}
        addFilesService={taskUploadAddFileService}
        returnSubmit={returnSubmit}
      />
    </>
  );
}

const SyledStatus = styled.td`
  color: ${(props) =>
    props.status === "N"
      ? "#FF0000"
      : props.status === "P"
      ? "blue"
      : props.status === "C"
      ? "#2cc05b"
      : "#FF0000"};
  text-align: center;
`;

const StyledBtn = styled.button`
  background-color: #175782;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.42857;
  margin-bottom: 0;
  padding-right: 11px;
  padding-bottom: 6px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  &:hover {
    background-color: #12364e;
    border-color: #adadad;
  }
`;

export default CampaignTasks;
