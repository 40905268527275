import React from "react";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";

function LaInput({
  onChange,
  values,
  isFormFieldValid,
  getFormErrorMessage,
  keyName,
  placeholder,
  type,
  disabled,
  ...rest
}) {
  return (
    <>
      <InputText
        id={keyName}
        type={type ? type : "text"}
        disabled={disabled ? disabled : false}
        placeholder={placeholder}
        name={keyName}
        value={values[keyName]}
        onChange={onChange}
        {...rest}
        className={classNames({
          "p-invalid": isFormFieldValid(keyName.toString()),
        })}
      />
      {getFormErrorMessage(keyName.toString())}
    </>
  );
}

export default LaInput;
