import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "medicalNegligenceSlice",
    initialState: {
        medicalNegligence: {},
        medicalNegligenceList: [],
        medicalNegligenceStatus: [],
    },
    reducers: {
        GETMEDICALNEGLIGENCELISTSUCCESS: (state, action) => {
            return {
                ...state,
                medicalNegligenceList: action.payload,
            };
        },
        GETMEDICALNEGLIGENCELISTERROR: (state) => {
            return {
                ...state,
                medicalNegligenceList: [],
            };
        },
        GETMEDICALNEGLIGENCESUCCESS: (state, action) => {
            return {
                ...state,
                medicalNegligence: action.payload,
            };
        },
        GETMEDICALNEGLIGENCEERROR: (state) => {
            return {
                ...state,
                medicalNegligence: {},
            };
        },
        GETMEDICALNEGLIGENCESTATUSSUCCESS: (state, action) => {
            return { ...state, medicalNegligenceStatus: action.payload };
        },
        GETMEDICALNEGLIGENCESTATUSERROR: (state) => {
            return { ...state, medicalNegligenceStatus: {} };
        },
    },
});

export const {
    GETMEDICALNEGLIGENCELISTSUCCESS,
    GETMEDICALNEGLIGENCELISTERROR,
    GETMEDICALNEGLIGENCESUCCESS,
    GETMEDICALNEGLIGENCEERROR,
    GETMEDICALNEGLIGENCESTATUSERROR,
    GETMEDICALNEGLIGENCESTATUSSUCCESS,
} = slice.actions;
export default slice.reducer;
