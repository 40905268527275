import React from "react";
import styled from "styled-components";

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Dropdown = styled.select`
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 4px;
  margin-right: 15px;
  cursor: pointer;
`;

const CategoryFilter = ({ selectedCategory, onCategoryChange }) => {
  const categories = [
    "Internal",
    "Introducer",
    "Solicitor",
    "Third Party",
    "All",
  ];

  return (
    <FilterContainer>
      <Dropdown value={selectedCategory} onChange={onCategoryChange}>
        {categories.map((category) => (
          <option key={category} value={category}>
            {category}
          </option>
        ))}
      </Dropdown>
    </FilterContainer>
  );
};

export default CategoryFilter;
