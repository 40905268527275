import { hdrService } from "../../services/Hdr";
import { handlePostRequest } from "../../services/PostTemplate";
import {
  GETPCPSUCCESS,
  GETPCPERROR,
  GETPCPSTATUSSUCCESS,
  GETPCPSTATUSERROR,
  GETPCPLISTSUCCESS,
  GETPCPLISTERROR,
} from "../slices/pcpSlice";
import { SETLOADING } from "../slices/loadingSlice";
import { handleGetRequest } from "../../services/GetTemplate";

export const getPcpList = (statusUrl) => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handleGetRequest(statusUrl);
  if (res) {
    dispatch(GETPCPLISTSUCCESS(res?.data));
    dispatch(SETLOADING(false));
    return res;
  } else {
    dispatch(GETPCPLISTERROR(res?.data?.data));
    dispatch(SETLOADING(false));
  }
};

export const getPcpDetails = (pcpCaseId) => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handleGetRequest(`Pcp/getPcpCaseById/${pcpCaseId}`);
  if (res) {
    dispatch(GETPCPSUCCESS(res?.data));
    dispatch(SETLOADING(false));
  } else {
    dispatch(GETPCPERROR(res?.data));
    dispatch(SETLOADING(false));
  }
};
export const getHdrStatus = () => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handleGetRequest("Pcp/getAllPcpStatusCounts");
  if (res) {
    dispatch(GETPCPSTATUSSUCCESS(res?.data));
    dispatch(SETLOADING(false));
    return res;
  } else {
    dispatch(GETPCPSTATUSERROR(res));
    dispatch(SETLOADING(false));
  }
};

export const postHdrDocs = (docs) => async (url) => {
  const res = await hdrService.postHdrDocs(docs, url);
  if (res) {
    return res;
  }
  return false;
};

export const addHdrCase = (data) => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handlePostRequest(data, "hdr/addNewHdrCase");
  dispatch(SETLOADING(false));
  return res;
};
