import React, { useState } from "react";
import styled from "styled-components";
import { FiClipboard } from "react-icons/fi";
import ESignAuditDialog from "./ESignAuditDialog";
import SideBox from "../FormLayout/SideBoxSection";

// Styled components for the button
const StyledButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function ESignAuditButton({ data }) {
  const [showModal, setShowModal] = useState(false);

  const onCloseModal = () => {
    setShowModal(false);
  };

  if (!data) {
    return null;
  }

  return (
    <SideBox title="ESign Audit" className="mt-4">
      <>
        <StyledButton type="button" onClick={() => setShowModal(true)}>
          <FiClipboard size={20} style={{ marginRight: "5px" }} />
          Open ESign Audit
        </StyledButton>
        <ESignAuditDialog
          showModal={showModal}
          onCloseModal={onCloseModal}
          data={data}
        />
      </>
    </SideBox>
  );
}
