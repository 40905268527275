import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AiFillWarning, AiOutlineDown, AiOutlineUp } from "react-icons/ai";

// Styled component for the list
const ReadOnlyListWrapper = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
`;

const ListItem = styled.li`
  margin: 10px 0;
  color: #333;
  display: flex;
  align-items: center;
`;

const WarningIcon = styled(AiFillWarning)`
  margin-right: 10px;
`;

const ShowMoreButton = styled.button`
  background-color: #3ca2bb;
  color: white;
  padding: 5px;
  border-radius: 50%;
  border: none;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

function ReadOnlyList({ showList = false, values, options }) {
  const [showMore, setShowMore] = useState(false);
  const [selectedNames, setSelectedNames] = useState([]);
  const [displayItems, setDisplayItems] = useState([]);

  useEffect(() => {
    if (Array.isArray(values) && Array.isArray(options)) {
      const selectedNames = values.map((value) => {
        return options.find((option) => option.value === value)?.name;
      });
      setSelectedNames(selectedNames);
    } else {
      setSelectedNames([]);
    }
  }, [values, options]);

  useEffect(() => {
    if (selectedNames.length > 5) {
      if (showMore) {
        setDisplayItems(selectedNames);
      } else {
        setDisplayItems(selectedNames.slice(0, 5));
      }
    } else {
      setDisplayItems(selectedNames);
    }
  }, [selectedNames, showMore]);

  if (!showList || !selectedNames?.length) return null;

  return (
    <ReadOnlyListWrapper show={showList}>
      {selectedNames.length > 5 && (
        <ShowMoreButton
          id="showmorebtn"
          type="button"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? <AiOutlineUp /> : <AiOutlineDown />}
        </ShowMoreButton>
      )}
      <ul>
        {displayItems.map((name, index) => (
          <ListItem key={index}>
            <WarningIcon />
            {name}
          </ListItem>
        ))}
      </ul>
    </ReadOnlyListWrapper>
  );
}

export default ReadOnlyList;
