import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import JobForm from "../JobForm";
import validationSchema, { initialValues } from "../JobConstants";
import { Button } from "primereact/button";
import { handlePostRequest } from "../../../../services/PostTemplate";
import { FaSpinner } from "react-icons/fa";

function EditCompanyJobs({ rowData, getjobList, onCloseModal }) {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (formData) => {
      const data = { ...formData };
      Object.keys(data).forEach((key) => {
        if (data[key] === true) {
          data[key] = "Y";
        }
        if (data[key] === false) {
          data[key] = "N";
        }
        if (data[key] === "") {
          data[key] = null;
        }
      });
      data["companycode"] = rowData.companycode;
      data["companyjobcode"] = rowData.companyjobcode;

      //convert all the values to string and null to ""
      Object.keys(data).forEach((key) => {
        if (data[key] === null) {
          data[key] = "";
        }
        data[key] = data[key].toString();
      });

      try {
        setLoading(true);
        await handlePostRequest(data, "userManagement/updateCompanyCompaign");
        onCloseModal();
        await getjobList();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
  };

  const fillFormFields = ({ ...data }) => {
    // To convert Y/N to true/false for radio buttons and checkboxes
    Object.keys(data).forEach((key) => {
      if (data[key] === "Y") {
        data[key] = true;
      }
      if (data[key] === "N") {
        data[key] = false;
      }
      if (data[key] === null) {
        data[key] = "";
      }
    });
    Object.keys(data).forEach((key) => {
      if (formik.initialValues.hasOwnProperty(key)) {
        formik.setFieldValue(key, data[key]);
      }
    });
    formik.setFieldValue("compaigncode", data.tblCompaign.compaigncode);
  };

  useEffect(() => {
    if (rowData) {
      fillFormFields(rowData);
    }
  }, [rowData]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <JobForm {...props} />
        <div align="center" className="p-col-12 p-md-12 mt-4">
          {loading ? (
            <div className="spinner-container">
              <FaSpinner className="spinner-icon" />
            </div>
          ) : (
            <Button type="submit" className="btn btn-primary mr-2">
              Update Job
            </Button>
          )}
        </div>
      </form>
    </>
  );
}

export default EditCompanyJobs;
