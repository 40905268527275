import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { Button } from "primereact/button";
import { handlePostRequest } from "../../services/PostTemplate";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { handleGetRequest } from "../../services/GetTemplate";

function ManualInvoiceForm({ onSubmitManualInvoice }) {
  const [companyOptions, setCompanyOptions] = useState([]);
  const validationSchema = Yup.object().shape({
    customername: Yup.string().required("Required"),
    customeraddress: Yup.string().required("Required"),
    duedate: Yup.string().required("Required"),
    tax: Yup.number().required("Required").typeError("Tax must be a number"),
    grandtotal: Yup.number()
      .required("Required")
      .typeError("This must be a number"),
  });

  const initialValues = {
    customername: "",
    customeraddress: "",
    duedate: "",
    tax: "",
    grandtotal: "",
    description: "",
  };

  const [items, setItems] = useState([]);

  const addItem = () => {
    setItems([...items, { description: "", amount: 0 }]);
  };

  const removeItem = (index, amount) => {
    const totalAmount = items.reduce(
      (acc, item) => acc + Number(item.amount),
      0
    );
    formik.setFieldValue("grandtotal", totalAmount - Number(amount));
    setItems(items.filter((_, i) => i !== index));
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small style={{ width: "63px" }} className="label__error p-d-block">
          {formik.errors[name]}
        </small>
      )
    );
  };

  useEffect(() => {
    const getActiveCompanies = async () => {
      try {
        const response = await handleGetRequest("lovActiveCompanies", false);
        setCompanyOptions(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    getActiveCompanies();
  }, []);

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (data) => {
      if (
        items.length === 0 ||
        items[0].description === "" ||
        items[0].amount === 0
      ) {
        toast.error("Please add at least one item");
        return;
      }
      // check if all items are filled
      const isAllItemsFilled = items.every(
        (item) => item.description !== "" && item.amount !== 0
      );
      if (!isAllItemsFilled) {
        toast.error("Please fill all items");
        return;
      }
      data["manualinvoicedetails"] = items;
      data["grandtotal"] = data["grandtotal"].toString();
      try {
        await handlePostRequest(data, "invoicing/addmanualinvoice");
        handleClear();
        onSubmitManualInvoice();
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleClear = () => {
    formik.resetForm();
    setItems([]);
  };

  const handleItemChange = (event, index) => {
    const { name, value } = event.target;
    const updatedItems = [...items];
    updatedItems[index][name] = value;
    setItems(updatedItems);

    const totalAmount = updatedItems.reduce(
      (acc, item) => acc + Number(item.amount),
      0
    );
    formik.setFieldValue("grandtotal", totalAmount);
  };

  const changeCompany = (e) => {
    console.log(e);
    formik.handleChange(e);
    formik.setFieldValue("customeraddress", e.target.value.type);
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-col-6 p-md-6">
            <label htmlFor="status">Select Company</label>
            <Dropdown
              id="company"
              name="company"
              filter
              options={companyOptions}
              optionLabel="name"
              placeholder="Select a Title"
              value={formik.values.company}
              onChange={changeCompany}
              className={classNames({
                "p-invalid": isFormFieldValid("company"),
                Width__100: true,
              })}
            />
            {getFormErrorMessage("company")}
          </div>
          <div className="p-col-6 p-md-6">
            <label htmlFor="status">Customer Name</label>
            <InputText
              id="customername"
              name="customername"
              value={formik.values.customername}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("customername"),
              })}
            />
            {getFormErrorMessage("customername")}
          </div>
          <div className="p-col-6 p-md-6">
            <label htmlFor="status">Customer Address</label>
            <InputText
              id="customeraddress"
              name="customeraddress"
              value={formik.values.customeraddress}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("customeraddress"),
              })}
            />
            {getFormErrorMessage("customeraddress")}
          </div>
          <div className="p-col-6 p-md-6">
            <label htmlFor="status">Due Date</label>
            <InputText
              id="duedate"
              name="duedate"
              type="date"
              value={formik.values.duedate}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("duedate"),
              })}
            />
            {getFormErrorMessage("duedate")}
          </div>
          <div className="p-col-6 p-md-6">
            <label htmlFor="status">Tax</label>
            <InputText
              id="tax"
              name="tax"
              value={formik.values.tax}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("tax"),
              })}
            />
            {getFormErrorMessage("tax")}
          </div>
          <div className="p-col-6 p-md-8">
            <label htmlFor="status">Grand Total</label>
            <InputText
              id="grandtotal"
              name="grandtotal"
              value={formik.values.grandtotal}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("grandtotal"),
              })}
            />
            {getFormErrorMessage("grandtotal")}
          </div>
          <div className="mt-4">
            <Button
              type="button"
              className="btn btn-primary mr-2"
              onClick={addItem}
            >
              +
            </Button>
          </div>

          {items.map((item, index) => (
            <>
              <div className="p-col-6 p-md-4">
                <label htmlFor="status">Description</label>
                <InputText
                  id="description"
                  name="description"
                  value={item.description}
                  onChange={(event) => {
                    handleItemChange(event, index);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldValid("description"),
                  })}
                />
                {getFormErrorMessage("description")}
              </div>
              <div className="p-col-6 p-md-4">
                <label htmlFor="status">Amount</label>
                <InputText
                  id="amount"
                  name="amount"
                  value={item.amount}
                  onChange={(event) => handleItemChange(event, index)}
                  className={classNames({
                    "p-invalid": isFormFieldValid("amount"),
                  })}
                />
                {getFormErrorMessage("amount")}
              </div>
              <div className="p-col-6 p-md-4">
                <div className="mt-4" style={{ width: "40px" }}>
                  <StyledRemovedButton
                    type="button"
                    className="btn"
                    onClick={() => removeItem(index, item.amount)}
                  >
                    -
                  </StyledRemovedButton>
                </div>
              </div>
            </>
          ))}
        </div>

        <div align="center" className="p-col-12 p-md-12">
          <Button type="submit" className="btn btn-primary mr-2">
            Add
          </Button>
          <Button
            type="button"
            className="btn btn-warning mr-2"
            onClick={handleClear}
          >
            Clear
          </Button>
        </div>
      </form>
    </div>
  );
}

const StyledRemovedButton = styled.button`
  background-color: #c22b2b;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 33px;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    background-color: #c22b2b;
  }
`;

export default ManualInvoiceForm;
