import React from "react";
import LaInput from "../../../components/LaInput";
import LaInputTextArea from "../../../components/LaInputTextArea";
import LaVehicleReg from "../../../components/LaVehicleReg";

function ThirdPartyVehicle({
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    formik,
  };
  const vehicleRegProps = {
    ...props,
    keyNameRegNo: "partyregno",
    keyNameMakeModel: "partymakemodel",
    keyNameYearOfManufacture: "partyyearofmanufacture",
    keyNameInsurer: "partyinsurer",
    keyNamePolicyNo: "partypolicyno",
    keyNameRefNo: "partyrefno",
  };
  return (
    <>
      <div style={{ marginLeft: "30px" }}>
        <h6 style={{ fontWeight: "bold", fontSize: "12px" }}>
          Third Party Vehicle
        </h6>
      </div>
      <hr className="border__hr" />
      <LaVehicleReg {...vehicleRegProps} />
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Greencard Number:</label>
        <LaInput
          keyName="greencardno"
          placeholder="Green card if TP vehicle is foreign"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Third Party Name:</label>
        <LaInput keyName="partyname" placeholder="" {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Contact Number:</label>
        <LaInput
          keyName="partycontactno"
          placeholder="contact no."
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Address</label>
        <LaInputTextArea
          keyName="partyaddress"
          placeholder="defendant address"
          {...props}
        />
      </div>
    </>
  );
}

export default ThirdPartyVehicle;
