import { Dialog } from "primereact/dialog";
import React from "react";
import EditCompanyJobs from "./EditCompanyJobs";

function EditJobDialog({ showModal, onCloseModal, data, getjobList }) {
  return (
    <Dialog
      header="Edit Job"
      position="top"
      draggable={false}
      visible={showModal}
      style={{ width: "80vw" }}
      onHide={onCloseModal}
    >
      <div className="mt-4">
        <EditCompanyJobs
          rowData={data}
          getjobList={getjobList}
          onCloseModal={onCloseModal}
        />
      </div>
    </Dialog>
  );
}

export default EditJobDialog;
