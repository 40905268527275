import React from "react";
import PhoneNumberVerifier from "../PhoneNumberVerifier/PhoneNumberVerifier";
import LaInput from "../LaInput";

function LaMobile({ values, keyName, ...rest }) {
  return (
    <>
      <LaInput
        {...rest}
        keyName={keyName}
        values={values}
        placeholder="Mobile"
      />
      <PhoneNumberVerifier phoneNumber={values[keyName]} />
    </>
  );
}

export default LaMobile;
