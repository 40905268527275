import React from "react";
import LaCheckbox from "../../../components/LaCheckbox";
import LaInput from "../../../components/LaInput";
import LaInputTextArea from "../../../components/LaInputTextArea";

function MedicalAttendanceInformation({
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
  };

  return (
    <>
      <div>
        <div className="Panel__Heading">Medical Attendance Information</div>
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Has the client attended hospital?</label>
        <LaCheckbox keyName="clientattendedhospital" {...props} />
      </div>
      {values.clientattendedhospital && (
        <>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Date of hospital attendance</label>
            <LaInput
              keyName="datehospitalattendance"
              placeholder="Date of Hospital Attendance"
              type={"date"}
              {...props}
            />
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Hospital name</label>
            <LaInput
              keyName="hospitalname"
              placeholder="Hospital Name"
              {...props}
            />
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Hospital address</label>
            <LaInput
              keyName="hospitaladdress"
              placeholder="Hospital Address"
              {...props}
            />
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">What advice was given?</label>
            <LaInputTextArea
              keyName="hospitaladvisegiven"
              placeholder="Hospital Advice"
              {...props}
            />
          </div>
        </>
      )}

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Has the client seen their GP?</label>
        <LaCheckbox keyName="clientseengp" {...props} />
      </div>
      {values.clientseengp && (
        <>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Date of GP attendance</label>
            <LaInput
              keyName="dategpattendance"
              placeholder="Date of GP Attendance"
              type={"date"}
              {...props}
            />
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">GP name</label>
            <LaInput keyName="gpname" placeholder="GP Name" {...props} />
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">GP address</label>
            <LaInput keyName="gpaddress" placeholder="GP Address" {...props} />
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">What advice if given?</label>
            <LaInputTextArea
              keyName="gpadvisegiven"
              placeholder="GP Advice"
              {...props}
            />
          </div>
        </>
      )}
    </>
  );
}

export default MedicalAttendanceInformation;
