import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { RiLockPasswordFill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginAction } from "../../redux/actions/authAction";
import "./Login.css";

function Login({ redirectUrl, setValidToken }) {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [loading, setloading] = useState(false);
  const [loadingIcon, setloadingIcon] = useState("");

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogin = async (e) => {
    e?.preventDefault();
    setloading(true);
    setloadingIcon("pi pi-spin pi-spinner");
    const data = {
      userName: username,
      password: password,
    };
    try {
      const res = await dispatch(loginAction(data));
      setValidToken(true);
      if (res?.login) {
        navigate(redirectUrl || "../", { replace: true });
      }
    } catch (err) {
      setValidToken(false);
    } finally {
      setloading(false);
      setloadingIcon("");
    }
  };

  const handleKey = (e) => {
    if (e.keyCode === 13) handleLogin();
  };

  return (
    <div className="login-wrapper" tabIndex="0" onKeyDown={handleKey}>
      <div className="box-shadow">
        <div className="popup-header">
          <span className="text-semibold">User Login</span>
          <div className="btn-group pull-right"></div>
        </div>

        <div className="well" id="login">
          <div data-bind="with: accountModel">
            <div className="form-group has-feedback">
              <label>
                Email <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                autoComplete="nofill"
                className="form-control"
                placeholder="email"
                onChange={(e) => setusername(e.target.value)}
              />
              <span className="input-icon">
                <ImUsers />
              </span>
            </div>

            <div className="form-group has-feedback mt-4">
              <label>
                Password <span className="text-danger">*</span>
              </label>
              <input
                type="password"
                autoComplete="nofill"
                className="form-control"
                placeholder="password"
                onChange={(e) => setpassword(e.target.value)}
              />
              <span className="input-icon">
                <RiLockPasswordFill />
              </span>
            </div>
          </div>
          <div className="mt-4 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary  d-flex align-items-center align-content-center"
              disabled={loading}
              onClick={handleLogin}
            >
              {loadingIcon ? <i className={loadingIcon}></i> : <FaBars />}
              &nbsp;
              <span>Sign in</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
