import React, { useEffect } from "react";
import { isTokenExpired } from "../../services/Auth";
import { Loader } from "react-overlay-loader";

function AuthVerify({ setValidToken, token }) {
  useEffect(() => {
    const checkToken = async () => {
      console.log("Checking token");
      try {
        await isTokenExpired(token);
        setValidToken(true);
      } catch (error) {
        setValidToken(false);
      }
    };
    checkToken();
  }, []);

  return <Loader loading={true} />;
}

export default AuthVerify;
