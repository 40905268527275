import React from "react";
import styled from "styled-components";
import { FiInfo } from "react-icons/fi"; // Use FiInfo icon from react-icons
import VehicleDetailsDialog from "./VehicleDetailsDialog";
import { useSelector } from "react-redux";

const StyledButton = styled.button`
  background-color: #007bff; /* Add your preferred background color */
  color: #fff; /* Remove color for an icon-only button */
  padding: 10px; /* Adjust padding to fit icon size */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center; /* Center icon horizontally */
  align-items: center;
  gap: 5px; /* Add space between icon and text */
`;

function VehicleDetailsButton({ responseData }) {
  const [showModal, setShowModal] = React.useState(false);
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const userCat = user?.tblUsercategory?.categorycode;

  const onCloseModal = () => {
    setShowModal(false);
  };

  const openModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  if (responseData === null || userCat !== "4") {
    return null;
  }
  return (
    <>
      <StyledButton onClick={openModal}>
        <FiInfo /> Show Vehicle Details
      </StyledButton>
      <VehicleDetailsDialog
        showModal={showModal}
        onCloseModal={onCloseModal}
        responseData={responseData}
      />
    </>
  );
}

export default VehicleDetailsButton;
