import * as Yup from "yup";

const initialValues = {
  anynotes: "N",
  imagesvideostaken: "N",
  anyotherlosses: "",
  calldate: "",
  calltime: "",
  clientcontactno: "",
  clientdob: "",
  clientemail: "",
  clientninumber: "",
  clientoccupation: "",
  complaintmade: "",
  complaintresponded: "",
  emergencysrvcattend: "",
  gpdetails: "",
  hospitalattend: "",
  hospitaldetails: "",
  incidentdatetime: "",
  injuries: "",
  location: "",
  medicalattenddate: "",
  negligencedescr: "",
  notesdetail: "",
  other: "",
  policeref: "",
  remarks: "",
  separatecomplaintcontact: "",
  stilloffwork: "",
  timeoffwork: "",
  timesincecomplaint: "",
  tppostalcode: "",
  tpaddress1: "",
  tpaddress2: "",
  tpaddress3: "",
  tpcity: "",
  tpregion: "",
  tpcompany: "",
  tpcontactno: "",
  tpname: "",
  witnesscontactdetails: "",
  witnesses: "",
  witnessname: "",
  hotkeyed: "",
  refundedclientinformed: "N",
  agentadviced: "N",
  gdpr: "N",
  advisor: "",
  introducer: "",
  title: "",
  firstname: "",
  middlename: "",
  lastname: "",
  postalcode: "",
  address1: "",
  address2: "",
  address3: "",
  city: "",
  region: "",
  acctime: "",
};

const validationSchema = Yup.object().shape({
  incidentdatetime: Yup.string().required("required."),
  acctime: Yup.string().required("required."),
  location: Yup.string().required("required."),
  address1: Yup.string().required("required."),
  city: Yup.string().required("required."),
  clientdob: Yup.string().required("required."),
  title: Yup.string().required("required."),
  firstname: Yup.string().required("required."),
  lastname: Yup.string().required("required."),
  postalcode: Yup.string().required("required."),
  clientcontactno: Yup.string()
    .required("required.")
    .nullable()
    .max(11, "Max 11 digits")
    .min(11, "Min 11 digits")
    .matches(/^[0-9]+$/, "Must be only digits"),
  clientninumber: Yup.string()
    .required("required.")
    .test("clientninumber", "Invalid NIN", (value) => {
      if (
        value === "Will be provided to the solicitor" ||
        value === "Minor" ||
        value === "Solicitor obtained Number"
      ) {
        return true;
      }
      if (value) {
        let ninumberFirstChar = value[0];
        let ninumberSecondChar = value[1];
        let prefix = ninumberFirstChar + ninumberSecondChar;
        let prefixCheckList = ["BG", "GB", "KN", "NK", "NT", "TN", "ZZ"];
        let checklist = "D,F,I,Q,U,V";
        if (
          checklist.includes(ninumberSecondChar) ||
          checklist.includes(ninumberFirstChar) ||
          value[1] === "O" ||
          prefixCheckList.includes(prefix)
        ) {
          return false;
        } else {
          const re = new RegExp(
            /^\s*[a-zA-Z]{2}(?:\s*\d\s*){6}[a-zA-Z]{1,}?\s*$/
          );
          return re.test(value);
        }
      }
      return true;
    }),
});

export { initialValues, validationSchema };
