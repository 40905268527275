import { useSelector } from "react-redux";
import { useMemo } from "react";

export const useUserCategory = () => {
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const userCat = user?.tblUsercategory?.categorycode;

  return useMemo(() => {
    switch (userCat) {
      case "4":
        return "LegalInternal";
      case "1":
        return "Introducer";
      case "2":
        return "Solicitor";
      default:
        return "";
    }
  }, [userCat]);
};
