import { Dialog } from "primereact/dialog";
import React from "react";
import HireCancelForm from "./HireCancelForm";

function HireCancelDialog({
  showModal,
  hireclaimcode,
  onCloseModal,
  buttonDetails,
}) {
  return (
    <Dialog
      header="Hire Cancel"
      visible={showModal}
      style={{ width: "70vw" }}
      onHide={onCloseModal}
    >
      <HireCancelForm
        onCloseModal={onCloseModal}
        buttonDetails={buttonDetails}
        hireclaimcode={hireclaimcode}
      />
    </Dialog>
  );
}

export default HireCancelDialog;
