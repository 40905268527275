import React, { useEffect, useState } from "react";
import SideBox from "../../components/FormLayout/SideBoxSection";
import { handleGetRequest } from "../../services/GetTemplate";
import { useSelector } from "react-redux";

function Timeline({
  getTimelineService = null,
  campaignCode = null,
  campaignLogCode,
}) {
  const [timeLine, setTimeLine] = useState(null);
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);

  useEffect(() => {
    const getTimeLine = async () => {
      try {
        const response = await handleGetRequest(
          `${getTimelineService}/${campaignCode}`,
          false
        );
        setTimeLine(response?.data || null);
      } catch (error) {
        setTimeLine(null);
        console.log(error);
      }
    };
    if (campaignCode) {
      getTimeLine();
    }
  }, [campaignCode, getTimelineService]);

  if (
    timeLine === null ||
    timeLine.length === 0 ||
    user?.tblUsercategory?.categorycode !== "4" ||
    getTimelineService === null ||
    campaignCode === null
  ) {
    return null;
  }

  const formatCreatedOn = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const seconds = dateObj.getSeconds();
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <SideBox title="Process Timeline" className="mt-4">
      <div className="history-tl-container">
        <ul className="tl">
          {timeLine?.map((item, index) => (
            <li className="tl-item" key={item?.[campaignLogCode]}>
              <div>
                <strong>{item?.descr}</strong> <br />
                <span>{item?.userName}</span>
                <br />
                <small>{formatCreatedOn(item?.createdon)}</small>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </SideBox>
  );
}

export default Timeline;
