import React, { useState } from "react";
import MapComponent from "./MapComponent"; // assuming you have a MapComponent component
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { FaMapMarkedAlt } from "react-icons/fa"; // Importing Map Marker Icon
import styled from "styled-components"; // Importing styled-components
import { toast } from "react-toastify";
import { LoqateKey } from "./index"; // Importing LoqateKey from the same folder

const YOUR_API_URL =
  "https://api.addressy.com/Geocoding/International/Geocode/v1.10/json3.ws";

// Styled button using styled-components
const StyledButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: #0056b3;
  }
`;

function MapAddress({ address }) {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [show, setShow] = useState(false);

  const handleShowMapClick = async (e) => {
    e.preventDefault();
    try {
      const params = new URLSearchParams();
      params.append("Key", LoqateKey);
      params.append("Country", "GBR");
      params.append("Location", address[0] + ", " + address[3]);

      const response = await axios.post(YOUR_API_URL, params);

      // Test for an error
      if (
        response.data.Items.length === 1 &&
        response.data.Items[0].Error !== undefined
      ) {
        alert(response.data.Items[0].Description);
      } else {
        // Check if there were any items found
        if (response.data.Items.length === 0) {
          toast.error("Sorry, no coordinates found for this address");
        } else {
          const { Latitude, Longitude } = response.data.Items[0];
          setLatitude(Latitude);
          setLongitude(Longitude);
          setShow(true);
        }
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };

  if (address[0] === "" || address[3] === "") {
    return null;
  }

  return (
    <>
      <div className="p-col-12 p-mt-2">
        <StyledButton onClick={handleShowMapClick}>
          <FaMapMarkedAlt />
          Show Address on Map
        </StyledButton>
      </div>
      <Dialog
        header={"Address Location"}
        visible={show}
        style={{ width: "60%" }}
        onHide={() => setShow(false)}
      >
        <MapComponent
          latitude={latitude}
          longitude={longitude}
          address={address}
        />
      </Dialog>
    </>
  );
}

export default MapAddress;
