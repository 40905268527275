import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";

function DealershipDetails({
  getFormErrorMessage,
  isFormFieldValid,
  classNames,
  onChange,
  values,
}) {
  return (
    <div>
      <div className="p-mt-4 p-ml-4 p-mr-4">
        <div className="Panel__Heading">Dealership Details</div>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-col-6 p-mt-2">
            <label className="p-d-block">Dealership name</label>
            <InputText
              id="dealername"
              name="dealername"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("dealername"),
                },
                "p-d-block Width__100"
              )}
              value={values.dealername}
              onChange={onChange}
            />
            {getFormErrorMessage("dealername")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Dealership address</label>
            <InputTextarea
              rows={5}
              id="dealeraddress"
              name="dealeraddress"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("dealeraddress"),
                },
                "p-d-block Width__100"
              )}
              value={values.dealeraddress}
              onChange={onChange}
            />
            {getFormErrorMessage("dealeraddress")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DealershipDetails;
