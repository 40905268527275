import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navigation from "../Navigation";
import { handleGetRequest } from "../../../services/GetTemplate";
import NoUsersComponent from "./NoUsersComponent";
import AddUserButton from "./AddUser/AddUserButton";
import CompanyUserDetails from "./CompanyUserDetails";

function ViewCompanyUsers() {
  const { id } = useParams();
  const [userList, setuserList] = useState([]);
  const [userFound, setuserFound] = useState(true);

  const getuserList = async () => {
    try {
      const response = await handleGetRequest("getCompaniesUsers/" + id);
      setuserList(response?.data);
      if (response?.data === null || response?.data?.length === 0) {
        setuserFound(false);
      } else {
        setuserFound(true);
      }
    } catch (error) {
      setuserFound(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getuserList();
    }
  }, [id]);

  return (
    <>
      <Navigation activeItem="users" id={id} />
      {userFound === false ? (
        <NoUsersComponent getuserList={getuserList} id={id} />
      ) : (
        <>
          <div className="d-flex justify-content-end mt-4">
            <AddUserButton getuserList={getuserList} id={id} />
          </div>
          <CompanyUserDetails userList={userList} getuserList={getuserList} />
        </>
      )}
    </>
  );
}

export default ViewCompanyUsers;
