import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function BroadcastDescr({ onEditorChange }) {
  const [message, setMessage] = useState("");

  const handleQuillChange = (value) => {
    setMessage(value);
    onEditorChange(value);
  };

  return (
    <div>
      <ReactQuill value={message} onChange={handleQuillChange} />
      {/* <div
        className="message"
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
      <style>{`
        .message {
          border: 1px solid #ccc;
          padding: 10px;
          margin-top: 10px;
        }
      `}</style> */}
    </div>
  );
}

export default BroadcastDescr;
