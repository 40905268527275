import React, { useEffect, useCallback } from "react";
import { getMessages } from "../../redux/actions/messagesAction";
import { useDispatch, useSelector } from "react-redux";
import MessagesTable from "../MessagesTable";

function Messages({ code, url, compainName, posturl }) {
  const dispatch = useDispatch();

  const messages = useSelector((state) => state.messagesSlice?.messages);

  const handleMessages = useCallback(async () => {
    dispatch(getMessages(code, url));
  }, [dispatch, code, url]);

  useEffect(() => {
    handleMessages();
  }, [handleMessages]);

  return (
    <MessagesTable
      messages={messages}
      loading={false}
      posturl={posturl}
      compainName={compainName}
    />
  );
}

export default Messages;
