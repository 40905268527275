import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { handleGetRequest } from "../../../services/GetTemplate";
import { handlePostRequest } from "../../../services/PostTemplate";
import { getrtaDetails } from "../../../redux/actions/rtaAction";
import { SplitButton } from "primereact/splitbutton";
import "./CaseHeaderRta.css";
import { Divider } from "primereact/divider";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import DuplicateCaseToRta from "./DuplicateCaseToRta";
import RtaExcel from "../RtaExcel";
import CampaignDuplicateCounts from "../../../components/CampaignDuplicateCounts/CampaignDuplicateCounts";

function CaseHeaderRta({ title }) {
  const [solicitorRtaData, setsolicitorRtaData] = useState("");
  const [solicitorRtaDataValue, setsolicitorRtaDataValue] = useState("");
  const [rtaHotkeyModal, setrtaHotkeyModal] = useState(false);
  const [companyWiseUser, setcompanyWiseUser] = useState("");
  const [companyWiseUserValue, setcompanyWiseUserValue] = useState("");
  const [rejectModal, setrejectModal] = useState(false);
  const [reason, setreason] = useState("");
  const [rejectBtnValue, setrejectBtnValue] = useState("");
  const [loading, setloading] = useState(false);
  const [loadingIcon, setloadingIcon] = useState("");
  const [btnValue, setbtnValue] = useState("");
  const [copyCaseModal, setcopyCaseModal] = useState(false);
  const [copyCaseNumber, setcopyCaseNumber] = useState("");
  const [hirecode, sethirecode] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const { rta } = useSelector((state) => state.rtaSlice);
  const userCatInternal = user?.tblUsercategory?.categorycode;

  const breakpoints = { "960px": "75vw", "640px": "100vw" };

  const createdon = useSelector((state) => state?.rtaSlice?.rta?.createdon);
  const isScotland = useSelector((state) => state?.rtaSlice?.rta?.scotland);
  const airbagopened = useSelector(
    (state) => state?.rtaSlice?.rta?.airbagopened
  );

  const funcgetSolicitorsForRta = async () => {
    if (airbagopened) {
      const jurisdiction = isScotland === "Y" ? "S" : "E";
      const res = await handleGetRequest(
        "lovSolicitorsForRta/" + jurisdiction + "/" + airbagopened
      );
      setsolicitorRtaData(res.data);
    }
  };

  useEffect(() => {
    funcgetSolicitorsForRta();
  }, [isScotland, airbagopened]);

  const handleSolicitor = async (e) => {
    setsolicitorRtaDataValue(e.value);
    const res = await handleGetRequest("lovCompanyWiseUSer/" + e.value.code);
    setcompanyWiseUser(res.data);
  };

  const rtaActionButtons = useSelector(
    (state) => state?.rtaSlice?.rta?.rtaActionButtons
  );
  const hdrActionButtonForLA = useSelector(
    (state) => state?.rtaSlice?.rta?.rtaDropdownBtns
  );

  let hdrActionBtnitems = [];
  if (hdrActionButtonForLA !== null && hdrActionButtonForLA !== undefined) {
    hdrActionButtonForLA.map((item) => {
      hdrActionBtnitems.push({
        label: item?.buttonname,
        command: () => {
          handleActionButton(item);
        },
      });
    });
  }

  const { rtacode } = useParams();

  const fetchRtaDetails = async () => {
    await dispatch(getrtaDetails(rtacode));
  };

  const handleActionHotKey = (value) => {
    setbtnValue(value);
    setrtaHotkeyModal(true);
  };

  const handleSubmitSolicitor = async () => {
    const data = {
      rtacode: rtacode.toString(),
      solicitorCode: solicitorRtaDataValue.code,
      solicitorUserCode: companyWiseUserValue.code,
      toStatus: btnValue,
    };
    await handlePostRequest(data, "rta/performActionOnRtaFromDirectIntro");
    setrtaHotkeyModal(false);
    fetchRtaDetails();
  };

  const footer = (
    <div>
      <center className="p-mt-2 p-button-outlined">
        <Button
          className="btn btn-primary"
          onClick={handleSubmitSolicitor}
          label="Submit"
        />
      </center>
    </div>
  );

  const handleActionButton = async (value) => {
    if (value.rejectDialog === "Y" || value?.acceptDialog === "Y") {
      // send reason on accept case as well
      setrejectModal(true);
      setrejectBtnValue(value.buttonvalue);
    } else {
      const data = {
        rtacode,
        toStatus: value.buttonvalue,
      };
      await handlePostRequest(data, "rta/performActionOnRta");
      fetchRtaDetails();
    }
  };

  const handlRejectActionButton = async () => {
    if (!reason.trim()) {
      // Show a toast message or some other form of error indication
      toast.error("Please enter a reason before submitting.");
      return;
    }
    setloading(true);
    setloadingIcon("pi pi-spin pi-spinner");
    const data = {
      rtacode,
      toStatus: rejectBtnValue,
      reason,
    };
    await handlePostRequest(data, "rta/performActionOnRta");
    setrejectModal(false);
    setrejectBtnValue("");
    setloading(false);
    setloadingIcon("");
    fetchRtaDetails();
  };

  const actionButtons = (
    <div>
      {rtaActionButtons && rtaActionButtons !== null
        ? rtaActionButtons.map((item) => {
            if (
              item.apiflag === "Y" &&
              localStorage.getItem("directIntroducer") === "true"
            )
              return (
                <Button
                  value={item.buttonvalue}
                  onClick={(e) => {
                    handleActionHotKey(item.buttonvalue);
                  }}
                  label={item.buttonname}
                  className="btn-info btn-sm p-mr-2 p-mb-2"
                />
              );
            else
              return (
                <Button
                  value={item.buttonvalue}
                  onClick={(e) => {
                    handleActionButton(item);
                  }}
                  label={item.buttonname}
                  className="btn-info btn-sm p-mr-2 p-mb-2"
                />
              );
          })
        : null}
      {user?.tblUsercategory?.categorycode === "4" ||
      user?.tblUsercategory?.categorycode === "1" ? (
        <Button
          onClick={(e) => {
            e.preventDefault();
            copyCase();
          }}
          label={"Copy Case to Hire"}
          className="btn-info btn-sm p-mr-2 p-mb-2"
        />
      ) : null}
    </div>
  );

  const Rejectfooter = (
    <div>
      <center className="p-mt-2 p-button-outlined">
        <Button
          icon={loadingIcon || ""}
          disabled={loading}
          onClick={handlRejectActionButton}
          label="Submit"
          className="btn btn-primary"
        />
      </center>
    </div>
  );

  const copyCase = async () => {
    const data = {
      rtacode,
    };
    try {
      const res = await handlePostRequest(data, "rta/copyRtaToHire");
      // show modal with case number
      setcopyCaseModal(true);
      setcopyCaseNumber(res?.data?.hirenumber);
      sethirecode(res?.data?.hirecode);
    } catch (err) {
      toast.error("Error in Copying Case");
    }
  };

  const copyCaseFooter = (
    <div>
      <center className="p-mt-2 p-button-outlined">
        <Button
          onClick={() => {
            navigate("/hireDetails/" + hirecode);
          }}
          label="Go to Hire"
          className="btn btn-info"
        />
        <Button
          onClick={() => {
            setcopyCaseModal(false);
            setcopyCaseNumber("");
          }}
          label="Close"
          className="btn btn-primary"
        />
      </center>
    </div>
  );

  if (!rta?.statusDescr) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="case-header">
        <div className="col-lg-4">
          <h4>{title}</h4>
        </div>

        <div style={{ display: "flex" }}>
          <div className="mt-2 p-mr-2"> {createdon} </div>
          <Divider style={{ padding: "0px" }} layout="vertical" />
          <div>
            <Button
              onClick={(e) => {
                e.preventDefault();
              }}
              label={rta.statusDescr}
              style={{ cursor: "text" }}
              className="btn-info btn-sm p-mr-2 p-mb-2"
            />
          </div>

          <Divider style={{ padding: "0px" }} layout="vertical" />
          <div>{actionButtons}</div>
          <DuplicateCaseToRta />
          {hdrActionButtonForLA !== null &&
          hdrActionButtonForLA !== undefined ? (
            <div>
              <SplitButton
                icon="pi pi-sliders-h"
                model={hdrActionBtnitems}
              ></SplitButton>
            </div>
          ) : (
            ""
          )}
        </div>
        <Dialog
          header="Solicitor for Rta"
          visible={rtaHotkeyModal}
          footer={footer}
          onHide={() => setrtaHotkeyModal(false)}
          breakpoints={breakpoints}
          style={{ width: "50vw" }}
        >
          <div
            className="p-fluid p-formgrid p-grid p-mt-4"
            style={{ paddingBottom: "30%" }}
          >
            <div className="p-field p-col-12 p-md-6">
              <label>Hotkey Account</label>
              <Dropdown
                className="Width__100 "
                options={solicitorRtaData}
                value={solicitorRtaDataValue}
                onChange={(e) => {
                  handleSolicitor(e);
                }}
                placeholder="Select"
                optionLabel="name"
              />
            </div>
            <div className="p-field p-col-12 p-md-6">
              <label>Hotkey User</label>
              <Dropdown
                className="Width__100 "
                options={companyWiseUser}
                value={companyWiseUserValue}
                onChange={(e) => {
                  setcompanyWiseUserValue(e.value);
                }}
                placeholder="Select"
                optionLabel="name"
              />
            </div>
          </div>
        </Dialog>
        <Dialog
          header="Action"
          visible={rejectModal}
          footer={Rejectfooter}
          onHide={() => setrejectModal(false)}
          breakpoints={breakpoints}
          style={{ width: "50vw" }}
        >
          <div className="p-mt-4">
            <InputTextarea
              className="Width__100"
              rows={5}
              cols={60}
              placeholder="Enter Reason "
              value={reason}
              onChange={(e) => setreason(e.target.value)}
            />
          </div>
        </Dialog>
        <Dialog
          header="Copy Case Success"
          visible={copyCaseModal}
          footer={copyCaseFooter}
          onHide={() => setcopyCaseModal(false)}
          breakpoints={breakpoints}
          style={{ width: "50vw" }}
        >
          <div className="p-mt-4">
            <h4>Case Number : {copyCaseNumber}</h4>
          </div>
        </Dialog>
      </div>
      <span>
        {userCatInternal === "4" || userCatInternal === "2" ? (
          <RtaExcel rta={rta} />
        ) : null}
      </span>
      <CampaignDuplicateCounts
        code={rtacode}
        viewDetailLink="rtadetails"
        caseId="rtacode"
        api="rta/getRtaDuplicates"
      />
    </>
  );
}

export default CaseHeaderRta;
