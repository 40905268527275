import { baseURL } from "../Config";
import axios from "axios";
import { toast } from "react-toastify";
import { SETLOADING } from "../redux/slices/loadingSlice";
import { handlePostRequest } from "./PostTemplate";

export const getMessages = async (code, url) => {
  let res;
  await axios({
    method: "GET",
    url: `${baseURL + url + code}`,
    headers: {
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      res = response.data.data;
    })
    .catch((err) => {
      toast.warn(err?.response?.data?.messages);
      res = false;
    });
  return res;
};

export const resendEmail = (data, posturl) => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handlePostRequest(data, posturl);
  dispatch(SETLOADING(false));
  return res;
};
