import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "dropdownSlice",
  initialState: {
    dropdowns: {},
  },
  reducers: {
    SETDROPDOWNSUCCESS: (state, action) => {
      const { url, options } = action.payload;
      state.dropdowns[url] = options;
    },
    SETDROPDOWNERROR: (state, action) => {
      const { url } = action.payload;
      state.dropdowns[url] = [];
    },
  },
});

export const { SETDROPDOWNERROR, SETDROPDOWNSUCCESS } = slice.actions;
export default slice.reducer;
