import React, { useEffect, useState } from "react";
import LogComponent from "./LogComponent";
import { handleGetRequest } from "../../services/GetTemplate";
import LogEmpty from "./LogEmpty";

function Logs({ api, caseCode }) {
  const [logs, setLogs] = useState(null);

  useEffect(() => {
    const getLogs = async () => {
      try {
        const response = await handleGetRequest(`${api}/${caseCode}`, false);
        setLogs(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    if (caseCode) {
      getLogs();
    }
  }, [api, caseCode]);

  if (logs === null) {
    return <LogEmpty />;
  }

  return <LogComponent logs={logs} />;
}

export default Logs;
