import * as Yup from "yup";

const initialValues = {
  addressline1: "",
  addressline2: "",
  city: "",
  companystatus: "",
  contactperson: "",
  email: "",
  jurisdiction: "",
  name: "",
  phone: "",
  phone2: "",
  postcode: "",
  tag: "",
  userCategoryCode: "",
  bdmuser: "",
  region: "",
  directIntroducer: "N",
  vat: "N",
  varegno: "",
  billtoemail: "",
  billtoname: "",
  accountemail: "",
  secondaryaccountemail: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("required."),
  contactperson: Yup.string().required("required."),
  email: Yup.string().email("Invalid email.").required("required."),
  userCategoryCode: Yup.string().required("required."),
  companystatus: Yup.string().required("required."),
  jurisdiction: Yup.string().required("required."),
  tag: Yup.string().required("required."),
  // if vat is true, then varegno is required
  varegno: Yup.string().when("vat", {
    is: (val) => val === "Y" || val === true,
    then: Yup.string().required("required."),
  }),
  // when userCategoryCode is 2 or 4, then accountemail is required
  accountemail: Yup.string().when("userCategoryCode", {
    is: (val) => val === "2" || val === "4",
    then: Yup.string().required("required."),
  }),
  // when userCategoryCode is 2 or 4, then secondaryaccountemail is required
  // secondaryaccountemail: Yup.string().when("userCategoryCode", {
  //   is: (val) => val === "2" || val === "4",
  //   then: Yup.string().required("required."),
  // }),
  // when userCategoryCode is 1 or 4, then billtoemail is required
  billtoemail: Yup.string().when("userCategoryCode", {
    is: (val) => val === "1" || val === "4",
    then: Yup.string().required("required."),
  }),
  // when userCategoryCode is 1 or 4, then billtoname is required
  billtoname: Yup.string().when("userCategoryCode", {
    is: (val) => val === "1" || val === "4",
    then: Yup.string().required("required."),
  }),
});

export default validationSchema;

export { initialValues, validationSchema };
