import React from "react";
import styled from "styled-components";
import { RiFilterLine } from "react-icons/ri";
import RtaFilterCaseListDialog from "./RtaFilterCaseListDialog";

const StyledButton = styled.button`
  background-color: #007bff; /* Add your preferred background color */
  color: #fff; /* Add your preferred text color */
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

function RtaFilterCaseListButton() {
  const [showModal, setShowModal] = React.useState(false);

  const onCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <StyledButton onClick={() => setShowModal(true)}>
        <RiFilterLine style={{ marginRight: "8px" }} />
        Filter
      </StyledButton>
      <RtaFilterCaseListDialog
        showModal={showModal}
        onCloseModal={onCloseModal}
      />
    </>
  );
}

export default RtaFilterCaseListButton;
