import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { FiSettings } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { handleGetRequest } from "../../services/GetTemplate";
import ManualInvoiceForm from "./ManualInvoiceForm";

function ManualInvoice() {
  const [invoiceList, setInvoiceList] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const navigate = useNavigate();

  const actionsTemplate = ({ invoiceno }) => {
    return (
      <div
        title="Details"
        className="pointer"
        onClick={() => {
          navigate(`../manualinvoicedetails/${invoiceno}`);
        }}
      >
        <FiSettings />
      </div>
    );
  };

  const statusbody = (rowData) => {
    return <Chip label={rowData?.statusdescr} className="p-px-4 custom-chip" />;
  };

  const getInvoiceList = async () => {
    const res = await handleGetRequest(`invoicing/getAllManualInvoices`);
    setInvoiceList(res.data);
  };

  useEffect(() => {
    getInvoiceList();
  }, []);

  useEffect(() => {
    onGlobalFilterChange();
  }, [invoiceList]);

  const onGlobalFilterChange = (e = false) => {
    const keys = [
      "compaigntype",
      "invoicecode",
      "username",
      "createdon",
      "amount",
      "statuscode",
    ];
    const value = e?.target?.value;
    if (value) {
      const filteredList = invoiceList.filter((item) => {
        let flag = false;
        keys.forEach((key) => {
          if (
            key &&
            item[key] &&
            item[key]
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
            flag = true;
        });
        if (flag) return item;
      });
      setFiltered(filteredList);
    } else {
      setFiltered(invoiceList);
    }
  };

  const onSubmitManualInvoice = () => {
    getInvoiceList();
  };

  return (
    <div>
      <div className="card px-4 pt-2 pb-2 mb-4">
        <ManualInvoiceForm onSubmitManualInvoice={onSubmitManualInvoice} />
      </div>
      <div className="card">
        <StyledInvoiceDataTable
          value={filtered || []}
          showGridlines
          responsiveLayout="scroll"
          header="Manual Invoice"
        >
          <Column field="invoiceno" sortable header="Invoice no"></Column>
          <Column field="customername" sortable header="Customer Name"></Column>
          <Column
            field="customeraddress"
            sortable
            header="Customer Address"
          ></Column>
          <Column field="duedate" sortable header="Due Date"></Column>
          <Column field="tax" sortable header="Tax"></Column>
          <Column field="grandtotal" sortable header="Grand Total"></Column>
          <Column
            field="statusdescr"
            body={statusbody}
            sortable
            header="Status"
          ></Column>
          <Column header="Acts" body={actionsTemplate}></Column>
        </StyledInvoiceDataTable>
      </div>
    </div>
  );
}

const StyledInvoiceDataTable = styled(DataTable)`
  td:first-child {
    width: 10%;
  }
  td:nth-child(2) {
    width: 10%;
  }
  td:nth-child(7) {
    width: 20%;
  }
`;

export default ManualInvoice;
