import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SideBox } from "../../components/FormLayout/SideBoxSection/index";
import { handlePostRequest } from "../../services/PostTemplate";
import { getrtaDetails } from "../../redux/actions/rtaAction";
import { AiFillPlusCircle } from "react-icons/ai";
import { Dialog } from "primereact/dialog";
import { TabPanel, TabView } from "primereact/tabview";
import PassengerClaimantDetails from "./rtaFormComponents/passengers/PassengerClaimantDetails";
import PassengerLitigationFriend from "./rtaFormComponents/passengers/PassengerLitigationFriend";
import PassengerInjuryInfo from "./rtaFormComponents/passengers/PassengerInjuryInfo";
import { useFormik } from "formik";
import { passengerKeys, passengerValidationSchema } from "./RtaConstants";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

function RtaAddPassengers() {
  const { rta } = useSelector((state) => state.rtaSlice);
  const dispatch = useDispatch();
  const [showPassengerModal, setShowPassengerModal] = useState(false);
  const [isLitigationFriend, setIsLitigationFriend] = useState(false);
  const navigate = useNavigate();
  const claimantAdress = {
    city: rta?.city,
    region: rta?.region,
    postalcode: rta?.postalcode,
    address1: rta?.address1,
    address2: rta?.address2,
    address3: rta?.address3,
    postcode: rta?.postalcode,
  };
  const claimantInfo = {
    title: rta?.title,
    dob: rta?.dob,
    firstname: rta?.firstname,
    lastname: rta?.lastname,
    middlename: rta?.middlename,
    email: rta?.email,
    phone: rta?.phone,
    address: claimantAdress,
    mobile: rta?.mobile,
    landline: rta?.landline,
  };
  const footer = (
    <div>
      <button
        type="submit"
        className="btn btn-primary"
        onClick={() => formik.handleSubmit()}
      >
        Submit
      </button>
    </div>
  );

  const handleLitigationFriend = (isShow) => {
    formik.setFieldValue("minor", isShow);
    setIsLitigationFriend(isShow);
  };

  const formik = useFormik({
    validationSchema: passengerValidationSchema,
    initialValues: passengerKeys,
    onSubmit: async (data) => {
      try {
        const passengerData = {};
        Object.keys(data).forEach((key) => {
          if (data[key] === true) {
            data[key] = "Y";
          }
          if (data[key] === false) {
            data[key] = "N";
          }
          if (data[key] === "") {
            data[key] = null;
          }
        });
        passengerData["passengers"] = [data];
        passengerData["rtacode"] = rta.rtacode;

        await handlePostRequest(passengerData, "rta/addNewPassengersToRtaCase");
        dispatch(getrtaDetails(rta.rtacode));
        setShowPassengerModal(false);
        formik.resetForm();
      } catch (err) {
        console.log(err);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    classNames: classNames,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
  };

  return (
    <>
      <SideBox title="Passengers" className="mt-4">
        <div onClick={() => setShowPassengerModal(true)}>
          <AiFillPlusCircle color="green" size="20px" className="p-ml-2" />
        </div>
        {rta?.passengerRtaClaims?.length > 0 && (
          <table>
            <tr></tr>
            {rta?.passengerRtaClaims?.map((psngr) => (
              <tr>
                <td>{psngr?.name}</td>
                <td style={{ color: "green" }}>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`../rtadetails/${psngr?.rtacode}`);
                    }}
                  >
                    {psngr?.rtanumber}
                  </div>
                </td>
                <td>{psngr?.status}</td>
              </tr>
            ))}
          </table>
        )}
      </SideBox>
      <Dialog
        header="Passengers Form"
        footer={footer}
        visible={showPassengerModal}
        style={{ width: "65%" }}
        onHide={() => {
          setShowPassengerModal(false);
          formik.resetForm();
        }}
      >
        <TabView className="mt-2">
          <TabPanel
            style={{ fontSize: "12px" }}
            renderActiveOnly={false}
            header="Personal Details"
          >
            <div className="row">
              <div className="col-md-6">
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <PassengerClaimantDetails
                    claimantAdress={claimantAdress}
                    handleLitigationFriend={handleLitigationFriend}
                    {...props}
                  />
                </div>
              </div>
              {isLitigationFriend && (
                <div className="col-md-6">
                  <div className="p-fluid p-formgrid p-grid p-pr-4">
                    <PassengerLitigationFriend
                      claimantInfo={claimantInfo}
                      {...props}
                    />
                  </div>
                </div>
              )}
            </div>
            <hr className="border__hr" />
          </TabPanel>
          <TabPanel
            style={{ fontSize: "12px" }}
            renderActiveOnly={false}
            header="Injury Info"
          >
            <div className="row">
              <div className="col-md-6">
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <PassengerInjuryInfo
                    isDriver={rta?.driverpassenger}
                    {...props}
                  />
                </div>
              </div>
            </div>
            <hr className="border__hr" />
          </TabPanel>
        </TabView>
      </Dialog>
    </>
  );
}

export default RtaAddPassengers;
