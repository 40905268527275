import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import { Button } from "primereact/button";
import { handlePostRequest } from "../../services/PostTemplate";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";

function AddLinesForm({ invoiceheadid, setIsShow, getInvoiceDetails }) {
  const validationSchema = Yup.object().shape({
    adjust: Yup.string().required("Required"),
    charge: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  });

  const initialValues = {
    adjust: "",
    charge: "",
    description: "",
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (data) => {
      data["invoiceheadid"] = invoiceheadid;
      await handlePostRequest(data, "invoicing/addManualInvoiceLine");
      setIsShow(false);
      getInvoiceDetails();
      formik.resetForm();
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-col-12 p-md-12 mt-4">
            <label htmlFor="status">Description</label>
            <InputTextarea
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("description"),
              })}
            />
            {getFormErrorMessage("description")}
          </div>
          <div className="p-col-12 p-md-12">
            <label htmlFor="status">Adjust</label>
            <InputText
              type="number"
              min={0}
              id="adjust"
              name="adjust"
              value={formik.values.adjust}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("adjust"),
              })}
            />
            {getFormErrorMessage("adjust")}
          </div>
          <div className="p-col-12 p-md-12">
            <label htmlFor="status">Charge</label>
            <InputText
              type="number"
              min={0}
              id="charge"
              name="charge"
              value={formik.values.charge}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("charge"),
              })}
            />
            {getFormErrorMessage("charge")}
          </div>
        </div>
        <div className="p-col-12 p-md-12 mt-4">
          <Button type="submit" className="btn btn-primary mr-2">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddLinesForm;
