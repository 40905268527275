import { Dialog } from "primereact/dialog";
import React from "react";
import ESignAudit from "./ESignAudit";

function ESignAuditDialog({ showModal, onCloseModal, data }) {
  return (
    <Dialog
      header="ESign Audit"
      visible={showModal}
      style={{ width: "70vw" }}
      onHide={onCloseModal}
    >
      <ESignAudit data={data} />
    </Dialog>
  );
}

export default ESignAuditDialog;
