import React from "react";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import LaDropdown from "../../components/LaDropdown";
import UsersLaDropdown from "./UsersLaDropdown";
import { handlePostRequest } from "../../services/PostTemplate";

const ValidationSchema = Yup.object().shape({
  password: Yup.string().required("This field is required"),
  confirmpassword: Yup.string().when("password", {
    is: (val) => !!val,
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    ),
  }),
});

const ErrorMsg = ({ name, formik }) =>
  formik.touched[name] && formik.errors[name] ? (
    <small className="label__error_100 p-d-block">{formik.errors[name]}</small>
  ) : null;

function ChangeUserPassword() {
  const formik = useFormik({
    validationSchema: ValidationSchema,
    initialValues: {
      userId: "",
      password: "",
      confirmpassword: "",
    },
    onSubmit: async (values) => {
      try {
        await handlePostRequest(values, "userManagement/changeUserPassword");
      } catch (err) {
        console.log(err);
      } finally {
        formik.resetForm();
      }
    },
  });

  const { handleChange, handleBlur, values, errors } = formik;

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && formik.errors[name];
  };

  return (
    <form onSubmit={formik.handleSubmit} className="w-100">
      <div className="p-field">
        <label className="p-d-block">
          Users <span className="text-danger">*</span>
        </label>
        <UsersLaDropdown
          keyName="userId"
          placeholder="Select User to update password"
          url="/getAllUsers"
          onChange={handleChange}
          values={values}
          isFormFieldValid={isFormFieldValid}
          getFormErrorMessage={getFormErrorMessage}
        />
      </div>

      <div className="p-field">
        <label>Password</label> <br />
        <InputText
          name="password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password || ""}
          type="password"
          placeholder="Enter a new password"
          className={errors.password ? "p-invalid p-d-block w-100" : "w-100"}
        />
        <ErrorMsg name="password" formik={formik} />
      </div>

      <div className="p-field ">
        <label>Confirm Password</label> <br />
        <InputText
          name="confirmpassword"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.confirmpassword || ""}
          type="password"
          placeholder="Re enter a new password"
          className={
            errors.confirmpassword ? "p-invalid p-d-block w-100" : "w-100"
          }
        />
        <ErrorMsg name="confirmpassword" formik={formik} />
      </div>

      <center>
        <Button
          type="submit"
          className="btn btn-primary"
          label={"Update Password"}
        />
      </center>
    </form>
  );
}

export default ChangeUserPassword;
