import React from "react";
import "./LargeTab.css";

function LargeTab({ Icon, title, count, active, onClick }) {
  return (
    <div className={`utils-component ${active && "active"}`} onClick={onClick}>
      <div className="icon_container">
        <span className="icon">{Icon && <Icon />}</span>
        <span className="text">{title}</span>
      </div>
      <div>{count ? count : null}</div>
    </div>
  );
}

function LargeTabWrapper(props) {
  return <div className="form-header">{props.children}</div>;
}

export { LargeTab, LargeTabWrapper };
