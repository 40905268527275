import React from "react";
import { BiEdit } from "react-icons/bi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FaRegTimesCircle } from "react-icons/fa";
import "./FormMainBody.css";

export default function Index({
  title,
  children,
  disabledForm,
  isEdit,
  setIsEdit,
  onSave,
  onCancel,
  editFlag,
}) {
  return (
    <>
      <div disabledForm={disabledForm} className="row p-2 mt-4 panel">
        <div className="panel-header text-semibold">
          {title}
          <div className="panel-right">
            {editFlag && editFlag === "Y" ? (
              <>
                {isEdit ? (
                  <div className="actions">
                    <span onClick={() => onSave()}>
                      <AiOutlineCheckCircle />
                      &nbsp; Save
                    </span>
                    <span onClick={onCancel}>
                      <FaRegTimesCircle />
                      &nbsp; Cancel
                    </span>
                  </div>
                ) : (
                  <span onClick={() => setIsEdit(true)}>
                    <BiEdit />
                    Edit
                  </span>
                )}
              </>
            ) : null}
          </div>
        </div>
        <hr />
        {children}
      </div>
    </>
  );
}
