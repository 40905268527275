import React, { useState } from "react";
import styled from "styled-components";
import { AiOutlinePlayCircle } from "react-icons/ai";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import DeleteMedia from "./DeleteMedia";

const VideoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
`;

const ThumbnailImage = styled.img`
  width: 150px;
  height: auto;
  display: block;
`;

const PlayIcon = styled(AiOutlinePlayCircle)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  color: rgba(255, 255, 255, 0.8);
  opacity: 0;
  transition: opacity 0.3s ease;
`;

const ThumbnailOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

const ThumbnailContainer = styled.div`
  position: relative;
  overflow: hidden;
  &:hover {
    ${PlayIcon}, ${ThumbnailOverlay} {
      opacity: 1;
    }
  }
`;

const DocumentName = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 14px;
  text-align: center;
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

function DocumentVideos({
  documentList,
  documentType,
  deleteApi,
  onDeleteDocument,
}) {
  const [openVideoIndex, setOpenVideoIndex] = useState(null);

  if (!documentList?.length) {
    return null;
  }

  const videoSources = documentList.filter(
    (item) => item?.doctype === "video/mp4"
  );

  if (!videoSources.length) {
    return null;
  }

  const handleVideoClick = (index) => {
    setOpenVideoIndex(index); // Update openVideoIndex with the clicked index
  };

  const handleCloseLightbox = () => {
    setOpenVideoIndex(null);
  };

  return (
    <>
      <p>Videos</p>
      <VideoGrid>
        {videoSources.map((item, index) => (
          <ThumbnailWrapper key={index}>
            {/* Add DeleteMedia component here */}
            <DeleteMedia
              documentId={item[documentType]} // Adjust the documentId as per your requirement
              deleteApi={deleteApi} // Pass deleteApi function
              onDeleteDocument={onDeleteDocument} // Pass onDeleteDocument function
            />
            <ThumbnailContainer onClick={() => handleVideoClick(index)}>
              <ThumbnailImage
                src={
                  "https://www.crowdcontent.com/blog/wp-content/uploads/sites/16/video-take.png"
                }
                alt={`Video ${index}`}
              />
              <ThumbnailOverlay>
                <PlayIcon />
              </ThumbnailOverlay>
              <DocumentName>{item?.docName || "Video"}</DocumentName>
            </ThumbnailContainer>
          </ThumbnailWrapper>
        ))}
      </VideoGrid>
      <Lightbox
        plugins={[Captions, Fullscreen, Slideshow, Video, Zoom]}
        video={{
          playsInline: false,
          autoPlay: true,
          loop: false,
          disablePictureInPicture: true,
          preload: "metadata",
        }}
        open={openVideoIndex !== null}
        index={openVideoIndex}
        close={handleCloseLightbox}
        slides={videoSources.map((item, index) => ({
          // Add index parameter to map function
          type: "video",
          width: 1280,
          height: 720,
          sources: [
            {
              src:
                item?.documentPath?.replaceAll("\\", "//") ||
                item?.docurl?.replaceAll("\\", "//") ||
                item?.docbase64,
              type: "video/mp4",
            },
          ],
        }))}
      />
    </>
  );
}

export default DocumentVideos;
