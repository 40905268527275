import React from "react";
import styled from "styled-components";

function ModesInfo() {
  return (
    <Container>
      <StyledInformation>Choose one of the following modes</StyledInformation>
    </Container>
  );
}

const Container = styled.div`
  margin: 1rem;
  width: 100%;
  display: flex;
  margin: 20px 0;
`;

const StyledInformation = styled.h3`
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export default ModesInfo;
