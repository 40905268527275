import { getMessages as gMessages } from "../../services/Messages";
import { GETMESSAGESSUCCESS, GETMESSAGESERROR } from "../slices/messageSlice";

export const getMessages = (code, url) => async (dispatch) => {
  const res = await gMessages(code, url);
  if (res) {
    dispatch(GETMESSAGESSUCCESS(res));
    return res;
  } else {
    dispatch(GETMESSAGESERROR(res));
  }
};
