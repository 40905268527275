import React from "react";
import { Button } from "primereact/button";
import * as XLSX from "xlsx";

function HireExcel({ hire }) {
  const exportHireToExcel = () => {
    const hireWithCircumcode = {
      ...hire,
      circumcode: hire?.circumcode?.circumcode,
      circumname: hire?.circumcode?.circumname,
      ccreatedon: hire?.circumcode?.createdon,
      descr: hire?.circumcode?.descr,
      remarks: hire?.circumcode?.remarks,
      status: hire?.circumcode?.status,
    };

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([hireWithCircumcode]);
    XLSX.utils.book_append_sheet(wb, ws, "Hire Data");
    XLSX.writeFile(wb, `${hire?.hirenumber}.xlsx`);
  };

  return (
    <Button
      onClick={exportHireToExcel}
      className="btn btn-primary"
      style={{
        color: "white",
        fontWeight: "bold",
      }}
    >
      Export to Excel
    </Button>
  );
}

export default HireExcel;
