import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import LaInput from "../LaInput";
import axios from "axios";
import { toast } from "react-toastify";
import "./LaAddress.css";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import styled from "styled-components";
import MapAddress from "./MapAddress";

export const LoqateKey = "AJ81-KC26-TE99-JP18";

function LaAddress({
  onChange,
  values,
  isFormFieldValid,
  getFormErrorMessage,
  keyNamePostcode,
  placeholder,
  keyNameCity,
  keyNameRegion,
  keyNameAddress1,
  keyNameAddress2,
  keyNameAddress3,
  formik,
  disabled,
}) {
  const [addressItems, setAddressItems] = useState([]);
  const [addressItemsValue, setAddressItemsValue] = useState();
  const [isShowSelectAddress, setIsShowSelectAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    disabled,
  };

  const handleValidations = (postcode) => {
    if (postcode === "" || postcode === undefined) {
      toast.error("Please enter postcode");
      return false;
    }
    if (postcode.length < 2) {
      toast.error("Please enter valid postcode");
      return false;
    }
    return true;
  };

  const findAddress = async (postcode, type = null) => {
    const res = handleValidations(postcode);
    if (!res) return;
    setIsLoading(true);
    setIsShowSelectAddress(true);
    clearForm();

    try {
      const formData = new FormData();
      formData.append("Key", LoqateKey);
      formData.append("Text", postcode);
      formData.append("IsMiddleware", false);
      formData.append("Container", type === "Container" ? postcode : "");
      formData.append("Origin", "");
      formData.append("Countries", "GBR");
      formData.append("Limit", "10");
      formData.append("Language", "en-gb");

      const response = await axios({
        method: "post", // Change method to 'post'
        url: "https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.10/json3.ws",
        data: formData, // Use formData instead of params
        headers: {
          "Content-Type": "multipart/form-data", // Use 'multipart/form-data' for form data
        },
      });

      setAddressItems(response.data.Items);
    } catch (error) {
      toast(error.response);
    } finally {
      setIsLoading(false);
    }
  };

  const retrieveAddress = async (id) => {
    setIsLoading(true);
    try {
      const response = await axios({
        method: "post",
        url: "https://services.postcodeanywhere.co.uk/Capture/Interactive/Retrieve/v1.00/json3.ws",
        params: {
          Key: LoqateKey,
          Id: id,
          Field1Format: "",
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      formik.setFieldValue(keyNameCity, response.data.Items[0].City);
      formik.setFieldValue(keyNameAddress1, response.data.Items[0].Line1);
      formik.setFieldValue(keyNameAddress2, response.data.Items[0].Line2);
      formik.setFieldValue(keyNameAddress3, response.data.Items[0].Line3);
      formik.setFieldValue(keyNameRegion, response.data.Items[0].Province);
    } catch (error) {
      toast(error.response);
    } finally {
      setIsLoading(false);
    }
  };

  const hanleAddressValue = (e) => {
    setAddressItemsValue(e.value);
    if (e.value.Type === "Address") {
      retrieveAddress(e.value.Id);
    } else {
      findAddress(e.value.Id, "Container");
    }
  };

  const clearForm = () => {
    formik.setFieldValue(keyNameCity, "");
    formik.setFieldValue(keyNameAddress1, "");
    formik.setFieldValue(keyNameAddress2, "");
    formik.setFieldValue(keyNameAddress3, "");
    formik.setFieldValue(keyNameRegion, "");
  };

  return (
    <>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Address <span className="text-danger">*</span>
        </label>
        <div className="p-inputgroup la__inputGroup">
          <>
            <InputText
              id={keyNamePostcode}
              placeholder={placeholder}
              name={keyNamePostcode}
              value={values[keyNamePostcode]}
              onChange={onChange}
              className={classNames({
                "p-invalid": isFormFieldValid(keyNamePostcode.toString()),
              })}
            />
          </>
          <OptionButtonContainer>
            {isLoading ? (
              <Button
                className="la__btnGroup"
                loading
                type="button"
                loadingIcon="pi pi-spin pi-sun"
              >
                Fetching ...
              </Button>
            ) : (
              <Button
                className="la__btnGroup"
                type="button"
                onClick={() => {
                  findAddress(values[keyNamePostcode]);
                }}
              >
                Lookup
              </Button>
            )}
          </OptionButtonContainer>
        </div>
        {getFormErrorMessage(keyNamePostcode.toString())}
      </div>
      {isShowSelectAddress && (
        <div className="p-col-12 p-mt-2">
          <Dropdown
            onChange={(e) => {
              hanleAddressValue(e);
            }}
            value={addressItemsValue || ""}
            options={addressItems}
            placeholder="Select"
            optionLabel={"Text"}
            className="Width__100"
          />
        </div>
      )}

      <div className="p-col-12 p-mt-2">
        <LaInput
          keyName={keyNameAddress1}
          placeholder="address line 1"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <LaInput
          keyName={keyNameAddress2}
          placeholder="address line 2"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <LaInput
          keyName={keyNameAddress3}
          placeholder="address line 3"
          {...props}
        />
      </div>

      <div className="p-col-6 p-mt-2">
        <LaInput keyName={keyNameCity} placeholder="city" {...props} />
      </div>

      <div className="p-col-6 p-mt-2">
        <LaInput keyName={keyNameRegion} placeholder="region" {...props} />
      </div>

      <MapAddress
        address={[
          values[keyNameAddress1],
          values[keyNameAddress2],
          values[keyNameAddress3],
          values[keyNameCity],
          values[keyNameRegion],
        ]}
      />
    </>
  );
}

const Button = styled.button`
  position: relative;
  height: 33px;
  width: 100%;
  padding-right: 25px; /* Add padding to accommodate the icon */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0px !important;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border-top: 6px solid #000;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
`;

const OptionButtonContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export default LaAddress;
