import React from "react";
import LaInput from "../../../components/LaInput";
import LaInputTextArea from "../../../components/LaInputTextArea";

function MedicalAttention({
  classNames,
  isFormFieldValid,
  values,
  onChange,
  formik,
  getFormErrorMessage,
}) {
  const props = {
    isFormFieldValid,
    onChange,
    values,
    getFormErrorMessage,
  };

  return (
    <>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Date of Medical Attention<span className="text-danger">*</span>
        </label>
        <LaInput
          keyName="medicalattenddate"
          placeholder="Date of Medical Attention"
          type="date"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">GP Details</label>
        <LaInputTextArea
          keyName="gpdetails"
          placeholder="GP Details"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Hospital Details</label>
        <LaInputTextArea
          keyName="hospitaldetails"
          placeholder="Hospital Details"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Injuries</label>
        <LaInputTextArea keyName="injuries" placeholder="Injuries" {...props} />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Time off work (days)</label>
        <LaInput
          keyName="timeoffwork"
          placeholder="Time off work (days)"
          {...props}
        />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Still off work</label>
        <LaInput
          keyName="stilloffwork"
          placeholder="Still off work"
          {...props}
        />
      </div>
    </>
  );
}

export default MedicalAttention;
