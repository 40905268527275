import React from "react";
import styled from "styled-components";

const theme = {
  primaryColor: "#094a99",
  secondaryColor: "#d6e0ff",
  // light grey color
  borderColor: "#d9d9d9",
};

const ButtonModeSelector = ({ icon, label, onClick, size }) => {
  return (
    <Container onClick={onClick} size={size} className="p-shadow-2">
      <Label>{label}</Label>
      {React.cloneElement(icon, { size: size || 30 })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  padding: 15px;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid ${theme.borderColor};
  transition: background-color 0.3s, border-color 0.3s;
  margin-bottom: 1rem;

  &:hover {
    background-color: ${theme.secondaryColor};
    border-color: ${theme.primaryColor};
    cursor: pointer;
  }

  svg {
    width: ${({ size }) => (size ? `${size}px` : "30px")};
    height: ${({ size }) => (size ? `${size}px` : "30px")};
  }
`;

const Label = styled.span`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${theme.primaryColor};
`;

export default ButtonModeSelector;
