import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import ProfileForm from "./ProfileForm";
import validationSchema, { initialValues } from "./ProfileConstants";
import { handleGetRequest } from "../../../services/GetTemplate";
import EditButton from "./EditButton";
import { Button } from "primereact/button";
import { handlePostRequest } from "../../../services/PostTemplate";

function EditCompanyProfile({ id }) {
  const [isEdit, setIsEdit] = useState(false);
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (data) => {
      Object.keys(data).forEach((key) => {
        if (data[key] === true) {
          data[key] = "Y";
        }
        if (data[key] === false) {
          data[key] = "N";
        }
        if (data[key] === "") {
          data[key] = null;
        }
      });
      console.log(data);
      data["companycode"] = id;
      // TODO: fix this at the backend later
      data["companystatus"] = {
        code: data.companystatus,
        name: data.companystatus === "Y" ? "Active" : "Inactive",
      };
      try {
        await handlePostRequest(data, "userManagement/updateCompanyProfile");
        setIsEdit(false);
        await getData();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
    disabled: !isEdit,
  };

  const fillFormFields = ({ ...data }) => {
    // To convert Y/N to true/false for radio buttons and checkboxes
    Object.keys(data).forEach((key) => {
      if (data[key] === "Y") {
        data[key] = true;
      }
      if (data[key] === "N") {
        data[key] = false;
      }
      if (data[key] === null) {
        data[key] = "";
      }
    });
    Object.keys(data).forEach((key) => {
      if (formik.initialValues.hasOwnProperty(key)) {
        formik.setFieldValue(key, data[key]);
      }
    });
    formik.setFieldValue(
      "userCategoryCode",
      data.tblUsercategory?.categorycode
    );
  };

  const getData = async () => {
    try {
      const res = await handleGetRequest("getCompaniesProfile/" + id);
      fillFormFields(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-4 d-flex justify-content-end">
        <EditButton isEdit={isEdit} setIsEdit={setIsEdit} formik={formik} />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <ProfileForm {...props} />
      </form>
    </>
  );
}

export default EditCompanyProfile;
