import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import { handleGetRequest } from "../../services/GetTemplate";

function UsersLaDropdown({
  onChange,
  values,
  isFormFieldValid,
  getFormErrorMessage,
  keyName,
  placeholder,
  url,
}) {
  const [options, setOptions] = useState("");
  const getDropdownOptions = async () => {
    const res = await handleGetRequest(url);
    const options = res.data.map((item) => {
      return {
        name: item.loginid + " ----- " + item.username,
        value: item.usercode,
      };
    });
    setOptions(options);
  };

  useEffect(() => {
    getDropdownOptions();
  }, []);
  return (
    <>
      <Dropdown
        virtualScrollerOptions={{ itemSize: 38 }}
        filter
        id={keyName}
        placeholder={placeholder}
        name={keyName}
        value={values[keyName]}
        options={options}
        onChange={onChange}
        optionLabel="name"
        className={
          (classNames({
            "p-invalid": isFormFieldValid(keyName.toString()),
          }),
          "Width__100")
        }
      />
      {getFormErrorMessage(keyName.toString())}
    </>
  );
}

export default UsersLaDropdown;
