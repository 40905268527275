import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import AffectedRoomModel from "../index";
import { formatDateToddmmyyy } from "../../../../utilities/commonFunctions";

function AffectedRoomTable({
  affectedRoom,
  handleRemoveAffected,
  handleUpdateAffected,
  isView,
  viewmode,
  disabledForm,
}) {
  const [affected, setAffected] = useState();
  const [displayBasic, setDisplayBasic] = useState(false);

  const formatLastReported = (rowData) => {
    return rowData?.lastReported
      ? formatDateToddmmyyy(new Date(rowData.lastReported))
      : rowData?.lastReported;
  };

  const actionTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          onClick={() => handleEditAffected(rowData)}
          className="p-button-rounded p-button-text p-mr-2"
        />
        <Button
          icon="pi pi-trash"
          onClick={() => handleRemoveAffected(rowData?.id)}
          className="p-button-rounded p-button-text"
        />
      </React.Fragment>
    );
  };
  const actionTemplateView = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          onClick={() => handleEditAffected(rowData)}
          className="p-button-rounded p-button-success p-mr-2"
        />
      </React.Fragment>
    );
  };
  const handleEditAffected = (affected) => {
    setAffected(affected);
    setDisplayBasic(true);
  };

  return (
    <div className="card">
      {affectedRoom?.length > 0 && (
        <DataTable value={affectedRoom}>
          <Column
            field="roomName"
            header="Affected Room"
            sortable
            filterMatchMode="contains"
          ></Column>
          <Column
            field="lastReported"
            header="Last Reported"
            sortable
            filterMatchMode="contains"
            body={formatLastReported}
          ></Column>
          <Column
            field="reportDetails"
            header="Report Details"
            sortable
            filterMatchMode="contains"
          ></Column>
          <Column
            field="disrepairDetail"
            header="Disrepair Details"
            sortable
            filterMatchMode="contains"
          ></Column>
          <Column
            field="personalPropertydamage"
            header="Personal Property Damage?"
            sortable
            filterMatchMode="contains"
          ></Column>
          <Column
            field="damageList"
            header="Damage detail"
            sortable
            filterMatchMode="contains"
          ></Column>
          {isView === true ? (
            <Column
              body={actionTemplateView}
              header="Action"
              filterMatchMode="contains"
            ></Column>
          ) : (
            <Column
              body={actionTemplate}
              header="Action"
              filterMatchMode="contains"
            ></Column>
          )}
        </DataTable>
      )}
      <AffectedRoomModel
        disabledForm={disabledForm}
        viewmode={isView || viewmode}
        show={displayBasic}
        hide={setDisplayBasic}
        affected={affected}
        handleAffectedReturn={handleUpdateAffected}
        isEdit={true}
      />
    </div>
  );
}

export default AffectedRoomTable;
