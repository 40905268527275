import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { handleGetRequest } from "../../services/GetTemplate";
import { handlePostRequest } from "../../services/PostTemplate";
import { Column } from "primereact/column";
import { FiSettings } from "react-icons/fi";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { Chip } from "primereact/chip";

function InvoiceFilter() {
  const [invoiceList, setInvoiceList] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [solicitorOptions, setSolicitorOptions] = useState([]);
  const [solicitorValue, setSolicitorValue] = useState("");
  const [statusOptions, setStatusOptions] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [invoiceCode, setInvoiceCode] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const navigate = useNavigate();

  const getInvoice = async (status) => {
    const res = await handleGetRequest(`invoicing/getInvoices`);
    setInvoiceList(res.data);
  };

  const getSolicitor = async () => {
    const res = await handleGetRequest(`lovSolicitorsForHdr`);
    setSolicitorOptions(res.data);
  };

  const getStatus = async () => {
    const res = await handleGetRequest(`lovInvoicingStatus`);
    setStatusOptions(res.data);
  };

  const actionsTemplate = ({ invoiceheadid }) => {
    return (
      <div
        title="Details"
        className="pointer"
        onClick={() => {
          navigate(`../invoiceDetails?invoicecode=${invoiceheadid}`);
        }}
      >
        <FiSettings />
      </div>
    );
  };

  const statusbody = (rowData) => {
    return <Chip label={rowData?.status} className="p-px-4 custom-chip" />;
  };

  useEffect(() => {
    getInvoice();
    getSolicitor();
    getStatus();
  }, []);

  const onGlobalFilterChange = (e = false) => {
    const keys = [
      "compaigntype",
      "invoicecode",
      "username",
      "createdon",
      "amount",
      "statuscode",
    ];
    const value = e?.target?.value;
    if (value) {
      const filteredList = invoiceList.filter((item) => {
        let flag = false;
        keys.forEach((key) => {
          if (
            key &&
            item[key] &&
            item[key]
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
            flag = true;
        });
        if (flag) return item;
      });
      setFiltered(filteredList);
    } else {
      setFiltered(invoiceList);
    }
  };

  useEffect(() => {
    onGlobalFilterChange();
  }, [invoiceList]);

  const renderHeader2 = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <InputText onChange={onGlobalFilterChange} placeholder="Search" />
        </span>
      </div>
    );
  };
  const header2 = renderHeader2();

  const codeTemplate = (rowData) => {
    const issueData = new Date(rowData.createdon);
    // get today date and minus issue data
    const today = new Date();
    const diff = today.getTime() - issueData.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    return (
      <div>
        <span>{rowData?.invoicecode}</span>
        <p style={{ color: "gray" }}>{days} day(s) ago</p>
      </div>
    );
  };

  const descriptionTemplate = ({ createdon }) => {
    // Add two days into createdon
    const date = new Date(createdon);
    date.setDate(date.getDate() + 2);

    // dd/mm/yyyy to yyyy-mm-dd
    const dateArr = date.toLocaleDateString().split("/");
    const dueDate = `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;

    return (
      <div>
        <span>Issue Date : {createdon}</span> &nbsp;
        <span>Due Date : {dueDate}</span>
      </div>
    );
  };

  const handleSearch = async () => {
    const data = {
      casenumber: companyCode,
      status: statusValue.code,
      invoicecode: invoiceCode,
      companycode: solicitorValue.code,
    };

    const res = await handlePostRequest(data, `invoicing/invoiceSearch`);
    console.log(res);
    if (res.status === 200) setFiltered(res?.data || []);
  };

  return (
    <div>
      <div className="card mb-4">
        <div
          className="p-fluid p-formgrid p-grid"
          style={{
            paddingLeft: "15px",
            paddingTop: "15px",
            paddingRight: "15px",
            paddingBottom: "15px",
          }}
        >
          <div className="p-col-3 p-mb-2">
            <label className="p-d-block">Invoice No.</label>
            <InputText
              placeholder="Invoice No."
              value={invoiceCode}
              onChange={(e) => setInvoiceCode(e.target.value)}
            />
          </div>
          <div className="p-col-3 p-mb-2">
            <label className="p-d-block">Solicitor</label>
            <Dropdown
              optionLabel="name"
              options={solicitorOptions}
              value={solicitorValue}
              onChange={(e) => {
                setSolicitorValue(e.value);
              }}
              placeholder="Select a Name"
              className="dropdown__width__100 "
            />
          </div>
          <div className="p-col-3 p-mb-2">
            <label className="p-d-block">Status</label>
            <Dropdown
              optionLabel="name"
              options={statusOptions}
              value={statusValue}
              onChange={(e) => {
                setStatusValue(e.value);
              }}
              placeholder="Select a Status"
              className="dropdown__width__100 "
            />
          </div>
          <div className="p-col-3 p-mb-2">
            <label className="p-d-block">Claim Code</label>
            <InputText
              placeholder="Claim Code"
              value={companyCode}
              onChange={(e) => setCompanyCode(e.target.value)}
            />
          </div>
        </div>
        <div align="center">
          <button className="btn btn-primary" onClick={handleSearch}>
            {" "}
            Search
          </button>
        </div>
      </div>

      <div className="card">
        <DataTable
          value={filtered || []}
          showGridlines
          responsiveLayout="scroll"
          header={header2}
        >
          <Column field="compaigntype" sortable header="Source"></Column>
          <Column
            field="invoicecode"
            sortable
            body={codeTemplate}
            header="Code"
          ></Column>
          <Column field="username" sortable header="Bill to"></Column>
          <Column
            field="createdon"
            body={descriptionTemplate}
            sortable
            header="Description"
          ></Column>
          <Column field="amount" sortable header="Amount"></Column>
          <Column
            field="statuscode"
            body={statusbody}
            sortable
            header="Status"
          ></Column>
          <Column header="Acts" body={actionsTemplate}></Column>
        </DataTable>
      </div>
    </div>
  );
}

export default InvoiceFilter;
