import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import "./DataTable.css";
import styled from "styled-components";

const CenteredContainer = styled.div`
  /* Add styles to center the expanded card */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeaderRow = styled.tr`
  background-color: #f0f0f0;
`;

const TableHeader = styled.th`
  padding: 10px;
  font-weight: bold;
`;

const TableDataRow = styled.tr``;

const TableData = styled.td`
  padding: 10px;
`;

const EditViewJobsData = ({ editRow, companyJobsData, viewMode }) => {
  const [jobsData, setjobsData] = useState(null);
  const dt = useRef(null);
  const [expandedRows, setExpandedRows] = useState();

  useEffect(() => {
    setjobsData(companyJobsData);
  }, [companyJobsData]);

  const compaignfeeBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Compaign</span>
        {rowData.tblCompaign.compaignname}
      </React.Fragment>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        {rowData.status === "Y" ? (
          <Badge value="Active" severity="success" className="p-mr-2"></Badge>
        ) : (
          <Badge value="Inactive" severity="warning" className="p-mr-2"></Badge>
        )}
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-info p-button-text p-button-text mr-2"
          onClick={() => editRow(rowData)}
        />
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-info p-button-text p-button-info"
          onClick={() => viewMode(rowData)}
        />
      </React.Fragment>
    );
  };

  const rowExpansionTemplate = (data) => {
    const hireFields = [
      { label: "Fault Repairs:", value: data?.faultrepairs },
      { label: "Prestige Roadworthy:", value: data?.prestigeroadworthy },
      { label: "Prestige Unroadworthy:", value: data?.prestigeunroadworthy },
      { label: "Recovery:", value: data?.recovery },
      { label: "Repairs:", value: data?.repairs },
      { label: "Salvage:", value: data?.salvage },
      { label: "Standard Roadworthy:", value: data?.standardroadworthy },
      { label: "Standard Unroadworthy:", value: data?.standardunroadworthy },
    ];

    const rtaFields = [
      { label: "Bike:", value: data?.bike },
      { label: "Hybrid:", value: data?.hybrid },
      { label: "Pedestrian:", value: data?.pedestrian },
      { label: "Scottish RTA:", value: data?.scotishRta },
      { label: "Wiplash:", value: data?.wiplash },
      { label: "Minor:", value: data?.minor },
    ];
    const hdrFields = [
      { label: "Solicitors Fees:", value: data?.solicitorsfees },
    ];

    return (
      <CenteredContainer>
        <TableContainer>
          <thead>
            <TableHeaderRow>
              <TableHeader>Hire Fields</TableHeader>
              <TableHeader>Value</TableHeader>
            </TableHeaderRow>
          </thead>
          <tbody>
            {hireFields.map((field, index) => (
              <TableDataRow key={index}>
                <TableData>{field.label}</TableData>
                <TableData>{field.value}</TableData>
              </TableDataRow>
            ))}

            {/* Add a separator row between the hireFields and rtaFields */}
            <tr>
              <td colSpan="2">&nbsp;</td>
            </tr>

            <TableHeaderRow>
              <TableHeader>RTA Fields</TableHeader>
              <TableHeader>Value</TableHeader>
            </TableHeaderRow>

            {rtaFields.map((field, index) => (
              <TableDataRow key={index}>
                <TableData>{field.label}</TableData>
                <TableData>{field.value}</TableData>
              </TableDataRow>
            ))}
          </tbody>

          <tbody>
            <tr>
              <td colSpan="2">&nbsp;</td>
            </tr>

            <TableHeaderRow>
              <TableHeader>HDR Fields</TableHeader>
              <TableHeader>Value</TableHeader>
            </TableHeaderRow>

            {hdrFields.map((field, index) => (
              <TableDataRow key={index}>
                <TableData>{field.label}</TableData>
                <TableData>{field.value}</TableData>
              </TableDataRow>
            ))}
          </tbody>
        </TableContainer>
      </CenteredContainer>
    );
  };

  return (
    <div className="datatable-filter-demo">
      <div className="card p-datatable-sm">
        <DataTable
          ref={dt}
          value={jobsData}
          paginator
          rows={10}
          className="p-datatable-customers"
          emptyMessage="No data found."
          expandedRows={expandedRows}
          dataKey="companyjobcode"
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column
            expander
            style={{ width: "2.5rem" }}
            filterMatchMode="contains"
          ></Column>
          <Column
            field="compaigndescr"
            header="Compaign"
            body={compaignfeeBodyTemplate}
            filter
            sortable
            filterMatchMode="contains"
          />

          <Column
            field="status"
            header="Status"
            body={statusBodyTemplate}
            filter
            sortable
            filterMatchMode="contains"
          />
          <Column body={actionBodyTemplate} filterMatchMode="contains"></Column>
        </DataTable>
      </div>
    </div>
  );
};
export default EditViewJobsData;
