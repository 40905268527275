import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import styled from "styled-components";

const StyledCard = styled.div`
  padding: 20px;
  background: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Heading = styled.h2`
  color: #3498db; /* Dodger Blue */
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

export default function VerticalBarDemo() {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "Legal Assistance Requests",
          backgroundColor: "#3498DB", // Dodger Blue
          borderColor: "#3498DB", // Dodger Blue
          data: [20, 35, 28, 45, 30, 42, 50],
        },
        {
          label: "Claims Assistance Requests",
          backgroundColor: "#E74C3C", // Alizarin
          borderColor: "#E74C3C", // Alizarin
          data: [15, 28, 18, 32, 25, 38, 40],
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            fontColor: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  return (
    <StyledCard>
      <Heading>Legal Assist Requests</Heading>
      <Chart type="bar" data={chartData} options={chartOptions} />
      <div className="text-center mt-4">
        <p>Legal Assistance Requests: January - July</p>
        <p>Claims Assistance Requests: January - July</p>
      </div>
    </StyledCard>
  );
}
