import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import classNames from "classnames";
import { handlePostRequest } from "../../services/PostTemplate";
import { useSelector } from "react-redux";
import { initialValues, validationSchema } from "./immigrationConstants";
import { InputTextarea } from "primereact/inputtextarea";

function Immigration({ edit, onSave, isSubmitFormik, handleReturnEdit }) {
  const { immigration } = useSelector((state) => state.immigrationSlice);

  useEffect(() => {
    if (isSubmitFormik) {
      formik.handleSubmit();
    }
  }, [isSubmitFormik]);

  useEffect(() => {
    if (edit) {
      fillFormFields();
    }
  }, [edit, immigration]);

  const fillFormFields = () => {
    Object.keys(immigration).forEach((key) => {
      if (formik.initialValues.hasOwnProperty(key)) {
        formik.setFieldValue(key, immigration[key]);
      }
    });
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (data) => {
      if (edit) {
        data["immigrationclaimcode"] = immigration?.immigrationclaimcode;
        await handlePostRequest(data, "immigration/updateImmigrationCase");
        handleReturnEdit();
      } else
        await handlePostRequest(data, "/immigration/addNewImmigrationCase");
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <>
      <div className="p-ml-4">
        {!edit ? (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h6
                style={{
                  color: "#428BCA",
                  fontWeight: "600",
                  lineHeight: "1.42857143",
                }}
              >
                Main Info
              </h6>
              <small style={{ color: "#999" }}>Enter main information</small>
            </div>
            <div>
              <Button
                type="submit"
                icon="pi pi-arrow-circle-right"
                iconPos="right"
                className="btn btn-primary"
                label="Next"
                onClick={formik.handleSubmit}
              ></Button>
            </div>
          </div>
        ) : (
          ""
        )}

        <hr className="border__hr" />
        <div>
          <form onSubmit={edit ? onSave : formik.handleSubmit}>
            <div className="col-md-6">
              <div className="p-fluid p-formgrid p-grid p-pr-4">
                <div className="Panel__Heading">Immigration</div>
                <div className="p-col-12 ">
                  <label className="p-d-block">Advice</label>
                  <InputText
                    id="advice"
                    name="advice"
                    type="text"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("advice"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.advice}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("advice")}
                </div>

                <div className="p-col-12 ">
                  <label className="p-d-block">Client Address</label>
                  <InputText
                    id="clientaddress"
                    name="clientaddress"
                    type="text"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("clientaddress"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.clientaddress}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("clientaddress")}
                </div>
                <div className="p-col-6 ">
                  <label className="p-d-block">Client Contact No</label>
                  <InputText
                    id="clientcontactno"
                    name="clientcontactno"
                    type="text"
                    className={classNames(
                      {
                        "p-inputtext-error":
                          isFormFieldValid("clientcontactno"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.clientcontactno}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("clientcontactno")}
                </div>
                <div className="p-col-6 ">
                  <label className="p-d-block">Client Email</label>
                  <InputText
                    id="clientemail"
                    name="clientemail"
                    type="text"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("clientemail"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.clientemail}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("clientemail")}
                </div>
                <div className="p-col-6 ">
                  <label className="p-d-block">Client Name</label>
                  <InputText
                    id="clientname"
                    name="clientname"
                    type="text"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("clientname"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.clientname}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("clientname")}
                </div>
                <div className="p-col-6 ">
                  <label className="p-d-block">Client Title</label>
                  <InputText
                    id="clienttitle"
                    name="clienttitle"
                    type="text"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("clienttitle"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.clienttitle}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("clienttitle")}
                </div>
                <div className="p-col-6 ">
                  <label className="p-d-block">Contact Time</label>
                  <InputText
                    id="contacttime"
                    name="contacttime"
                    type="time"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("contacttime"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.contacttime}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("contacttime")}
                </div>
                <div className="p-col-6 ">
                  <label className="p-d-block">E-Sign</label>
                  <InputText
                    id="esign"
                    name="esign"
                    type="text"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("esign"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.esign}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("esign")}
                </div>
                <div className="p-col-6 ">
                  <label className="p-d-block">E-Sign Date</label>
                  <InputText
                    id="esigndate"
                    name="esigndate"
                    type="date"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("esigndate"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.esigndate}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("esigndate")}
                </div>
                {/* immigrationcode */}
                <div className="p-col-6 ">
                  <label className="p-d-block">Immigration Code</label>
                  <InputText
                    id="immigrationcode"
                    name="immigrationcode"
                    type="text"
                    className={classNames(
                      {
                        "p-inputtext-error":
                          isFormFieldValid("immigrationcode"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.immigrationcode}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("immigrationcode")}
                </div>

                <div className="p-col-6 ">
                  <label className="p-d-block">Inquiry Date</label>
                  <InputText
                    id="inquirydatetime"
                    name="inquirydatetime"
                    type="date"
                    className={classNames(
                      {
                        "p-inputtext-error":
                          isFormFieldValid("inquirydatetime"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.inquirydatetime}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("inquirydatetime")}
                </div>

                <div className="p-col-6 ">
                  <label className="p-d-block">Nationality</label>
                  <InputText
                    id="nationality"
                    name="nationality"
                    type="text"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("nationality"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.nationality}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("nationality")}
                </div>

                <div className="p-col-12 ">
                  <label className="p-d-block">Inquiry Description</label>
                  <InputTextarea
                    id="inquirydescription"
                    name="inquirydescription"
                    type="text"
                    className={classNames(
                      {
                        "p-inputtext-error":
                          isFormFieldValid("inquirydescription"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.inquirydescription}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("inquirydescription")}
                </div>

                <div className="p-col-6 ">
                  <label className="p-d-block">Inquiry Nature</label>
                  <InputText
                    id="inquirynature"
                    name="inquirynature"
                    type="text"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("inquirynature"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.inquirynature}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("inquirynature")}
                </div>

                <div className="p-col-6 ">
                  <label className="p-d-block">Inquiry Referral</label>
                  <InputText
                    id="inquiryreferal"
                    name="inquiryreferal"
                    type="text"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("inquiryreferal"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.inquiryreferal}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("inquiryreferal")}
                </div>

                <div className="p-col-12 ">
                  <label className="p-d-block">Notes</label>
                  <InputTextarea
                    id="notes"
                    name="notes"
                    type="text"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("notes"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.notes}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("notes")}
                </div>
                <div className="p-col-12 ">
                  <label className="p-d-block">Remarks</label>
                  <InputText
                    id="remarks"
                    name="remarks"
                    type="text"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("remarks"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.remarks}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("remarks")}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Immigration;
