import {
  GETELSUCCESS,
  GETELERROR,
  GETELSTATUSSUCCESS,
  GETELSTATUSERROR,
  GETELLISTSUCCESS,
  GETELLISTERROR,
  CLEARELDATA,
} from "../slices/elSlice";
import { SETLOADING } from "../slices/loadingSlice";
import { handleGetRequest } from "../../services/GetTemplate";

export const getList = (statusUrl) => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handleGetRequest(statusUrl);
  if (res) {
    dispatch(GETELLISTSUCCESS(res?.data));
    dispatch(SETLOADING(false));
    return res;
  } else {
    dispatch(GETELLISTERROR(res?.data?.data));
    dispatch(SETLOADING(false));
  }
};

export const getElDetails = (id) => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handleGetRequest(`El/getElCaseById/${id}`);
  if (res) {
    dispatch(GETELSUCCESS(res?.data));
    dispatch(SETLOADING(false));
  } else {
    dispatch(GETELERROR(res?.data));
    dispatch(SETLOADING(false));
  }
};
export const getStatus = () => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handleGetRequest("/El/getAllElStatusCounts");
  if (res) {
    dispatch(GETELSTATUSSUCCESS(res?.data));
    dispatch(SETLOADING(false));
    return res;
  } else {
    dispatch(GETELSTATUSERROR(res));
    dispatch(SETLOADING(false));
  }
};

export const clearElData = () => async (dispatch) => {
  dispatch(CLEARELDATA());
};
