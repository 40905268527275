import { baseURL } from "../Config";
import axios from "axios";
import { toast } from "react-toastify";

export const hdrService = {
  getHdrList: async (statusUrl) => {
    let res;
    await axios({
      method: "GET",
      url: `${baseURL + statusUrl}`,
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.messages);
        res = false;
      });
    return res;
  },
  getHdrDetails: async (hdrCaseId) => {
    let res;
    await axios({
      method: "GET",
      url: `${baseURL}hdr/getHdrCaseById/${hdrCaseId}`,
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        if (err?.response?.status === 401 || err?.response?.status === 500) {
          toast.warn(err?.response?.data?.messages);
          localStorage.clear();
          window.location.href = "../";
        } else {
          toast.warn(err?.response?.data?.messages);

          res = false;
        }
      });
    return res;
  },

  getHdrStatus: async () => {
    let res;
    await axios({
      method: "GET",
      url: `${baseURL}/hdr/getAllHdrStatusCounts`,
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.messages);
        res = false;
      });
    return res;
  },
  postHdrDocs: async (docs, url) => {
    let res;
    await axios({
      method: "post",
      url: `${baseURL}${url || `hdr/uploadHdrDocs`}`,
      data: docs,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(() => {
        res = true;
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
        res = false;
      });
    return res;
  },
};
