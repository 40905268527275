import React from "react";
import LaInput from "../../../components/LaInput";
import LaCheckbox from "../../../components/LaCheckbox";
import LaInputTextArea from "../../../components/LaInputTextArea";

function DamagesInformation({
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
  };

  return (
    <>
      <div>
        <div className="Panel__Heading">Damages Information</div>
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Specify client’s injuries</label>
        <LaInputTextArea
          keyName="clientinjuries"
          placeholder="Client’s Injuries"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Any treatment received at the accident scene? Whom was it provided by?
        </label>
        <LaInput
          keyName="anytreatmentreceived"
          placeholder="Treatment Details"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Was an ambulance called at the scene?
        </label>
        <LaCheckbox keyName="ambulancecalled" {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Did the claimant suffer injuries straight away?
        </label>
        <LaCheckbox keyName="claimantsufferinjuries" {...props} />
      </div>
      {!values.claimantsufferinjuries && (
        <div className="p-col-12 p-mt-2">
          <label className="p-d-block">When did the symptoms start?</label>
          <LaInput
            keyName="injuriessymptomsstart"
            placeholder="Symptoms Start Date"
            {...props}
          />
        </div>
      )}
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Any pre-existing medical conditions?
        </label>
        <LaCheckbox keyName="anypremedicalconds" {...props} />
      </div>
      {values.anypremedicalconds && (
        <div className="p-col-12 p-mt-2">
          <label className="p-d-block">If yes, please specify details</label>
          <LaInputTextArea
            keyName="premedicalconddetail"
            placeholder="Pre-existing Medical Conditions"
            {...props}
          />
        </div>
      )}
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Any previous accident?</label>
        <LaCheckbox keyName="anypreviousacc" {...props} />
      </div>
      {values.anypreviousacc && (
        <div className="p-col-12 p-mt-2">
          <label className="p-d-block">If yes, specify details</label>
          <LaInputTextArea
            keyName="previousaccdetail"
            placeholder="Previous Accident Details"
            {...props}
          />
        </div>
      )}
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Has the client been advised not to work because of the injuries?
        </label>
        <LaCheckbox keyName="noworkadvised" {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          What is the client’s present condition?
        </label>
        <LaInputTextArea
          keyName="clientpresentcond"
          placeholder="Client’s Present Condition"
          {...props}
        />
      </div>
    </>
  );
}

export default DamagesInformation;
