import React from "react";
import { useFormik } from "formik";
import LaDropdown from "../../../../../components/LaDropdown";
import { handlePostRequest } from "../../../../../services/PostTemplate";
import { useState } from "react";
import { Button } from "primereact/button";
import * as Yup from "yup";
import LaDropdownLink from "../../../../../components/LaDropdown/LaDropdownLink";
import LaInputTextArea from "../../../../../components/LaInputTextArea";
import { getHireDetails } from "../../../../../redux/actions/hireAction";
import { useDispatch } from "react-redux";

function HireAssignForm({ buttonDetails, hireclaimcode, onCloseModal }) {
  const initialValues = {
    companyprofilecode: "",
    userCode: "",
    message: "",
  };

  const [loading, setLoading] = useState(false);
  const [loadingIcon, setLoadingIcon] = useState("");
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      companyprofilecode: Yup.string().required("required"),
      userCode: Yup.string().required("required"),
    }),
    onSubmit: async (data) => {
      try {
        setLoading(true);
        setLoadingIcon("pi pi-spin pi-spinner");
        data["statusCode"] = buttonDetails.buttonvalue;
        data["hireclaimcode"] = hireclaimcode;
        await handlePostRequest(data, "hire/assignCaseToBusiness");
        dispatch(getHireDetails(hireclaimcode));
        setLoadingIcon("");
        onCloseModal();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };
  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
  };

  return (
    <>
      <div className="p-grid p-fluid">
        <div className="p-col-12 p-md-12 p-mt-4">
          <label htmlFor="hireCompanyAssign">Hire company Assign</label>
          <LaDropdown
            keyName="companyprofilecode"
            optionLabel="name"
            placeholder="Select"
            {...props}
            url={"lovHireCompanies"}
          />
        </div>
        <div className="p-col-12 p-md-12">
          <label>Hire Company User</label>
          <LaDropdownLink
            {...props}
            linkId={formik.values.companyprofilecode}
            label="Hire Company User"
            required
            valueOption="code"
            keyName="userCode"
            placeholder="Select Hire Company User"
            optionLabel="name"
            url="lovCompanyWiseUSer"
          />
        </div>
        <div className="p-col-12 p-md-12">
          <label htmlFor="message">Message</label>
          <LaInputTextArea keyName="message" {...props} rows={5} cols={30} />
        </div>
      </div>
      <div className="p-d-flex p-jc-center p-mt-4">
        <Button
          icon={loadingIcon || ""}
          onClick={formik.handleSubmit}
          disabled={loading}
          label="Submit"
          className="btn-info btn-sm p-mr-2 p-mb-2"
        />
      </div>
    </>
  );
}

export default HireAssignForm;
