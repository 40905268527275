import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "authenticationSlice",
  initialState: {
    tenancy: {},
    tenancyList: [],
    tenancyStatus: [],
  },
  reducers: {
    GETTENANCYLISTSUCCESS: (state, action) => {
      return {
        ...state,
        tenancyList: action.payload,
      };
    },
    GETTENANCYLISTERROR: (state) => {
      return {
        ...state,
        tenancyList: [],
      };
    },
    GETTENANCYSUCCESS: (state, action) => {
      return {
        ...state,
        tenancy: action.payload,
      };
    },
    GETTENANCYERROR: (state) => {
      return {
        ...state,
        tenancy: {},
      };
    },
    GETTENANCYSTATUSSUCCESS: (state, action) => {
      return { ...state, tenancyStatus: action.payload };
    },
    GETTENANCYSTATUSERROR: (state) => {
      return { ...state, tenancyStatus: {} };
    },
  },
});

export const {
  GETTENANCYLISTSUCCESS,
  GETTENANCYLISTERROR,
  GETTENANCYSUCCESS,
  GETTENANCYERROR,
  GETTENANCYSTATUSERROR,
  GETTENANCYSTATUSSUCCESS,
} = slice.actions;
export default slice.reducer;
