import React, { useEffect } from "react";
import { RadioButton } from "primereact/radiobutton";
import LaCheckbox from "../../../components/LaCheckbox";
import LaInput from "../../../components/LaInput";
import LaInputTextArea from "../../../components/LaInputTextArea";
import { InputText } from "primereact/inputtext";
import VehicleType from "./VehicleType";

function VnPInfo({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    formik,
  };

  const handleMaxDate = (e, key) => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date in ISO format

    if (e.target.value > today) {
      formik.setFieldValue(key, today);
    } else {
      formik.setFieldValue(key, e.target.value);
    }
  };

  useEffect(() => {
    if (values.vehicleType === "P") {
      formik.setFieldValue("vehiclecondition", "");
      formik.setFieldValue("medicalevidence", "");
    }
  }, [values.vehicleType]);

  return (
    <div>
      <div align="center" style={{ marginTop: "5%" }}>
        <h6
          style={{
            color: "#428BCA",
            fontWeight: "600",
            lineHeight: "1.42857143",
          }}
        >
          Vehicles & Passenger Info
        </h6>
        <small style={{ color: "#999" }}>Enter vehicle information</small>
      </div>
      <VehicleType {...props} />
      <hr className="border__hr" />
      <div className="row">
        <div className="col-md-4">
          <div style={{ marginLeft: "30px" }}>
            <h6 style={{ fontWeight: "bold", fontSize: "12px" }}>
              Police Report Info
            </h6>
          </div>
          <hr className="border__hr" />
          <div className="p-fluid p-formgrid p-grid p-pr-4">
            <div className="p-col-12 p-mt-2">
              <label className="p-d-block">Condition of vehicle:</label>
              <div className="Radio__Btn">
                <div className="p-field-radiobutton">
                  <RadioButton
                    inputId="vehiclecondition"
                    name="vehiclecondition"
                    value="D"
                    onChange={onChange}
                    checked={values.vehiclecondition === "D"}
                    disabled={values.vehicleType === "P"}
                  />
                  <label htmlFor="Driveable" style={{ marginRight: "10px" }}>
                    Driverable
                  </label>
                </div>
                <div className="p-field-radiobutton">
                  <RadioButton
                    inputId="vehiclecondition"
                    name="vehiclecondition"
                    value="No"
                    onChange={onChange}
                    checked={values.vehiclecondition === "No"}
                    disabled={values.vehicleType === "P"}
                  />
                  <label
                    htmlFor="vehiclecondition"
                    style={{ marginRight: "10px" }}
                  >
                    Non-driveable
                  </label>
                </div>
                <div className="p-field-radiobutton">
                  <RadioButton
                    inputId="vehiclecondition"
                    name="vehiclecondition"
                    value="U"
                    onChange={onChange}
                    checked={values.vehiclecondition === "U"}
                    disabled={values.vehicleType === "P"}
                  />
                  <label
                    htmlFor="vehiclecondition"
                    style={{ marginRight: "10px" }}
                  >
                    Urgent recovery
                  </label>
                </div>
              </div>
            </div>
            {values.vehiclecondition === "U" && (
              <div className="p-col-12 p-mt-2">
                <label className="p-d-block">Location</label>
                <LaInputTextArea
                  keyName="medicalevidence"
                  placeholder="please accident location here"
                  {...props}
                />
              </div>
            )}
            <div className="p-col-12 p-mt-2" style={{ display: "flex" }}>
              <LaCheckbox keyName="reportedtopolice" {...props} />
              <label className="p-d-block" style={{ marginLeft: "10px" }}>
                Reported To Police
              </label>
            </div>
            <div className="p-col-6 p-mt-2">
              <InputText
                type="date"
                id="reportedon"
                name="reportedon"
                disabled={!values.reportedtopolice}
                value={values.reportedon}
                onChange={(e) => {
                  handleMaxDate(e, "reportedon");
                }}
                className={classNames({
                  "p-invalid": isFormFieldValid("reportedon"),
                })}
              />
              {getFormErrorMessage("reportedon")}
            </div>
            <div className="p-col-6 p-mt-2">
              <LaInput
                keyName="referencenumber"
                type="number"
                placeholder="Reference Number"
                disabled={!values.reportedtopolice}
                {...props}
              />
            </div>
            <div className="p-col-12 p-mt-4" style={{ display: "flex" }}>
              <LaCheckbox keyName="vdImages" {...props} />
              <label className="p-d-block" style={{ marginLeft: "10px" }}>
                Vehical Damage Images
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VnPInfo;
