import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { handleGetRequest } from "../../../services/GetTemplate";
import { handlePostRequest } from "../../../services/PostTemplate";
import { getTenancyDetails } from "../../../redux/actions/tenancyAction";
import { SplitButton } from "primereact/splitbutton";
import "./CaseHeaderTenancy.css";
import { Divider } from "primereact/divider";

function CaseHeaderTenancy({
  code,
  title,
  subTitle,
  search,
  children,
  onSearch,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [solicitorRtaData, setsolicitorRtaData] = useState("");
  const [solicitorRtaDataValue, setsolicitorRtaDataValue] = useState("");
  const [rtaHotkeyModal, setrtaHotkeyModal] = useState(false);
  const [companyWiseUser, setcompanyWiseUser] = useState("");
  const [companyWiseUserValue, setcompanyWiseUserValue] = useState("");
  const [rejectModal, setrejectModal] = useState(false);
  const [reason, setreason] = useState("");
  const [rejectBtnValue, setrejectBtnValue] = useState("");
  const [loading, setloading] = useState(false);
  const [loadingIcon, setloadingIcon] = useState("");
  const dispatch = useDispatch();

  const breakpoints = { "960px": "75vw", "640px": "100vw" };

  const createdate = useSelector(
    (state) => state?.tenancySlice?.tenancy?.createdate
  );

  const funcgetSolicitorsForRta = async () => {
    const res = await handleGetRequest("lovSolicitorsForTenancy");
    setsolicitorRtaData(res.data);
  };

  useEffect(() => {
    funcgetSolicitorsForRta();
  }, []);

  const handleSolicitor = async (e) => {
    setsolicitorRtaDataValue(e.value);
    const res = await handleGetRequest("lovCompanyWiseUSer/" + e.value.code);
    setcompanyWiseUser(res.data);
  };

  const rtaActionButtons = useSelector(
    (state) => state?.tenancySlice?.tenancy?.hdrActionButton
  );
  const hdrActionButtonForLA = useSelector(
    (state) => state?.tenancySlice?.tenancy?.hdrActionButtonForLA
  );

  let hdrActionBtnitems = [];
  if (hdrActionButtonForLA !== null && hdrActionButtonForLA !== undefined) {
    hdrActionButtonForLA.map((item) => {
      hdrActionBtnitems.push({
        label: item?.buttonname,
        command: () => {
          handleActionButton(item);
        },
      });
    });
  }

  const tenancyClaimCode = useSelector(
    (state) => state?.tenancySlice?.tenancy?.tenancyclaimcode
  );

  const fetchHdrDetails = async () => {
    await dispatch(getTenancyDetails(tenancyClaimCode));
  };

  const handleActionHotKey = (value) => {
    setrtaHotkeyModal(true);
  };

  const handleSubmitSolicitor = async () => {
    const data = {
      tenancyClaimCode: tenancyClaimCode.toString(),
      solicitorCode: companyWiseUserValue.code,
      solicitorUserCode: solicitorRtaDataValue.code,
    };
    await handlePostRequest(data, "tenancy/assigncasetosolicitorbyLA");
    setrtaHotkeyModal(false);
    fetchHdrDetails();
  };

  const footer = (
    <div>
      <center className="p-mt-2 p-button-outlined">
        <Button
          className="btn btn-primary"
          onClick={handleSubmitSolicitor}
          label="Submit"
        />
      </center>
    </div>
  );

  const handleActionButton = async (value) => {
    if (value.rejectDialog === "Y" || value?.acceptDialog === "Y") {
      // send reason on accept case as well
      setrejectModal(true);
      setrejectBtnValue(value.buttonvalue);
    } else {
      const data = {
        tenancyClaimCode,
        toStatus: value.buttonvalue,
      };
      await handlePostRequest(data, "tenancy/performActionOnTenancy");
      fetchHdrDetails();
    }
  };

  const handlRejectActionButton = async () => {
    setloading(true);
    setloadingIcon("pi pi-spin pi-spinner");
    const data = {
      tenancyClaimCode,
      toStatus: rejectBtnValue,
      reason,
    };
    await handlePostRequest(data, "tenancy/performActionOnTenancy");
    setrejectModal(false);
    setrejectBtnValue("");
    setloading(false);
    setloadingIcon("");
    fetchHdrDetails();
  };

  const actionButtons = (
    <div>
      {rtaActionButtons && rtaActionButtons !== null
        ? rtaActionButtons.map((item) => {
            if (
              item.apiflag === "Y" &&
              localStorage.getItem("directIntroducer") === "true"
            )
              return (
                <Button
                  value={item.buttonvalue}
                  onClick={(e) => {
                    handleActionHotKey(item.buttonvalue);
                  }}
                  label={item.buttonname}
                  className="btn-info btn-sm p-mr-2 p-mb-2"
                />
              );
            else
              return (
                <Button
                  value={item.buttonvalue}
                  onClick={(e) => {
                    handleActionButton(item);
                  }}
                  label={item.buttonname}
                  className="btn-info btn-sm p-mr-2 p-mb-2"
                />
              );
          })
        : ""}
    </div>
  );

  const Rejectfooter = (
    <div>
      <center className="p-mt-2 p-button-outlined">
        <Button
          icon={loadingIcon || ""}
          disabled={loading}
          onClick={handlRejectActionButton}
          label="Submit"
          className="btn btn-primary"
        />
      </center>
    </div>
  );

  return (
    <div className="case-header">
      <div className="col-lg-4">
        <h6>{title}</h6>
      </div>

      <div style={{ display: "flex" }}>
        <div className="mt-2 p-mr-2">{createdate} </div>
        <Divider style={{ padding: "0px" }} layout="vertical" />
        <div>
          <Button
            onClick={(e) => {
              e.preventDefault();
            }}
            label={subTitle}
            style={{ cursor: "text" }}
            className="btn-info btn-sm p-mr-2 p-mb-2"
          />
        </div>
        <Divider style={{ padding: "0px" }} layout="vertical" />
        <div>{actionButtons}</div>
        {hdrActionButtonForLA !== null && hdrActionButtonForLA !== undefined ? (
          <div>
            <SplitButton
              icon="pi pi-sliders-h"
              model={hdrActionBtnitems}
            ></SplitButton>
          </div>
        ) : (
          ""
        )}
      </div>
      <Dialog
        header="Solicitor for Tenancy"
        visible={rtaHotkeyModal}
        footer={footer}
        onHide={() => setrtaHotkeyModal(false)}
        breakpoints={breakpoints}
        style={{ width: "50vw" }}
      >
        <div
          className="p-fluid p-formgrid p-grid p-mt-4"
          style={{ paddingBottom: "30%" }}
        >
          <div className="p-field p-col-12 p-md-6">
            <label>Solicitor for Tenancy</label>
            <Dropdown
              className="Width__100 "
              options={solicitorRtaData}
              value={solicitorRtaDataValue}
              onChange={(e) => {
                handleSolicitor(e);
              }}
              placeholder="Select"
              optionLabel="name"
            />
          </div>
          <div className="p-field p-col-12 p-md-6">
            <label>Solicitor for Tenancy</label>
            <Dropdown
              className="Width__100 "
              options={companyWiseUser}
              value={companyWiseUserValue}
              onChange={(e) => {
                setcompanyWiseUserValue(e.value);
              }}
              placeholder="Select"
              optionLabel="name"
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Action"
        visible={rejectModal}
        footer={Rejectfooter}
        onHide={() => setrejectModal(false)}
        breakpoints={breakpoints}
        style={{ width: "50vw" }}
      >
        <div className="p-mt-4">
          <InputTextarea
            className="Width__100"
            rows={5}
            cols={60}
            placeholder="Enter Reason "
            value={reason}
            onChange={(e) => setreason(e.target.value)}
          />
        </div>
      </Dialog>
    </div>
  );
}

export default CaseHeaderTenancy;

React.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  search: PropTypes.bool,
  children: PropTypes.element,
  onSearch: PropTypes.func,
};
