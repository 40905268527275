import * as Yup from "yup";

const initialValues = {
  anyFfmSufferedDb: "",
  claimantAddress: "",
  claimantContactno: "",
  claimantDob: "",
  claimantEmail: "",
  claimantName: "",
  complaintDefendent: "",
  consentPiShared: "",
  dateOfBreach: "",
  dateOfKnowledge: "",
  dbByCompBusns: "",
  dbPreexistingCond: "",
  dbRectified: "",
  defendentAddress: "",
  defendentContactno: "",
  defendentEmail: "",
  defendentHandleDb: "",
  defendentName: "",
  defendentReqConsent: "",
  evidenceOfDb: "",
  frstFoundAbtDb: "",
  hasDbAffectedYou: "",
  howFeelYourDb: "",
  howKnowAbtDb: "",
  howKnowDefendent: "",
  instructedPrvntDb: "",
  litigationFriend: "",
  otherprblmWithDefendent: "",
  piDisclosedInDb: "",
  piShared: "",
  recurringDbOfPi: "",
  soughtMedicalAvdnce: "",
};

const validationSchema = Yup.object().shape({
  anyFfmSufferedDb: Yup.string().required("Required"),
  claimantAddress: Yup.string().required("Required"),
  claimantContactno: Yup.string().required("Required"),
  claimantDob: Yup.string().required("Required"),
  claimantEmail: Yup.string().required("Required"),
  claimantName: Yup.string().required("Required"),
  complaintDefendent: Yup.string().required("Required"),
  consentPiShared: Yup.string().required("Required"),
  dateOfBreach: Yup.string().required("Required"),
  dateOfKnowledge: Yup.string().required("Required"),
  dbByCompBusns: Yup.string().required("Required"),
  dbPreexistingCond: Yup.string().required("Required"),
  dbRectified: Yup.string().required("Required"),
  defendentAddress: Yup.string().required("Required"),
  defendentContactno: Yup.string().required("Required"),
  defendentEmail: Yup.string().required("Required"),
  defendentHandleDb: Yup.string().required("Required"),
  defendentName: Yup.string().required("Required"),
  defendentReqConsent: Yup.string().required("Required"),
  evidenceOfDb: Yup.string().required("Required"),
  frstFoundAbtDb: Yup.string().required("Required"),
  hasDbAffectedYou: Yup.string().required("Required"),
  howFeelYourDb: Yup.string().required("Required"),
  howKnowAbtDb: Yup.string().required("Required"),
  howKnowDefendent: Yup.string().required("Required"),
  instructedPrvntDb: Yup.string().required("Required"),
  litigationFriend: Yup.string().required("Required"),
  otherprblmWithDefendent: Yup.string().required("Required"),
  piDisclosedInDb: Yup.string().required("Required"),
  piShared: Yup.string().required("Required"),
  recurringDbOfPi: Yup.string().required("Required"),
  soughtMedicalAvdnce: Yup.string().required("Required"),
});

export { initialValues, validationSchema };
