import React from "react";
import LaInputTextArea from "../../../components/LaInputTextArea";
import LaCheckbox from "../../../components/LaCheckbox";
import LaInput from "../../../components/LaInput";

function FurtherDetails({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
  };

  return (
    <>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Any notes?</label>
        <LaCheckbox keyName="anynotes" {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Any images/videos taken?</label>
        <LaCheckbox keyName="imagesvideostaken" {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Witnesses</label>
        <LaCheckbox keyName="witnesses" {...props} />
      </div>
      {values.witnesses && (
        <>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Witness Name</label>
            <LaInputTextArea
              keyName="witnessName"
              placeholder="Witness Name"
              {...props}
            />
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Witness Contact Details</label>
            <LaInputTextArea
              keyName="witnessContactDetails"
              placeholder="Witness Contact Details"
              {...props}
            />
          </div>
        </>
      )}
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Further Notes</label>
        <LaInputTextArea
          keyName="furtherNotes"
          placeholder="Further Notes"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Any other Heads of losses the client wishes to claim for?
        </label>
        <LaInputTextArea keyName="anyotherlosses" placeholder="" {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Has CL made a complaint to the relevant body?
        </label>
        <LaCheckbox keyName="complaintmade" {...props} />
      </div>
      {values.complaintmade && (
        <>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              How long has it been since the complaint?
            </label>
            <LaInput
              keyName="timesincecomplaint"
              placeholder="Time Since Complaint"
              {...props}
            />
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Have they responded or ignored?</label>
            <LaInput keyName="complaintresponded" {...props} />
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              Have they provided a separate body to contact in respect of
              complaint?
            </label>
            <LaInput keyName="separatecomplaintcontact" {...props} />
          </div>
        </>
      )}
    </>
  );
}

export default FurtherDetails;
