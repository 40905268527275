import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FiSettings } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { handleGetRequest } from "../../../services/GetTemplate";

function ViewAllBroadcast() {
  const navigate = useNavigate();
  const [filtered, setFiltered] = useState([]);
  const [broadcastList, setBroadcastList] = useState([]);

  const actionsTemplate = ({ tenancyClaimCode }) => {
    return (
      <div
        title="Details"
        className="pointer"
        onClick={() => {
          navigate(`../tenancyDetails?tenancycode=${tenancyClaimCode}`);
        }}
      >
        <FiSettings />
      </div>
    );
  };

  useEffect(() => {
    const getBroadcastList = async () => {
      try {
        const response = await handleGetRequest("getAllBroadCastMessages");
        setBroadcastList(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    getBroadcastList();
  }, []);

  const onGlobalFilterChange = (e = false) => {
    const keys = ["code", "client", "taskName", "taskDue", "created", "status"];
    const value = e?.target?.value;
    if (value) {
      const filteredList = broadcastList.filter((item) => {
        let flag = false;
        keys.forEach((key) => {
          if (
            key &&
            item[key] &&
            item[key]
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
            flag = true;
        });
        if (flag) return item;
      });
      setFiltered(filteredList);
    } else {
      setFiltered(broadcastList);
    }
  };

  const renderHeader2 = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <InputText onChange={onGlobalFilterChange} placeholder="Search" />
        </span>
      </div>
    );
  };

  const header2 = renderHeader2();

  return (
    <>
      {" "}
      <div className="card">
        <DataTable
          value={filtered || []}
          showGridlines
          responsiveLayout="scroll"
          header={header2}
        >
          <Column field="code" sortable header="Code"></Column>
          <Column field="client" sortable header="Client"></Column>
          <Column field="taskName" sortable header="Task Name"></Column>
          <Column field="taskDue" sortable header="Task Due"></Column>
          <Column field="created" sortable header="Created"></Column>
          <Column field="status" sortable header="Status"></Column>
          <Column header="Acts" body={actionsTemplate}></Column>
        </DataTable>
      </div>
    </>
  );
}

export default ViewAllBroadcast;
