import React, { useEffect } from "react";
import { useFormik } from "formik";
import JobForm from "../JobForm";
import validationSchema, { initialValues } from "../JobConstants";

function ViewCompanyJob({ rowData }) {
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async () => {},
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
    disabled: true,
  };

  const fillFormFields = ({ ...data }) => {
    // To convert Y/N to true/false for radio buttons and checkboxes
    Object.keys(data).forEach((key) => {
      if (data[key] === "Y") {
        data[key] = true;
      }
      if (data[key] === "N") {
        data[key] = false;
      }
      if (data[key] === null) {
        data[key] = "";
      }
    });
    Object.keys(data).forEach((key) => {
      if (formik.initialValues.hasOwnProperty(key)) {
        formik.setFieldValue(key, data[key]);
      }
    });
    formik.setFieldValue("compaigncode", data.tblCompaign.compaigncode);
  };

  useEffect(() => {
    if (rowData) {
      fillFormFields(rowData);
    }
  }, [rowData]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <JobForm {...props} />
      </form>
    </>
  );
}

export default ViewCompanyJob;
