import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function DashboardNav() {
  let navigate = useNavigate();
  const dashboardNav = JSON.parse(
    useSelector((state) => state.authenticationSlice?.menu)
  );

  const topColors = [
    "bg-primary",
    "bg-success",
    "bg-danger",
    "bg-warning",
    "bg-info",
  ];

  const bottomColors = [
    "bg-secondary",
    "bg-dark",
    "bg-light",
    "bg-white",
    "bg-muted",
  ];

  // <div
  //   className={"p-ml-2 p-mt-2 " + topColors[index % 5]}
  //   key={index}
  // >
  //   <div className="top-info">
  //     <a href={"#/" + item?.[0]?.label}>{item?.label}</a>
  //     <small>{item?.[0]?.label} </small>
  //   </div>
  //   {/* <span
  //     className={"bottom-info  pointer " + bottomColors[index % 2]}
  //     onClick={() => navigate("role")}
  //   >
  //     Roles
  //   </span> */}
  // </div>

  return (
    <div className="block">
      <div className="info-blocks d-flex justify-content-center flex-wrap">
        {dashboardNav?.map((item, index) => (
          <div className={"p-ml-2 p-mt-2 " + topColors[index % 5]} key={index}>
            <div className="top-info">
              {item.isList === "true" && (
                <a href={"#" + item.listLink}>{item.label}</a>
              )}
              <small>
                {item.addNewCaseLabel !== null
                  ? item.addNewCaseLabel
                  : item.listLabel}
              </small>
            </div>
            <span
              className={"bottom-info  pointer " + bottomColors[index % 2]}
              onClick={() => navigate(item.bottomAreaShowLink)}
            >
              {item.bottomAreaShowLabel}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DashboardNav;
