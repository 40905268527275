import React from "react";
import CaseHeaderRta from "./CaseHeaderRta/index";
import { MainWrapper } from "../../components/FormLayout/FormHeader/index";
import {
  SideBoxWrapper,
  SideBox,
} from "../../components/FormLayout/SideBoxSection/index";
import { InfoWrapper, InfoButton } from "../../components/InfoButton";
import { AiOutlineBars } from "react-icons/ai";
import { useSelector } from "react-redux";
import UploadModal from "../../components/UploadModal";
import ViewRtaForm from "./ViewRtaForm";
import RtaTasks from "./RtaTasks";
import RtaAddPassengers from "./RtaAddPassengers";
import RtaSideBoxPassengersLinkToMain from "./RtaSideBoxPassengersLinkToMain";
import RTASideBoxTimeline from "./RTASideBoxTimeline";
import InvoiceCaseDetails from "../../components/InvoiceCaseDetails.js/InvoiceCaseDetails";
import ESignAuditButton from "../../components/ESignAudit/ESignAuditButton";

export default function RtaDetails() {
  const { rta } = useSelector((state) => state.rtaSlice);
  const [showUpload, setShowUpload] = React.useState(false);
  const [rtaTaskCode, setRtaTaskCode] = React.useState("");
  const advisorname = rta?.advisorname || "";
  const introducername = rta?.introducername || "";
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  return (
    <>
      <CaseHeaderRta title={rta.rtanumber}>
        <InfoWrapper>
          <InfoButton
            title={"All"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
          <InfoButton
            title={"New"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
        </InfoWrapper>
      </CaseHeaderRta>
      <MainWrapper>
        <ViewRtaForm />
        <SideBoxWrapper>
          {user?.tblUsercategory?.categorycode === "4"
            ? rta?.rtaCaseTasks?.length > 0 && <RtaTasks />
            : null}
          {(user?.tblUsercategory?.categorycode === "4" ||
            user?.tblUsercategory?.categorycode === "1") && (
            <SideBox title="Case Accounts" className="mt-4">
              <strong>{introducername}</strong>
              <br />
              <small>{advisorname}</small>
              <br />
              <hr />
              <strong>{rta?.solicitorcompany || ""}</strong>
              <br />
              <small>{rta?.solicitorusername || ""}</small>
            </SideBox>
          )}
          <InvoiceCaseDetails
            introducerInvoiceDate={rta?.introducerInvoiceDate}
            solicitorInvoiceDate={rta?.solicitorInvoiceDate}
            introducerInvoiceHeadId={rta?.introducerInvoiceHeadId}
            solicitorInvoiceHeadId={rta?.solicitorInvoiceHeadId}
          />
          <ESignAuditButton data={rta?.tblEsignStatus} />
          {user?.tblUsercategory?.categorycode === "1" ||
          user?.tblUsercategory?.categorycode === "4"
            ? rta?.rtalinkcode === "" ||
              (rta?.rtalinkcode === null && <RtaAddPassengers />)
            : null}
          <RtaSideBoxPassengersLinkToMain />
          <RTASideBoxTimeline />
        </SideBoxWrapper>
      </MainWrapper>
      <UploadModal
        taskCode={rtaTaskCode}
        show={showUpload}
        hide={setShowUpload}
      />
    </>
  );
}
