import React from "react";
import LaInput from "../../../components/LaInput";
import LaAddress from "../../../components/LaAddress";

function ThirdPartyDetails({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
  };

  const addressProps = {
    ...props,
    keyNameCity: "tpcity",
    keyNameRegion: "tpregion",
    keyNamePostcode: "tppostalcode",
    keyNameAddress1: "tpaddress1",
    keyNameAddress2: "tpaddress2",
    keyNameAddress3: "tpaddress3",
    placeholder: "Enter Address of Third Party",
    formik,
  };

  return (
    <>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Third Party Name/Company Details<span className="text-danger">*</span>
        </label>
        <LaInput
          keyName="tpname"
          placeholder="Third Party Name/Company Details"
          {...props}
        />
      </div>
      <LaAddress {...addressProps} />
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Third Party Contact Number</label>
        <LaInput
          keyName="tpcontactno"
          placeholder="Third Party Contact Number"
          {...props}
        />
      </div>
    </>
  );
}

export default ThirdPartyDetails;
