import React from "react";
import LaInputNumber from "../../../../components/LaInputNumber/LaInputNumber";
import LaStaticDropdown from "../../../../components/LaStaticDropdown";

function RtaJobFields({
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
  disabled,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    disabled,
  };

  const inputNumberProps = {
    ...props,
    mode: "currency",
    currency: "GBP",
  };

  return (
    <>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Whiplash</label>
        <LaInputNumber
          keyName="wiplash"
          placeholder="Whiplash"
          {...inputNumberProps}
        />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Minor</label>
        <LaInputNumber
          keyName="minor"
          placeholder="Minor"
          {...inputNumberProps}
        />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Hybrid</label>
        <LaInputNumber
          keyName="hybrid"
          placeholder="Hybrid"
          {...inputNumberProps}
        />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Bike</label>
        <LaInputNumber
          keyName="bike"
          placeholder="Bike"
          {...inputNumberProps}
        />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Pedestrian</label>
        <LaInputNumber
          keyName="pedestrian"
          placeholder="Pedestrian"
          {...inputNumberProps}
        />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Scottish RTA</label>
        <LaInputNumber
          keyName="scotishRta"
          placeholder="Scottish RTA"
          {...inputNumberProps}
        />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Status</label>
        <LaStaticDropdown
          keyName="status"
          optionLabel="label"
          options={[
            { value: true, label: "Active" },
            { value: false, label: "Inactive" },
          ]}
          {...props}
        />
      </div>
    </>
  );
}

export default RtaJobFields;
