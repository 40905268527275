import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { useFormik } from "formik";
import classNames from "classnames";
import { handlePostRequest } from "../../services/PostTemplate";
import { useSelector } from "react-redux";
import { initialValues, validationSchema } from "./TenancyConstants";

function Tenant({ edit, onSave, isSubmitFormik, handleReturnEdit }) {
  const { tenancy } = useSelector((state) => state.tenancySlice);

  useEffect(() => {
    if (isSubmitFormik) {
      formik.handleSubmit();
    }
  }, [isSubmitFormik]);

  useEffect(() => {
    if (edit) {
      fillFormFields();
    }
  }, [edit, tenancy]);

  const fillFormFields = () => {
    Object.keys(tenancy).forEach((key) => {
      formik.setFieldValue(key, tenancy[key]);
    });
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (data) => {
      if (edit) {
        data["tenancyclaimcode"] = tenancy.tenancyclaimcode;
        delete data["hdrActionButton"];
        delete data["tblTenancydocuments"];
        delete data["tblTenancylogs"];
        delete data["tblTenancymessages"];
        delete data["tblTenancynotes"];
        delete data["tblTenancysolicitors"];
        await handlePostRequest(data, "/tenancy/updateTenancyCase");
        handleReturnEdit();
      } else await handlePostRequest(data, "/tenancy/addNewTenancyCase");
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <>
      <div className="p-ml-4">
        {!edit ? (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h6
                style={{
                  color: "#428BCA",
                  fontWeight: "600",
                  lineHeight: "1.42857143",
                }}
              >
                Main Info
              </h6>
              <small style={{ color: "#999" }}>Enter main information</small>
            </div>
            <div>
              <Button
                type="submit"
                icon="pi pi-arrow-circle-right"
                iconPos="right"
                className="btn btn-primary"
                label="Next"
                onClick={formik.handleSubmit}
              ></Button>
            </div>
          </div>
        ) : (
          ""
        )}

        <hr className="border__hr" />
        <div>
          <form onSubmit={edit ? onSave : formik.handleSubmit}>
            <div className="p-col-6">
              <div className="p-fluid p-formgrid p-grid p-pr-4">
                <div className="Panel__Heading">Main Claimant</div>
                <div className="p-col-12 ">
                  <label className="p-d-block">Full Name</label>
                  <InputText
                    id="fullName"
                    name="fullName"
                    placeholder="Name"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("fullName"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("fullName")}
                </div>
                <div className="p-col-12  p-mt-2">
                  <label className="p-d-block">Contact</label>
                  <InputText
                    id="contact"
                    name="contact"
                    placeholder="Contact"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("contact"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.contact}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("contact")}
                </div>
                <div className="p-col-6 p-mt-4">
                  <label className="p-d-block">Date of birth</label>
                  <InputText
                    type="date"
                    id="dob"
                    name="dob"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("dob"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.dob}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("dob")}
                </div>
                <div className="p-col-6 p-mt-4">
                  <label className="p-d-block">Email</label>
                  <InputText
                    type="email"
                    id="email"
                    name="email"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("email"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("email")}
                </div>
                <div className="p-col-12 p-mt-4">
                  <label className="p-d-block">Current Address</label>
                  <InputText
                    type="email"
                    id="currentAddress"
                    name="currentAddress"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("currentAddress"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.currentAddress}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("currentAddress")}
                </div>
                <div className="p-col-12 p-mt-4">
                  <label className="p-d-block">
                    Did the landlord live with you in the property ?
                  </label>
                </div>
                <div className="p-col-12">
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="landlordLiveInProperty"
                      name="landlordLiveInProperty"
                      value={"Y"}
                      onChange={formik.handleChange}
                      checked={formik.values.landlordLiveInProperty === "Y"}
                    />
                    <label htmlFor="landlordliveyes" className="p-ml-1">
                      Yes
                    </label>
                  </div>
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="landlordLiveInProperty"
                      name="landlordLiveInProperty"
                      value={"N"}
                      onChange={formik.handleChange}
                      checked={formik.values.landlordLiveInProperty === "N"}
                    />
                    <label htmlFor="landlordliveyes" className="p-ml-1">
                      No
                    </label>
                  </div>
                  {getFormErrorMessage("landlordLiveInProperty")}
                </div>
                <div className="p-col-12 p-mt-4">
                  <label className="p-d-block">
                    Are you the only tenant registered on your tenancy
                    agreement?
                  </label>
                </div>
                <div className="p-col-6 mt-2">
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="stillLivingInProperty"
                      name="stillLivingInProperty"
                      value={"Y"}
                      onChange={formik.handleChange}
                      checked={formik.values.stillLivingInProperty === "Y"}
                    />
                    <label htmlFor="stillLivingInProperty" className="p-ml-1">
                      Yes
                    </label>
                  </div>
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="stillLivingInProperty"
                      name="stillLivingInProperty"
                      value={"N"}
                      onChange={formik.handleChange}
                      checked={formik.values.stillLivingInProperty === "N"}
                    />
                    <label htmlFor="stillLivingInProperty" className="p-ml-1">
                      No
                    </label>
                  </div>
                  {getFormErrorMessage("stillLivingInProperty")}
                </div>
                <div className="p-col-12 p-mt-4">
                  <label className="p-d-block">
                    Are you still living in the property ?
                  </label>
                </div>
                <div className="p-col-12">
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="onlyTenantRegistered"
                      name="onlyTenantRegistered"
                      value={"Y"}
                      onChange={formik.handleChange}
                      checked={formik.values.onlyTenantRegistered === "Y"}
                    />
                    <label htmlFor="onlyTenantRegistered" className="p-ml-1">
                      Yes
                    </label>
                  </div>
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="onlyTenantRegistered"
                      name="onlyTenantRegistered"
                      value={"N"}
                      onChange={formik.handleChange}
                      checked={formik.values.onlyTenantRegistered === "N"}
                    />
                    <label htmlFor="onlyTenantRegistered" className="p-ml-1">
                      No
                    </label>
                  </div>
                  {getFormErrorMessage("onlyTenantRegistered")}
                </div>

                <div className="p-col-6 mt-4">
                  <label className="p-d-block">Date moved out</label>
                  <InputText
                    type="date"
                    id="dateMovedOn"
                    name="dateMovedOn"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("dateMovedOn"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.dateMovedOn}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("dateMovedOn")}
                </div>

                <div className="p-col-6 mt-4">
                  <label className="p-d-block">
                    Number of tenancy agreements signed
                  </label>
                  <InputText
                    id="noOfTenancyAgrmtSigned"
                    name="noOfTenancyAgrmtSigned"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid(
                          "noOfTenancyAgrmtSigned"
                        ),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.noOfTenancyAgrmtSigned}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("noOfTenancyAgrmtSigned")}
                </div>

                <div className="p-col-6 mt-4">
                  <label className="p-d-block">Term dates</label>
                  <InputText
                    type="date"
                    name="termDate"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("termDate"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.termDate}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("termDate")}
                </div>

                <div className="p-col-12 p-mt-4">
                  <label className="p-d-block">
                    Do you know if the deposit was protected ?
                  </label>
                </div>
                <div className="p-col-6 mt-2">
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="depositProtected"
                      name="depositProtected"
                      value={"Y"}
                      onChange={formik.handleChange}
                      checked={formik.values.depositProtected === "Y"}
                    />
                    <label htmlFor="depositProtected" className="p-ml-1">
                      Yes
                    </label>
                  </div>
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="depositProtected"
                      name="depositProtected"
                      value={"N"}
                      onChange={formik.handleChange}
                      checked={formik.values.depositProtected === "N"}
                    />
                    <label htmlFor="depositProtected" className="p-ml-1">
                      No
                    </label>
                  </div>
                </div>

                <div className="p-col-12 mt-2 ">
                  <label className="p-d-block">
                    Please explain why you think that?
                  </label>
                  <InputText
                    name="thinkReason"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("thinkReason"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.thinkReason}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("thinkReason")}
                </div>

                <div className="p-col-6 mt-2">
                  <label className="p-d-block">Amount of deposit paid</label>
                  <InputText
                    name="depositPaid"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("depositPaid"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.depositPaid}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("depositPaid")}
                </div>

                <div className="p-col-6 mt-2">
                  <label className="p-d-block">
                    When was the deposit paid ?
                  </label>
                  <InputText
                    type="date"
                    name="depositPaidDate"
                    className={classNames(
                      {
                        "p-inputtext-error":
                          isFormFieldValid("depositPaidDate"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.depositPaidDate}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("depositPaidDate")}
                </div>

                <div className="p-col-6 mt-2">
                  <label className="p-d-block">How was it paid?</label>
                  <InputText
                    name="depositPaidMethod"
                    className={classNames(
                      {
                        "p-inputtext-error":
                          isFormFieldValid("depositPaidMethod"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.depositPaidMethod}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("depositPaidMethod")}
                </div>

                <div className="p-col-6 p-mt-4">
                  <label className="p-d-block">
                    Paid to landlord or agent?
                  </label>
                  <div className="p-col-6">
                    <div className="field-radiobutton">
                      <RadioButton
                        inputId="paidToLandlordOrAgent"
                        name="paidToLandlordOrAgent"
                        value={"Y"}
                        onChange={formik.handleChange}
                        checked={formik.values.paidToLandlordOrAgent === "Y"}
                      />
                      <label htmlFor="paidToLandlordOrAgent" className="p-ml-1">
                        Yes
                      </label>
                    </div>
                    <div className="field-radiobutton">
                      <RadioButton
                        inputId="paidToLandlordOrAgent"
                        name="paidToLandlordOrAgent"
                        value={"N"}
                        onChange={formik.handleChange}
                        checked={formik.values.paidToLandlordOrAgent === "N"}
                      />
                      <label htmlFor="paidToLandlordOrAgent" className="p-ml-1">
                        No
                      </label>
                    </div>
                  </div>
                </div>

                <div className="p-col-12 mt-2 ">
                  <label className="p-d-block">
                    What is the name of the agency?
                  </label>
                  <InputText
                    name="nameOfAgency"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("nameOfAgency"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.nameOfAgency}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("nameOfAgency")}
                </div>

                <div className="p-col-6 mt-2 ">
                  <label className="p-d-block">
                    Amount of deposit returned?
                  </label>
                  <InputText
                    name="amountOfDepositReturned"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid(
                          "amountOfDepositReturned"
                        ),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.amountOfDepositReturned}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("amountOfDepositReturned")}
                </div>

                <div className="p-col-12 mt-2 ">
                  <label className="p-d-block">
                    Please give the reason if any was withheld :
                  </label>
                  <InputText
                    name="withheldReason"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("withheldReason"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.withheldReason}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("withheldReason")}
                </div>

                <div className="p-col-12 p-mt-4">
                  <label className="p-d-block">Any rent arrears?</label>
                </div>
                <div className="p-col-6 mt-2">
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="rentArrears"
                      name="rentArrears"
                      value={"Y"}
                      onChange={formik.handleChange}
                      checked={formik.values.rentArrears === "Y"}
                    />
                    <label htmlFor="rentArrears" className="p-ml-1">
                      Yes
                    </label>
                  </div>
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="rentArrears"
                      name="rentArrears"
                      value={"N"}
                      onChange={formik.handleChange}
                      checked={formik.values.rentArrears === "N"}
                    />
                    <label htmlFor="rentArrears" className="p-ml-1">
                      No
                    </label>
                  </div>
                </div>

                <div className="p-col-12 mt-2 ">
                  <label className="p-d-block">
                    If yes please tell the amount and details :
                  </label>
                  <InputText
                    name="rentArrearsDetail"
                    className={classNames(
                      {
                        "p-inputtext-error":
                          isFormFieldValid("rentArrearsDetail"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.rentArrearsDetail}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("rentArrearsDetail")}
                </div>

                <div className="p-col-12 mt-2 ">
                  <label className="p-d-block">
                    Has the landlord made any claims for damages? if yes please
                    give details .
                  </label>
                  <InputText
                    name="landlordDamageClaim"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid(
                          "landlordDamageClaim"
                        ),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.landlordDamageClaim}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("landlordDamageClaim")}
                </div>

                <div className="p-col-12 p-mt-4">
                  <label className="p-d-block">
                    Did you claim housing benefits while living there ?
                  </label>
                </div>
                <div className="p-col-6 mt-2">
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="claimHousingBenefitsLiving"
                      name="claimHousingBenefitsLiving"
                      value={"Y"}
                      onChange={formik.handleChange}
                      checked={formik.values.claimHousingBenefitsLiving === "Y"}
                    />
                    <label
                      htmlFor="claimHousingBenefitsLiving"
                      className="p-ml-1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="claimHousingBenefitsLiving"
                      name="claimHousingBenefitsLiving"
                      value={"N"}
                      onChange={formik.handleChange}
                      checked={formik.values.claimHousingBenefitsLiving === "N"}
                    />
                    <label
                      htmlFor="claimHousingBenefitsLiving"
                      className="p-ml-1"
                    >
                      No
                    </label>
                  </div>
                </div>

                <div className="p-col-12 mt-4 ">
                  <label className="p-d-block">
                    Do you have any email address for the landlord? (The more
                    the details you have for the landlord the more the better)
                  </label>
                  <InputText
                    name="landlordEmailAddress"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid(
                          "landlordEmailAddress"
                        ),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.landlordEmailAddress}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("landlordEmailAddress")}
                </div>

                <div className="p-col-6 p-mt-4">
                  <label className="p-d-block">
                    Do you have a phone number for the landlord ?
                  </label>

                  <div className="p-col-6">
                    <div className="field-radiobutton">
                      <RadioButton
                        inputId="haveLandlordContact"
                        name="haveLandlordContact"
                        value={"Y"}
                        onChange={formik.handleChange}
                        checked={formik.values.haveLandlordContact === "Y"}
                      />
                      <label htmlFor="haveLandlordContact" className="p-ml-1">
                        Yes
                      </label>
                    </div>
                    <div className="field-radiobutton">
                      <RadioButton
                        inputId="haveLandlordContact"
                        name="haveLandlordContact"
                        value={"N"}
                        onChange={formik.handleChange}
                        checked={formik.values.haveLandlordContact === "N"}
                      />
                      <label htmlFor="haveLandlordContact" className="p-ml-1">
                        No
                      </label>
                    </div>
                  </div>
                </div>

                <div className="p-col-6 mt-4 ">
                  <label className="p-d-block">Landlord phone number</label>
                  <InputText
                    name="landlordContact"
                    className={classNames(
                      {
                        "p-inputtext-error":
                          isFormFieldValid("landlordContact"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.landlordContact}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("landlordContact")}
                </div>

                <div className="p-col-12 mt-2 ">
                  <label className="p-d-block">
                    Address for the landlord ?
                  </label>
                  <InputText
                    name="landlordAddress"
                    className={classNames(
                      {
                        "p-inputtext-error":
                          isFormFieldValid("landlordAddress"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.landlordAddress}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("landlordAddress")}
                </div>
              </div>
            </div>

            <div className="p-col-6">
              <div className="p-fluid p-formgrid p-grid p-pr-4">
                <div className="Panel__Heading">Rent Info</div>
                <div className="p-col-12">
                  <label className="p-d-block">Rent Paid ?</label>
                </div>
                <div className="p-col-12">
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="rentPaid"
                      name="rentPaid"
                      value={"Y"}
                      onChange={formik.handleChange}
                      checked={formik.values.rentPaid === "Y"}
                    />
                    <label htmlFor="rentPaid" className="p-ml-1">
                      Yes
                    </label>
                  </div>
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="rentPaid"
                      name="rentPaid"
                      value={"N"}
                      onChange={formik.handleChange}
                      checked={formik.values.rentPaid === "N"}
                    />
                    <label htmlFor="rentPaid" className="p-ml-1">
                      No
                    </label>
                  </div>
                </div>
                <div className="p-col-6 p-mt-2">
                  <label className="p-d-block">Rent Amount </label>
                  <InputText
                    name="rentAmount"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("rentAmount"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.rentAmount}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("rentAmount")}
                </div>

                <div className="p-col-12 p-mt-2">
                  <label className="p-d-block">Recieving Benefits </label>
                  <InputText
                    name="receivingBenefits"
                    className={classNames(
                      {
                        "p-inputtext-error":
                          isFormFieldValid("receivingBenefits"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.receivingBenefits}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("receivingBenefits")}
                </div>

                <div className="p-col-6 p-mt-2">
                  <label className="p-d-block">Arrears </label>
                  <InputText
                    name="arrears"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("arrears"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.arrears}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("arrears")}
                </div>

                <div className="p-col-6 p-mt-2">
                  <label className="p-d-block">Payment Plan</label>
                  <InputText
                    name="paymentPlan"
                    className={classNames(
                      {
                        "p-inputtext-error": isFormFieldValid("paymentPlan"),
                      },
                      "p-d-block Width__100"
                    )}
                    value={formik.values.paymentPlan}
                    onChange={formik.handleChange}
                  />
                  {getFormErrorMessage("paymentPlan")}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Tenant;
