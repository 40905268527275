import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import Client from "./Client";
import VehiclesDetails from "./VehiclesDetails";
import Lender from "./Lender";
import DealershipDetails from "./DealershipDetails";
import { useFormik } from "formik";
import classNames from "classnames";
import { handlePostRequest } from "../../services/PostTemplate";
import { useSelector } from "react-redux";
import { initialValues, validationSchema } from "./PCPConstants";
import MainInfo from "../../components/MainInfo/MainInfo";
import AccSubmissionCriteria from "../../components/AccSubmissionCriteria";
import { campaignCodes } from "../../components/CampaignGeneric/campaignConfig";
import ClaimantDetails from "./pcpFormComponents/ClaimantDetails";

function PCP({
  edit,
  onSave,
  isSubmitFormik,
  handleReturnEdit,
  setIsSubmitForm,
  showAccSubmissionCriteria,
}) {
  const [isAccSubmissionCriteria, setisAccSubmissionCriteria] = useState(
    showAccSubmissionCriteria || false
  );
  const [accSubmissionCriteria, setaccSubmissionCriteria] = useState({});

  const { pcp } = useSelector((state) => state.pcpSlice);

  useEffect(() => {
    if (isSubmitFormik && edit) {
      formik.handleSubmit();
    }
  }, [isSubmitFormik]);

  const handleAccSum = (data) => {
    setisAccSubmissionCriteria(true);
    setaccSubmissionCriteria(data);
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (body) => {
      if (edit) {
        body["tenancyclaimcode"] = pcp.pcpclaimcode;
        delete body["pcpActionButton"];
        delete body["tblTenancydocuments"];
        delete body["tblTenancylogs"];
        delete body["tblTenancymessages"];
        delete body["tblTenancynotes"];
        delete body["tblTenancysolicitors"];
        await handlePostRequest(body, "/Pcp/updatePcpCase");
        handleReturnEdit();
      } else {
        await handlePostRequest(body, "/Pcp/addNewPcpCase");
      }
    },
  });

  useEffect(() => {
    if (edit) {
      fillFormFields();
    }
  }, [edit, pcp]);

  const fillFormFields = () => {
    Object.keys(pcp).forEach((key) => {
      formik.setFieldValue(key, pcp[key] || "");
    });
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    classNames: classNames,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
  };

  //show formik errors
  useEffect(() => {
    if (formik.errors && setIsSubmitForm) {
      setIsSubmitForm(false);
    }
  }, [formik.errors, setIsSubmitForm]);

  return (
    <>
      {!isAccSubmissionCriteria ? (
        <AccSubmissionCriteria
          handleAccSum={handleAccSum}
          campaignCode={campaignCodes.OL}
        />
      ) : (
        <div className="p-ml-4">
          <MainInfo edit={edit} formik={formik} />

          <div>
            <hr className="border__hr" />
            <div className="row">
              <div className="p-col-6 p-mt-2">
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <ClaimantDetails {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PCP;
