import { Dialog } from "primereact/dialog";
import React from "react";
import HireRejectForm from "./HireRejectForm";

function HireRejectDialog({
  showModal,
  hireclaimcode,
  onCloseModal,
  buttonDetails,
}) {
  return (
    <Dialog
      header="Hire Reject"
      visible={showModal}
      style={{ width: "70vw" }}
      onHide={onCloseModal}
    >
      <HireRejectForm
        onCloseModal={onCloseModal}
        buttonDetails={buttonDetails}
        hireclaimcode={hireclaimcode}
      />
    </Dialog>
  );
}

export default HireRejectDialog;
