import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import { handleGetRequest } from "../../services/GetTemplate";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { getFilteredInvoiceList } from "../../redux/actions/invoiceAction";

function InvoiceFilterMenu() {
  const [statusOptions, setStatusOptions] = React.useState([]);
  const [companyOptions, setCompanyOptions] = React.useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const getStatusOptions = async () => {
      try {
        const response = await handleGetRequest("lovInvoiceStatus");
        setStatusOptions(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getCompanyOptions = async () => {
      try {
        const response = await handleGetRequest("lovActiveCompanies");
        setCompanyOptions(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getStatusOptions();
    getCompanyOptions();
  }, []);

  const validationSchema = Yup.object().shape({
    status: Yup.mixed().required("Required"),
    companycode: Yup.mixed().required("Required"),
  });

  const initialValues = {
    status: "",
    companycode: "",
    datefrom: "",
    dateto: "",
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (data) => {
      data["companycode"] = data.companycode.code;
      data["status"] = data.status.code;
      dispatch(getFilteredInvoiceList(data));

      console.log(data);
    },
  });

  const handleClear = () => {
    formik.resetForm();
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-12">
            <label htmlFor="status">Status</label>
            <Dropdown
              id="status"
              name="status"
              options={statusOptions}
              optionLabel="name"
              value={formik.values.status}
              onChange={formik.handleChange}
              className={
                (classNames({
                  "p-invalid": isFormFieldValid("status"),
                }),
                "Width__100")
              }
            />
            {getFormErrorMessage("status")}
          </div>
          <div className="p-col-6 p-md-6">
            <label htmlFor="status">From</label>
            <InputText
              id="datefrom"
              name="datefrom"
              type="date"
              value={formik.values.datefrom}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("datefrom"),
              })}
            />
            {getFormErrorMessage("datefrom")}
          </div>
          <div className="p-col-6 p-md-6">
            <label htmlFor="status">To</label>
            <InputText
              id="dateto"
              name="dateto"
              type="date"
              value={formik.values.dateto}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("dateto"),
              })}
            />
            {getFormErrorMessage("dateto")}
          </div>
          <div className="p-field p-col-12 p-md-12 p-mt-2">
            <label htmlFor="companycode">Bill to Account</label>
            <Dropdown
              filter
              id="companycode"
              name="companycode"
              options={companyOptions}
              optionLabel="name"
              value={formik.values.companycode}
              onChange={formik.handleChange}
              className={
                (classNames({
                  "p-invalid": isFormFieldValid("companycode"),
                }),
                "Width__100")
              }
            />
            {getFormErrorMessage("companycode")}
          </div>
        </div>
        <div className="p-col-12 p-md-12">
          <Button type="submit" className="btn btn-primary mr-2">
            Search Filter
          </Button>
          <Button
            type="button"
            className="btn btn-primary mr-2"
            onClick={handleClear}
          >
            Clear
          </Button>
        </div>
      </form>
    </div>
  );
}

export default InvoiceFilterMenu;
