import React, { useState } from "react";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CSVLink } from "react-csv";
import { Chip } from "primereact/chip";
import { FaFileExcel } from "react-icons/fa";
import { handlePostRequest } from "../../services/PostTemplate";

function HireCaseReport() {
  const [cases, setcases] = useState([]);
  const [expandedRows, setExpandedRows] = useState();
  const [loading, setloading] = useState(false);
  const [value, onChange] = useState([new Date(), new Date()]);

  const formatDate = (input) => {
    var datePart = input.match(/\d+/g),
      year = datePart[0], // get only two digits
      month = datePart[1],
      day = datePart[2];
    return day + "-" + month + "-" + year;
  };

  const dateTemplate = (rowData) => {
    let allDate = rowData.accidentDate.split(" ");
    let thisDate = allDate[0].split("-");
    let newDate = [thisDate[2], thisDate[1], thisDate[0]].join("-");
    return newDate;
  };

  const searchCases = async () => {
    setloading(true);
    try {
      const data = {
        fromDate: formatDate(value[0].toISOString().slice(0, 10)),
        toDate: formatDate(value[1].toISOString().slice(0, 10)),
      };
      const res = await handlePostRequest(data, "hire/hireCaseReport");
      // TODO: remove this random number when backend is ready
      res.data.forEach((element) => {
        element.rtacode = Math.floor(Math.random() * 1000000);
      });
      setcases(res.data);
    } catch (error) {
      console.log(error);
    }
    setloading(false);
  };

  const headers = [
    { label: "Created On", key: "createdon" },
    { label: "Last Updated", key: "lastupdated" },
    { label: "Code", key: "code" },
    { label: "Title", key: "title" },
    { label: "First Name", key: "firstname" },
    { label: "Middle Name", key: "middlename" },
    { label: "Last Name", key: "lastname" },
    { label: "Introducer Company", key: "introducerCompany" },
    { label: "Introducer User", key: "introducerUser" },
    { label: "Hire Firm", key: "hireFirm" },
    { label: "Case Status", key: "caseStatus" },
    { label: "Case Status On", key: "caseStatusOn" },
    { label: "Accident Date", key: "accidentDate" },
    { label: "Client Vehicle Reg No", key: "clientVehicleRegno" },
    { label: "Client Make Model", key: "clientMakemodel" },
    { label: "Vehicle Condition", key: "vehiclecondition" },
    { label: "Service", key: "service" },
    { label: "Booking Date", key: "bookingdate" },
    { label: "Hire Start Date", key: "hirestartdate" },
    { label: "Hire End Date", key: "hireenddate" },
    { label: "Party Vehicle Reg No", key: "partyVehicleRegno" },
    { label: "Party Make Model", key: "partymakemodel" },
    { label: "Party Contact No", key: "partycontactno" },
    { label: "Postal Code", key: "postalcode" },
  ];

  const rowExpansionTemplate = (data) => {
    const keyMappings = {
      accidentDate: { label: "Accident Date", value: data?.accidentDate },
      bookingdate: { label: "Booking Date", value: data?.bookingdate },
      caseStatus: { label: "Case Status", value: data?.caseStatus },
      caseStatusOn: { label: "Case Status On", value: data?.caseStatusOn },
      clientMakemodel: {
        label: "Client Make Model",
        value: data?.clientMakemodel,
      },
      clientVehicleRegno: {
        label: "Client Vehicle Reg No",
        value: data?.clientVehicleRegno,
      },
      code: { label: "Code", value: data?.code },
      createdon: { label: "Created On", value: data?.createdon },
      firstname: { label: "First Name", value: data?.firstname },
      hireFirm: { label: "Hire Firm", value: data?.hireFirm },
      hireenddate: { label: "Hire End Date", value: data?.hireenddate },
      hirestartdate: { label: "Hire Start Date", value: data?.hirestartdate },
      introducerUser: { label: "Introducer User", value: data?.introducerUser },
      lastname: { label: "Last Name", value: data?.lastname },
      lastupdated: { label: "Last Updated", value: data?.lastupdated },
      middlename: { label: "Middle Name", value: data?.middlename },
      partyVehicleRegno: {
        label: "Party Vehicle Reg No",
        value: data?.partyVehicleRegno,
      },
      partycontactno: {
        label: "Party Contact No",
        value: data?.partycontactno,
      },
      partymakemodel: {
        label: "Party Make Model",
        value: data?.partymakemodel,
      },
      partyname: { label: "Party Name", value: data?.partyname },
      postalcode: { label: "Postal Code", value: data?.postalcode },
      service: { label: "Service", value: data?.service },
      title: { label: "Title", value: data?.title },
      vehiclecondition: {
        label: "Vehicle Condition",
        value: data?.vehiclecondition,
      },
    };

    // Split the keys into three arrays for three columns
    const keys = Object.keys(keyMappings);
    const numKeys = keys.length;
    const keysPerColumn = Math.ceil(numKeys / 3);

    // Create arrays for each column
    const column1Keys = keys.slice(0, keysPerColumn);
    const column2Keys = keys.slice(keysPerColumn, 2 * keysPerColumn);
    const column3Keys = keys.slice(2 * keysPerColumn);

    return (
      <div className="expanded-card" style={{ display: "flex", width: "100%" }}>
        {/* First column */}
        <div className="box" style={{ flex: "1", padding: "1em" }}>
          {column1Keys.map((key) => (
            <div key={key}>
              <strong>{keyMappings[key].label}:</strong>
              <p>{keyMappings[key].value}</p>
            </div>
          ))}
        </div>

        {/* Second column */}
        <div className="box" style={{ flex: "1", padding: "1em" }}>
          {column2Keys.map((key) => (
            <div key={key}>
              <strong>{keyMappings[key].label}:</strong>
              <p>{keyMappings[key].value}</p>
            </div>
          ))}
        </div>

        {/* Third column */}
        <div className="box" style={{ flex: "1", padding: "1em" }}>
          {column3Keys.map((key) => (
            <div key={key}>
              <strong>{keyMappings[key].label}:</strong>
              <p>{keyMappings[key].value}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const statusTemplate = (rowData) => {
    return (
      <center>
        <Chip label={rowData?.caseStatus} className="p-px-4 custom-chip" />
      </center>
    );
  };

  return (
    <>
      <div className="expanded-card p-d-flex p-jc-between">
        <div className="p-d-flex ">
          <DateTimeRangePicker
            autoFocus={true}
            format="y-MM-dd"
            onChange={onChange}
            value={value}
          />
          &nbsp;
          <Button
            disabled={loading}
            label={loading ? "Searching..." : "Search"}
            onClick={searchCases}
          />
        </div>
        <CSVLink data={cases} headers={headers}>
          <Button disabled={loading}>
            <FaFileExcel />
            &nbsp;Export
          </Button>
        </CSVLink>
      </div>
      <div className="card p-datatable-sm mt-4">
        <DataTable
          value={cases}
          scrollable
          paginator
          rows={10}
          className="p-datatable-customers"
          emptyMessage="No data found."
          expandedRows={expandedRows}
          dataKey="rtacode"
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander style={{ maxWidth: "60px" }}></Column>
          <Column field="firstname" header="Client Name" sortable />
          <Column field="introducerUser" header="Introducer" sortable />
          <Column field="hireFirm" header="Hire Firm" sortable />
          <Column
            field="caseStatusOn"
            header="Case Status On"
            sortable
            body={dateTemplate}
          />
          <Column
            field="caseStatus"
            header="Case Status"
            sortable
            body={statusTemplate}
          />
        </DataTable>
      </div>
    </>
  );
}
export default HireCaseReport;
