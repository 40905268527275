import React from "react";
import "./CaseHeader.css";
import PropTypes from "prop-types";

function CaseHeader({ title, subTitle }) {
  return (
    <div className="case-header">
      <div className="col-lg-4">
        <h6>{title}</h6>
        <small>{subTitle}</small>
      </div>
    </div>
  );
}

export default CaseHeader;

React.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  search: PropTypes.bool,
  children: PropTypes.element,
  onSearch: PropTypes.func,
};
