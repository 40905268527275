import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "dbSlice",
  initialState: {
    db: {},
    dbList: [],
    dbStatus: [],
  },
  reducers: {
    GETDBLISTSUCCESS: (state, action) => {
      return {
        ...state,
        dbList: action.payload,
      };
    },
    GETDBLISTERROR: (state) => {
      return {
        ...state,
        dbList: [],
      };
    },
    GETDBSUCCESS: (state, action) => {
      return {
        ...state,
        db: action.payload,
      };
    },
    GETDBERROR: (state) => {
      return {
        ...state,
        db: {},
      };
    },
    GETDBSTATUSSUCCESS: (state, action) => {
      return { ...state, dbStatus: action.payload };
    },
    GETDBSTATUSERROR: (state) => {
      return { ...state, dbStatus: {} };
    },
  },
});

export const {
  GETDBLISTSUCCESS,
  GETDBLISTERROR,
  GETDBSUCCESS,
  GETDBERROR,
  GETDBSTATUSERROR,
  GETDBSTATUSSUCCESS,
} = slice.actions;
export default slice.reducer;
