import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import styled from "styled-components";

const StyledCard = styled.div`
  padding: 20px;
  background: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Heading = styled.h2`
  color: #3498db; /* Dodger Blue */
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

export default function PieChartDemo() {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      labels: ["Legal Advice", "Claims Assistance", "Policy Review"],
      datasets: [
        {
          data: [25, 40, 35],
          backgroundColor: [
            "#3498DB", // Dodger Blue
            "#E74C3C", // Alizarin
            "#2ECC71", // Emerald
          ],
          hoverBackgroundColor: [
            "#5DADE2", // Light Blue
            "#EC7063", // Light Red
            "#58D68D", // Light Green
          ],
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  return (
    <StyledCard>
      <Heading>Legal Assist UK Portal for Insurance Company</Heading>
      <Chart
        type="pie"
        data={chartData}
        options={chartOptions}
        className="w-full md:w-30rem"
      />
      <div className="text-center mt-4">
        <p>Legal Advice: 25%</p>
        <p>Claims Assistance: 40%</p>
        <p>Policy Review: 35%</p>
      </div>
    </StyledCard>
  );
}
