import React from "react";
import { RadioButton } from "primereact/radiobutton";
import LaInput from "../../../../components/LaInput";
import LaInputTextArea from "../../../../components/LaInputTextArea";
import LaCheckbox from "../../../../components/LaCheckbox";
import LaMultiSelect from "../../../../components/LaMultiSelect/LaMultiSelect";

function PassengerInjuryInfo({
  classNames,
  isFormFieldValid,
  getFormErrorMessage,
  onChange,
  values,
  formik,
  isDriver,
}) {
  const props = {
    onChange,
    values,
    isFormFieldValid,
    getFormErrorMessage,
  };

  const handleChanges = (e) => {
    if (e.checked === true) {
      formik.setFieldValue("injlength", "");
    }
  };

  return (
    <>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Claimant:</label>
        <div className="Radio__Btn">
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="driverpassenger"
              name="driverpassenger"
              value="D"
              disabled={isDriver === "D" ? true : false}
              onChange={onChange}
              checked={values.driverpassenger === "D"}
            />
            <label htmlFor="driverpassenger" style={{ marginRight: "10px" }}>
              Driver
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="driverpassenger"
              name="driverpassenger"
              value="P"
              onChange={onChange}
              checked={values.driverpassenger === "P"}
            />
            <label htmlFor="driverpassenger">Passenger</label>
          </div>
        </div>
        {getFormErrorMessage("driverpassenger")}
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Injury Classification</label>
        <LaMultiSelect
          keyName="injclasscodes"
          optionLabel="name"
          placeholder=""
          showList={true}
          url={"/lovInjuryClaims"}
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Injury Description</label>
        <LaInputTextArea
          keyName="injdescription"
          placeholder="if other please provide a detailed description"
          {...props}
        />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Length Of Injury</label>
        <LaInput
          keyName="injlength"
          type="number"
          placeholder="Weeks"
          disabled={values.ongoing}
          {...props}
        />
      </div>
      <div className="p-col-6 p-mt-2" style={{ display: "flex" }}>
        <LaCheckbox
          keyName="ongoing"
          handleChanges={handleChanges}
          {...props}
        />
        <label className="p-d-block" style={{ marginLeft: "10px" }}>
          Ongoing Injury
        </label>
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Medical Info:</label>
        <div style={{ display: "flex" }}>
          <LaCheckbox keyName="medicalinfo" {...props} />
          <label className="p-d-block" style={{ marginLeft: "10px" }}>
            Medical evidence avaliable
          </label>
        </div>
      </div>
      {values.medicalinfo === true && (
        <div className="p-col-12 p-mt-2">
          <LaInputTextArea
            keyName="medicalevidence"
            placeholder="please provide details"
            {...props}
          />
        </div>
      )}
    </>
  );
}

export default PassengerInjuryInfo;
